// schema.ts
import { ApplicationData, FieldAttributes } from "types"
import * as Yup from "yup"
import { getAllFields, pluralize } from "./helpers"
import { determineIsRequired, isConditionSetMet } from "utilities/conditions"
import { ACCEPTED_FILE_TYPES, FILE_ERROR_MESSAGES } from "components/FileUpload"

/**
 *
 * Create a schema to validate the form data based on form json
 *
 * @param applicationData The JSON representing the entire form
 * @returns a flat (single array) schema of key value pairs
 * key: the field name // value: a custom schema for each input type based on JSON data
 * E.G: [{fieldName1: schema1, fieldName2: schema2}]
 */

export const generateValidationSchema = (applicationData: ApplicationData) => {
  const fields = getAllFields(applicationData)
  const fieldsObj: any = {}

  const MAX_NESTED_FIELDS = 10

  fields.forEach(field => {
    if (field.name.includes("{{x}}")) {
      for (let i = 1; i <= MAX_NESTED_FIELDS; i++) {
        let parsedFieldName = field.name.replace("{{x}}", `${i}`)
        fieldsObj[parsedFieldName] = generateFieldSchema(field)
      }
    } else {
      fieldsObj[field.name] = generateFieldSchema(field)
    }
  })

  const nestedSections = applicationData.pages.flatMap(page =>
    page.sections.filter(section => section.nestedSectionsMax),
  )

  nestedSections.forEach(section => {
    fieldsObj[section.controlSectionName] = Yup.array().of(
      Yup.object().shape(
        section.fields.reduce((acc, field) => {
          acc[field.name] = generateFieldSchema(field)
          return acc
        }, {}),
      ),
    )
  })

  return Yup.object().shape(fieldsObj)
}

/**
 * Create a schema for a field
 *
 * @param fieldData The JSON representing the field
 * @returns the schema to validate the given field
 */
const generateFieldSchema = (fieldData: FieldAttributes) => {
  // Fields that users cannot interact with shouldn't validate
  if (fieldData.isReadOnly || fieldData.isDisabled) {
    return DEFAULT_SCHEMA
  }

  const errorMessage = fieldData.errorText || undefined

  let schema: any

  switch (fieldData.type) {
    case "text":
    case "password":
    case "tel":
    case "tel-2":
    case "textarea":
    case "zip_code":
      schema = generateTextSchema(fieldData, errorMessage)
      break
    case "email":
      schema = generateEmailSchema(fieldData, errorMessage)
      break
    case "date":
      schema = generateDateSchema(fieldData, errorMessage)
      break
    case "month":
      schema = generateMonthSchema(fieldData, errorMessage)
      break
    case "year":
      schema = generateYearSchema(fieldData, errorMessage)
      break
    case "select":
    case "radio":
      schema = generateOptionsSchema(fieldData, errorMessage)
      break
    case "checkbox":
      schema = generateMultiOptionsSchema(fieldData, errorMessage)
      break
    case "number":
    case "integer":
      schema = generateNumberSchema(fieldData, errorMessage)
      break
    case "file":
      schema = generateFileSchema(fieldData, errorMessage)
      break
    case "highSchoolSearch":
    case "collegeSearch":
    case "countrySearch":
    case "stateSearch":
    case "countySearch":
    case "activitySearch":
    case "apSearch":
    case "ibSearch":
    case "languageSearch":
    case "sportSearch":
    case "religionSearch":
      schema = generateSearchSchema(fieldData, errorMessage)
      break
    default:
      schema = DEFAULT_SCHEMA
  }

  // Check if schema is defined before applying isRequired
  if (schema === undefined) {
    console.warn(`Schema is undefined for field: ${fieldData.name}`)
    return DEFAULT_SCHEMA
  }

  // Apply isRequired validation to all schemas
  return isRequired(fieldData, schema, errorMessage)
}

const fieldHasValue = (value: any) => {
  return value !== null && value !== undefined && value.toString() !== ""
}

export const isRequired = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  const requiredMessage = errorMessage || "This field is required"

  if (!fieldData.isRequired) {
    return schema
  }

  if (typeof fieldData.isRequired === "boolean") {
    return schema.test("is-required", requiredMessage, value => {
      return fieldHasValue(value)
    })
  }

  if (
    typeof fieldData.isRequired === "object" &&
    "conditions" in fieldData.isRequired
  ) {
    const conditionSet = fieldData.isRequired
    return schema.test(
      "is-conditionally-required",
      requiredMessage,
      function (value, context) {
        const formValues = context.parent || {}
        const isConditionMet = isConditionSetMet(conditionSet, formValues)
        if (isConditionMet) {
          return fieldHasValue(value)
        }
        return true
      },
    )
  }

  return schema
}

export const numberFieldExists = (field: number) => {
  return field !== null && field !== undefined
}

export const booleanFieldExists = (field: boolean) => {
  return field !== null && field !== undefined
}

export const generateTextSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  let schema = Yup.string()

  schema = isRequired(fieldData, schema, errorMessage)
  schema = getMinLength(fieldData, schema, errorMessage)
  schema = getMaxLength(fieldData, schema, errorMessage)
  schema = getIsLength(fieldData, schema, errorMessage)

  return schema
}

export const generateEmailSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  let schema = Yup.string().email(errorMessage || "Email address invalid")

  // Apply the isRequired validation if necessary
  schema = isRequired(fieldData, schema, errorMessage)

  return schema
}

export const getMinLength = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  if (numberFieldExists(fieldData.validations?.length?.min)) {
    const minLength = fieldData.validations?.length?.min
    return schema.min(
      minLength,
      errorMessage || `Value must be at least ${minLength} characters`,
    )
  }
  return schema
}

export const getMaxLength = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  if (numberFieldExists(fieldData.validations?.length?.max)) {
    const maxLength = fieldData.validations?.length?.max
    return schema.max(
      maxLength,
      errorMessage || `Value must be at most ${maxLength} characters`,
    )
  }
  return schema
}

export const getIsLength = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  if (numberFieldExists(fieldData.validations?.length?.is)) {
    return schema.test(
      `${fieldData.name}`,
      errorMessage ||
        `Input must be length: ${fieldData.validations.length.is}`,
      value => {
        if (value?.length === 0) {
          return true
        }
        return fieldData.validations.length.is === value?.length
      },
    )
  }
  return schema
}

export const generateDateSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  let schema = Yup.date().nullable()

  schema = setDateRules(fieldData, schema, errorMessage)
  schema = isRequired(fieldData, schema, errorMessage)

  // Any additional schemas generated AFTER this transform, will fail.
  schema = schema
    .transform((value, originalValue) => {
      if (originalValue?.length === 10) {
        return value
      }
      return null
    })
    .test(
      `${fieldData.name}-date-format`,
      errorMessage || "Invalid date format, must be MM/DD/YYYY",
      (value, context) => {
        if (!context.originalValue) {
          return true
        }
        return context.originalValue?.replace(/ /g, "").length === 10
      },
    )

  return schema.typeError(errorMessage || "Invalid date")
}

export const transformMonthToStandardFormat = (month: string) => {
  return new Date(month.split("/").join("/01/"))
}

export const generateMonthSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  let schema = Yup.date().nullable()
  schema = setDateRules(fieldData, schema, errorMessage)
  schema = isRequired(fieldData, schema, errorMessage)

  // Any additional schemas generated AFTER this transform, will fail.
  schema = schema
    .transform((value, originalValue) => {
      if (originalValue?.length === 7) {
        return transformMonthToStandardFormat(originalValue)
      }
      return null
    })
    .test(
      `${fieldData.name}-date-format`,
      errorMessage || "Invalid date format, must be MM/YYYY",
      (value, context) => {
        if (!context.originalValue) {
          return true
        }
        return context.originalValue?.replace(/ /g, "").length === 7
      },
    )

  return schema.typeError(errorMessage || "Invalid date")
}

export const generateYearSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  let schema = Yup.date().nullable()
  schema = setDateRules(fieldData, schema, errorMessage)
  schema = isRequired(fieldData, schema, errorMessage)

  // Any additional schemas generated AFTER this transform, will fail.
  schema = schema
    .transform((value, originalValue) => {
      if (originalValue?.length === 4) {
        return value
      }
      return null
    })
    .test(
      `${fieldData.name}-date-format`,
      errorMessage || "Invalid date format, must be YYYY",
      (value, context) => {
        if (!context.originalValue) {
          return true
        }
        return context.originalValue?.replace(/ /g, "").length === 4
      },
    )

  return schema.typeError(errorMessage || "Invalid date")
}

type TimeUnit = "days" | "months" | "years"

export const getMinDate = (count: number, unit: TimeUnit) => {
  switch (unit) {
    case "days":
      return new Date(new Date().setDate(new Date().getDate() - count))
    case "months":
      return new Date(new Date().setMonth(new Date().getMonth() - count))
    case "years":
      return new Date(new Date().setFullYear(new Date().getFullYear() - count))
  }
}

export const getMaxDate = (count: number, unit: TimeUnit) => {
  switch (unit) {
    case "days":
      return new Date(new Date().setDate(new Date().getDate() + count))
    case "months":
      return new Date(new Date().setMonth(new Date().getMonth() + count))
    case "years":
      return new Date(new Date().setFullYear(new Date().getFullYear() + count))
  }
}

export const getDateForType = (dateString: string, type: string) => {
  switch (type) {
    case "date":
    case "year":
      return new Date(dateString)
    case "month":
      return transformMonthToStandardFormat(dateString)
    default:
      return new Date()
  }
}

export const setDateRule = (
  schema: any,
  rule: "min" | "max",
  date: Date,
  defaultMessage: string,
  errorMessage?: string,
) => {
  return schema[rule](date, errorMessage || defaultMessage)
}

export const setDateRangeRule = (
  schema: any,
  rule: "min" | "max",
  value: number,
  unit: TimeUnit,
  errorMessage?: string,
) => {
  const date =
    rule === "min" ? getMinDate(value, unit) : getMaxDate(value, unit)
  const defaultMessage = `Date must be within the ${rule === "min" ? "past" : "next"} ${pluralize(value, unit)}`
  return setDateRule(schema, rule, date, defaultMessage, errorMessage)
}

export const setDateRules = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  const { validations } = fieldData

  if (validations?.date?.after) {
    const date = getDateForType(
      validations.date.after,
      fieldData.type as string,
    )
    schema = setDateRule(
      schema,
      "min",
      date,
      `Date must be after ${validations.date.after}`,
      errorMessage,
    )
  }

  if (validations?.date?.before) {
    const date = getDateForType(
      validations.date.before,
      fieldData.type as string,
    )
    schema = setDateRule(
      schema,
      "max",
      date,
      `Date must be before ${validations.date.before}`,
      errorMessage,
    )
  }

  if (booleanFieldExists(validations?.date?.isInFuture)) {
    schema = setDateRule(
      schema,
      "min",
      new Date(),
      "Date must be in the future",
      errorMessage,
    )
  }

  if (booleanFieldExists(validations?.date?.isInPast)) {
    schema = setDateRule(
      schema,
      "max",
      new Date(),
      "Date must be in the past",
      errorMessage,
    )
  }

  const dateRangeRules = [
    { key: "maxDaysInPast", rule: "min", unit: "days" },
    { key: "maxDaysInFuture", rule: "max", unit: "days" },
    { key: "maxMonthsInPast", rule: "min", unit: "months" },
    { key: "maxMonthsInFuture", rule: "max", unit: "months" },
    { key: "maxYearsInPast", rule: "min", unit: "years" },
    { key: "maxYearsInFuture", rule: "max", unit: "years" },
  ] as const

  dateRangeRules.forEach(({ key, rule, unit }) => {
    if (numberFieldExists(validations?.date?.[key])) {
      schema = setDateRangeRule(
        schema,
        rule,
        validations.date[key],
        unit,
        errorMessage,
      )
    }
  })

  return schema
}
export const setMinNumber = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  if (numberFieldExists(fieldData.validations?.value?.min)) {
    const { min } = fieldData.validations.value
    return schema.test(
      `${fieldData.name}`,
      errorMessage || `Value must be greater than or equal to ${min}`,
      value => {
        return value >= min
      },
    )
  }
  return schema
}

export const setMaxNumber = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  if (numberFieldExists(fieldData.validations?.value?.max)) {
    const { max } = fieldData.validations.value
    return schema.test(
      `${fieldData.name}`,
      errorMessage || `Value must be less than or equal to ${max}`,
      value => {
        return value <= max
      },
    )
  }
  return schema
}

export const setEqualNumber = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  if (numberFieldExists(fieldData.validations?.value?.is)) {
    return schema.test(
      `${fieldData.name}`,
      errorMessage || "Values must be equal",
      value => {
        return value === fieldData.validations.value.is
      },
    )
  }
  return schema
}

export const setIsDivisibleBy = (
  fieldData: FieldAttributes,
  schema: any,
  errorMessage?: string,
) => {
  if (numberFieldExists(fieldData.validations?.value?.divisor)) {
    const { divisor } = fieldData.validations.value
    return schema.test(
      `${fieldData.name}`,
      errorMessage || `Value must be divisible by ${divisor}`,
      value => {
        return value % divisor === 0
      },
    )
  }
  return schema
}

export const generateNumberSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  let schema = Yup.number().nullable()

  schema = isRequired(fieldData, schema, errorMessage)
  schema = setMaxNumber(fieldData, schema, errorMessage)
  schema = setMinNumber(fieldData, schema, errorMessage)
  schema = setEqualNumber(fieldData, schema, errorMessage)
  schema = setIsDivisibleBy(fieldData, schema, errorMessage)

  schema = schema.transform((value, originalValue) => {
    if (originalValue === "") {
      return null
    }
    return value
  })

  return schema
}

export const generateOptionsSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  return Yup.mixed()
    .nullable()
    .test(
      "is-valid-selection",
      errorMessage || `${fieldData.label} is required`,
      value => {
        if (fieldData.isRequired) {
          if (typeof value === "string") {
            return fieldHasValue(value)
          } else if (value && typeof value === "object" && "value" in value) {
            const { value: fieldValue } = value
            return fieldHasValue(fieldValue)
          }
          return false
        }
        return true
      },
    )
    .transform((currentValue, originalValue) => {
      if (
        originalValue &&
        typeof originalValue === "object" &&
        "value" in originalValue
      ) {
        return originalValue.value
      }
      return currentValue
    })
}

export const generateMultiOptionsSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  if ("options" in fieldData && fieldData.options.length === 1) {
    // Single checkbox
    return Yup.boolean()
      .nullable()
      .test("is-checked", errorMessage || "This field is required", value => {
        if (fieldData.isRequired) {
          // Check if the value is true or an array with a non-empty string
          return (
            value === true ||
            (Array.isArray(value) && value.length > 0 && value[0] !== "")
          )
        }
        return true
      })
      .transform(value => {
        // Transform array to boolean
        if (Array.isArray(value)) {
          return value.length > 0 && value[0] !== ""
        }
        return value
      })
  } else {
    // Multiple checkboxes
    let schema = Yup.array().of(Yup.string())
    const lengthValidations = fieldData.validations?.length

    if (lengthValidations) {
      if (lengthValidations?.min) {
        schema = schema.min(
          lengthValidations?.min,
          errorMessage ||
            `At least ${lengthValidations?.min} selection(s) required`,
        )
      }
      if (lengthValidations?.max) {
        schema = schema.max(
          lengthValidations?.max,
          errorMessage ||
            `No more than ${lengthValidations?.max} selection(s) allowed`,
        )
      }
    }

    return schema
  }
}

export const generateSearchSchema = (
  fieldData: FieldAttributes,
  errorMessage?: string,
) => {
  return Yup.mixed()
    .nullable()
    .test(
      "is-valid-selection",
      errorMessage || "This field is required",
      value => {
        if (fieldData.isRequired) {
          return fieldHasValue(value)
        }
        return true
      },
    )
}

export const generateFileSchema = (
  fieldData: FieldAttributes,
  errorText?: string,
) => {
  return Yup.mixed()
    .nullable()
    .test({
      name: "file-validation",
      test: function (value) {
        const isRequired = determineIsRequired(
          fieldData,
          this.parent,
          undefined,
          false,
          false,
        )

        // Check if the field is required and empty
        if (isRequired && !fieldHasValue(value)) {
          return false // This will trigger the 'required' error message
        }

        // If there's a value, check if it's a valid file type
        if (value && typeof value === "string") {
          const acceptedExtensions = Object.values(ACCEPTED_FILE_TYPES).flat()
          const fileExtension = `.${value.split(".").pop().toLowerCase()}`
          if (!acceptedExtensions.includes(fileExtension)) {
            return false // This will trigger the 'invalidType' error message
          }
        }

        return true
      },
      message: ({ value }) => {
        if (!fieldHasValue(value)) {
          return FILE_ERROR_MESSAGES.required
        }
        if (typeof value === "string") {
          const acceptedExtensions = Object.values(ACCEPTED_FILE_TYPES).flat()
          const fileExtension = `.${value.split(".").pop().toLowerCase()}`
          if (!acceptedExtensions.includes(fileExtension)) {
            return FILE_ERROR_MESSAGES.invalidType
          }
        }
        return errorText || "Something's gone wrong. Please try again."
      },
    })
}

export const DEFAULT_SCHEMA = Yup.object()
  .shape({} as any)
  .nullable()
