import { OptionData } from "types"

export const RELIGIONS: OptionData = [
  "African Methodist Episcopal",
  "Agnostic",
  "Anglican",
  "Assembly of God",
  "Atheist",
  "Baha'i",
  "Baptist",
  "Buddhism",
  "Catholic (Roman)",
  "Caodaist",
  "Cheondoist",
  "Christian",
  "Christian Scientist",
  "Disciples of Christ",
  "Christian Reformed Church US",
  "Church of Christ",
  "Church of Christ, Scientist",
  "Church of God",
  "Church of God in Christ",
  "Church of the Brethren",
  "Church of the Nazarene",
  "Church of World Messianity",
  "Confucianism",
  "Congregational",
  "Eastern Orthodox Churches",
  "Eastern Rite Catholicism",
  "Episcopal",
  "Evangelical",
  "Evangelical Lutheran Church in America",
  "Greek Orthodox",
  "Hindu",
  "Hmong",
  "Islam (Muslim)",
  "Jainism",
  "Jehovah's Witness",
  "Judaism",
  "Latter-Day Saints",
  "Lutheran",
  "Lutheran Church-Missouri Synod",
  "Lutheran—Wisconsin Synod",
  "Mennonite",
  "Methodist",
  "African Methodist Episcopal Church",
  "Missouri Synod Lutheran",
  "Moravian",
  "Muslim",
  "None",
  "Nondenominational",
  "Orthodox",
  "Other",
  "Other-Christian",
  "Other-Non-Christian",
  "Pentecostalism",
  "Presbyterian",
  "Protestant",
  "Rastafari",
  "Reformed",
  "Reformed Church in America",
  "Roman Orthodox",
  "Seicho-no-le-ist",
  "Seventh-Day Adventist",
  "Sikhism",
  "Society of Friends (Quakers)",
  "Southern Baptist Convention",
  "Taoism",
  "Tenrikyoist",
  "Unitarian Universalist Assoc",
  "United Church of Christ",
  "United Methodist",
  "Wesleyan Church",
  "Wicca (Pagan)",
  "Wisconsin Evangelical Lutheran Synod",
  "Worldwide Church of God",
  "Yazidi",
]
