import { useContext, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { ApplicationData, PageData } from "types"

import { Layout } from "components/Layout"
import { BoxProps } from "@chakra-ui/react"
import { AuthRoutes } from "utilities/constants"
import { ClientContext } from "context/ClientContext"
import { HollinsAuthLayout } from "pages/susi/hollins/HollinsAuthLayout"

interface LayoutProviderProps extends BoxProps {
  applicationData: ApplicationData
  isDataLoaded: boolean
}

export const LayoutProvider = ({
  applicationData,
  isDataLoaded,
  ...rest
}: LayoutProviderProps) => {
  const { pages, layout } = applicationData
  const location = useLocation()
  const { client } = useContext(ClientContext)

  const [currentPage, setCurrentPage] = useState<PageData>()

  useEffect(
    function determineCurrentPage() {
      setCurrentPage(
        pages.find(pageData => pageData.slug === location.pathname.slice(1)),
      )
    },
    [location, pages],
  )

  const [showLoadingScreen, setShowLoadingScreen] = useState(!isDataLoaded)

  useEffect(() => {
    let mounted = true

    setTimeout(() => {
      if (mounted) setShowLoadingScreen(!isDataLoaded)
    }, 1000)

    return () => {
      //canceling state setting on unmounting
      mounted = false
    }
  }, [isDataLoaded])

  const navRef = useRef(null)
  const mainRef = useRef(null)

  const layoutProps = {
    applicationData,
    navRef,
    mainRef,
    currentPage,
    layout: { version: !currentPage ? "centered" : layout.version },
    showLoadingScreen,
    ...rest,
  }

  const isAuthPage = AuthRoutes.some(route =>
    location.pathname.includes(`/${route}`),
  )

  if (isAuthPage) {
    if (client.theme === "hollins") {
      return <HollinsAuthLayout {...layoutProps} />
    }
  }

  return <Layout {...layoutProps} />
}
