import { Box, useStyleConfig } from "@chakra-ui/react"
import { SiteLogo } from "components/SiteLogo"
import { FullWidthFooter } from "components/FullWidthFooter"
import { LayoutProps } from "components/Layout"
import { LoadingScreen } from "components/LoadingScreen"
import { motion } from "framer-motion"

export const HollinsAuthLayout = (props: LayoutProps) => {
  const { applicationData, children, showLoadingScreen } = props
  const styles = useStyleConfig("AuthPage")

  if (showLoadingScreen) {
    return (
      <LoadingScreen
        height="full"
        as={motion.div}
        initial={{ opacity: 0.5 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0.5 }}
      />
    )
  }

  return (
    <Box __css={styles}>
      <Box gridArea="logo" className="logo-wrapper desktop-left">
        <Box>
          <SiteLogo
            alt={applicationData.meta.title}
            src={applicationData.meta.logo}
          />
        </Box>
      </Box>

      {/* HollinsAuthPage content here */}
      {children}

      <FullWidthFooter
        gridArea="footer"
        id="auth-footer"
        className="desktop-right"
        applicationData={applicationData}
        hideSignOut
      />
    </Box>
  )
}
