import { useContext } from "react"

import { ThemingProps } from "@chakra-ui/react"
import {
  CheckboxGroup,
  Input,
  RadioGroup,
  NumberInput,
  Textarea,
  RedirectModal,
} from "components/common"
import {
  CheckboxGroupInputProps,
  FieldAttributes,
  InfoComponentProps,
  RadioGroupInputProps,
  SelectInputProps,
  TextInputProps,
} from "types"
import { useController, UseControllerProps } from "react-hook-form"
import FormContext from "context/FormContext"
import { SearchHandler } from "components/SearchHandler"
import { FileUpload, FileUploadProps } from "components/FileUpload"
import { ZipCodeInput } from "components/ZipCodeInput"
import { PhoneInput } from "components/common/PhoneInput"
import { PhoneInput2 } from "components/common/PhoneInput2"
import { IS_PRODUCTION } from "utilities/constants"
import { BusinessConcentrationField } from "components/siena/BusinessConcentrationField"
import { InfoBox } from "./common/InfoBox"

export interface InputSwitchProps extends UseControllerProps, ThemingProps {
  fieldData: FieldAttributes
  label: string
  isRequired?: boolean
  isInvalid?: boolean
  errorText?: string
  isRequiredErrors?: boolean
  nestedIndex?: number | undefined
  isFinalCheck?: boolean
}

export const InputSwitch = ({
  control,
  fieldData,
  isRequired,
  isInvalid,
  errorText,
  name,
  isRequiredErrors,
  isFinalCheck,
}: InputSwitchProps) => {
  const { field } = useController({
    name,
    control,
    rules: {
      required: isRequired && "This field is required",
    },
  })

  const { onSave } = useContext(FormContext)

  /**
   * Fields change on blur by default. Some fields need to save on change.
   * - RadioGroup and SearchHandler should save on change to prevent being one step behind
   */
  const saveOnChange = (value: string) => {
    field.onChange(value)
    onSave()
  }

  const inputProps = {
    ...field,
    ...fieldData,
    errorText: errorText,
    onBlur: () => {
      onSave()
    },
    isRequired: isRequired,
    isInvalid:
      isInvalid ||
      (isRequired && isRequiredErrors && !field.value) ||
      (isRequired && !field.value && isFinalCheck),
    hide: fieldData.hide ? fieldData.hide.toString() : undefined,
    ref: fieldData.type !== "redirectModal" ? field.ref : undefined,
  }

  const { type: fieldType } = fieldData

  switch (fieldType) {
    case "text":
    case "password":
      return (
        <Input
          {...(inputProps as TextInputProps)}
          value={field.value || ""}
          maxLength={255}
        />
      )
    case "info":
    case "subtext":
      return <InfoBox {...(inputProps as InfoComponentProps)} />
    case "date":
    case "month":
    case "year":
      // These are masked inputs and maxLength is not allowed
      return <Input {...(inputProps as TextInputProps)} />
    case "email":
      return (
        <Input
          {...(inputProps as TextInputProps)}
          isDisabled={fieldData.name === "email_address"}
        />
      )
    case "tel":
      return <PhoneInput {...(inputProps as TextInputProps)} />
    case "tel-2":
      return <PhoneInput2 {...(inputProps as TextInputProps)} />
    case "zip_code":
      return <ZipCodeInput {...(inputProps as TextInputProps)} />
    case "file":
      return <FileUpload {...(inputProps as FileUploadProps)} />
    case "number":
    case "integer":
      return (
        <NumberInput
          {...(inputProps as TextInputProps)}
          disallowDecimals={fieldType === "integer"}
          disallowExponents
          hideStepper
        />
      )
    case "textarea":
      return <Textarea rows={5} {...(inputProps as TextInputProps)} />
    case "radio":
      return (
        <RadioGroup
          {...(inputProps as RadioGroupInputProps)}
          onChange={saveOnChange}
        />
      )
    case "checkbox":
      return <CheckboxGroup {...(inputProps as CheckboxGroupInputProps)} />
    case "highSchoolSearch":
    case "collegeSearch":
    case "countrySearch":
    case "activitySearch":
    case "apSearch":
    case "ibSearch":
    case "languageSearch":
    case "sportSearch":
    case "religionSearch":
    case "stateSearch":
    case "countySearch":
    case "select":
      return (
        <SearchHandler
          {...(inputProps as TextInputProps)}
          onChange={saveOnChange}
        />
      )
    case "redirectModal":
      return <RedirectModal {...(inputProps as any)} onSave={onSave} />
    case "sienaBusinessConcentrationSelect":
      return (
        <BusinessConcentrationField
          inputProps={inputProps as SelectInputProps}
        />
      )
    default:
      return IS_PRODUCTION ? null : (
        // @ts-ignore
        <InvalidInputType key={fieldData.name} {...fieldData} />
      )
  }
}

const InvalidInputType = (dataObj: any) => (
  <Input
    key={dataObj.name}
    {...dataObj}
    type="text"
    isInvalid
    isDisabled
    variant="filled"
    errorText={`type: "${dataObj.type || "unknown"}" not found for ${
      dataObj.name
    }`}
    value="Invalid dataObj type!"
  />
)
