import {
  CheckboxGroup as ChakraCheckboxGroup,
  CheckboxGroupProps as ChakraCheckboxGroupProps,
  SystemProps as ChakraSystemProps,
  forwardRef,
  RequiredIndicator,
  Stack,
} from "@chakra-ui/react"
import { FormControl, SharedFormControlProps } from "./FormControl"
import { Checkbox, CheckboxProps } from "./Checkbox"
import { StringToHTMLParser } from "components/StringToHTMLParser"

export interface CheckboxGroupProps
  extends ChakraCheckboxGroupProps,
    SharedFormControlProps {
  /**
   * Stack checkbox buttons vertically (default) or horizontally (inline)
   */
  isInline?: boolean
  /**
   * Array of options to become checkboxes
   */
  options?: CheckboxProps[]
  /**
   * The space between checkboxes. References theme "spacing" variables.
   */
  spacing?: ChakraSystemProps["margin"]
}

export const CheckboxGroup = forwardRef<CheckboxGroupProps, "fieldset">(
  (
    {
      errorText,
      helperText,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      label,
      //
      isInline,
      options,
      spacing,
      onBlur,
      value,
      children,
      ...rest
    },
    ref,
  ) => {
    const FormControlValues = {
      errorText,
      helperText,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      label,
    }

    const isRequiredOrphan = isRequired && options?.length === 1

    const handleCheckboxBlur = (e: any) => {
      onBlur(e)
    }

    const currentValue = value || []

    return (
      <FormControl asFieldSet {...FormControlValues}>
        <ChakraCheckboxGroup {...rest} value={currentValue}>
          <Stack
            direction={isInline ? "row" : undefined}
            spacing={spacing}
            className="checkbox-group"
          >
            {options
              ? options.map(option => (
                  <Checkbox
                    ref={ref}
                    key={option.value}
                    isInvalid={isInvalid}
                    onBlur={handleCheckboxBlur}
                    {...option}
                  >
                    <StringToHTMLParser string={option.label} as="span" />
                    {isRequiredOrphan && <RequiredIndicator />}
                  </Checkbox>
                ))
              : children}
          </Stack>
        </ChakraCheckboxGroup>
      </FormControl>
    )
  },
)
