import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Box, BoxProps, Button, ButtonGroup } from "@chakra-ui/react"
import { AppRoutes } from "utilities/constants"
interface AuthToolbarProps extends BoxProps {
  route:
    | AppRoutes.authForgotPassword
    | AppRoutes.authSignIn
    | AppRoutes.authSignUp
}
export const AuthToolbar = ({ route }: AuthToolbarProps) => {
  const navigate = useNavigate()

  const isSignInPage = route === AppRoutes.authSignIn
  const isSignUpPage = route === AppRoutes.authSignUp
  const isForgotPasswordPage = route === AppRoutes.authForgotPassword

  const [showToolbar, setShowToolbar] = useState(false)
  const [isCodeCopied, setIsCodeCopied] = useState(false)

  const getPageHTML = () => {
    // Get the div that Azure will inject content info
    const azureForm = document.getElementById("api")

    if (!azureForm) {
      console.error("Azure form element not found")
      return ""
    }

    // Save the value before we clear it to copy / paste
    const initialAzureFormHTML = azureForm.innerHTML

    // Clear the cloned form to return the empty `<div id="api"></div>` tag for Azure
    azureForm.innerHTML = ""

    // Clone the entire document
    const clonedDoc = document.documentElement.cloneNode(true) as HTMLElement

    // Find and remove the auth route nav from the cloned document
    const authRouteNav = clonedDoc.querySelector(".auth-route-nav")
    if (authRouteNav?.parentNode) {
      authRouteNav.parentNode.removeChild(authRouteNav)
    } else {
      console.warn("AuthToolbar element not found in cloned document")
    }

    // Remove LastPass elements
    const lastPassElements = clonedDoc.querySelectorAll(
      "[data-lastpass-root], [data-lastpass-icon-root]",
    )
    lastPassElements.forEach(el => el.parentNode?.removeChild(el))

    // Remove any inline scripts added by LastPass
    const scripts = clonedDoc.getElementsByTagName("script")
    for (let i = scripts.length - 1; i >= 0; i--) {
      const script = scripts[i]
      if (script?.textContent?.includes("LastPass")) {
        script.parentNode?.removeChild(script)
      }
    }

    // Get the HTML from the modified cloned document
    let html = clonedDoc.outerHTML

    // Remove any remaining LastPass-related attributes
    html = html.replace(/\s*data-lastpass-[^=]+="[^"]*"/g, "")

    // Reset Azure form HTML
    azureForm.innerHTML = initialAzureFormHTML

    return html
  }

  // Copy page HTML to paste into Azure or do something else with
  const copyPageHTML = () => {
    const html = getPageHTML()

    // Create a text area and set value as page HTML
    const textarea = document.createElement("textarea")
    textarea.value = html
    document.body.appendChild(textarea)

    // Select and copy the contents of the textarea (aka our HTML)
    navigator.clipboard.writeText(textarea.value)
    setIsCodeCopied(true)

    // Put the nav back, remove the textarea
    document.body.removeChild(textarea)

    // setIsCodeCopied(false) after two seconds
    setTimeout(() => {
      setIsCodeCopied(false)
    }, 2000)
  }

  let filename: string = ""
  switch (route) {
    case AppRoutes.authSignIn:
      filename = "signin.html"
      break
    case AppRoutes.authSignUp:
      filename = "signup.html"
      break
    case AppRoutes.authForgotPassword:
      filename = "forgotpassword.html"
      break
  }

  const savePageAsFile = (filename: string) => {
    const html = new Blob([getPageHTML()], { type: "text/html" })
    const a = document.createElement("a")
    a.href = URL.createObjectURL(html)
    a.download = `${filename}`
    a.hidden = true
    document.body.appendChild(a)
    a.click()
  }

  return (
    <Box
      className="auth-route-nav"
      background="white"
      boxShadow="sm"
      display={{ base: "none", sm: "flex" }}
      justifyContent="space-between"
      padding={2}
      position="absolute"
      width="fit-content"
      height="fit-content"
      zIndex="skipLink"
      top={0}
      left={0}
    >
      {showToolbar ? (
        <ButtonGroup variant="outline" size="sm">
          <Button title="Hide Toolbar" onClick={() => setShowToolbar(false)}>
            ◀
          </Button>
          <Button onClick={() => navigate("/")}>Home</Button>
          {!isSignInPage && (
            <Button onClick={() => navigate(`/${AppRoutes.authSignIn}`)}>
              Sign-In Page
            </Button>
          )}
          {!isSignUpPage && (
            <Button onClick={() => navigate(`/${AppRoutes.authSignUp}`)}>
              Sign-Up Page
            </Button>
          )}
          {!isForgotPasswordPage && (
            <Button
              onClick={() => navigate(`/${AppRoutes.authForgotPassword}`)}
            >
              Forgot Password Page
            </Button>
          )}

          <Button onClick={() => savePageAsFile(filename)}>
            Download {filename}
          </Button>
          <Button onClick={copyPageHTML} isDisabled={isCodeCopied}>
            {isCodeCopied ? "HTML Copied!" : "Copy HTML"}
          </Button>
        </ButtonGroup>
      ) : (
        <Button
          variant="outline"
          size="sm"
          onClick={() => setShowToolbar(true)}
        >
          Show Toolbar
        </Button>
      )}
    </Box>
  )
}
