import React, { useMemo } from "react"
import { Stack, Flex } from "@chakra-ui/react"
import { FieldDataParser } from "components/FieldDataParser"
import { NestedSectionDataParser } from "components/NestedSectionDataParser"
import { SectionData } from "types"

export interface FormFieldsDisplayProps {
  sectionData: SectionData
  onFieldBlur?: React.FocusEventHandler<HTMLDivElement>
  nestedIndex?: number
  isRequiredErrors?: boolean
  isFinalCheck?: boolean
}

export const FormFieldsDisplay = ({
  sectionData,
  nestedIndex,
  onFieldBlur,
  isRequiredErrors,
  isFinalCheck,
}: FormFieldsDisplayProps) => {
  const { fields, nestedSectionsMax, controlSectionName } = sectionData

  const combineObjectsByGroup = array => {
    const temp = {}

    for (let i = 0; i < array.length; i++) {
      const object = array[i]

      if (object.hasOwnProperty("group")) {
        if (!temp.hasOwnProperty(object.group)) {
          temp[object.group] = {}
          temp[object.group].elements = []
        }

        temp[object.group].elements.push(object)

        if (object.order === 1) {
          temp[object.group]["firstIndex"] = i
        }
      }
    }

    const result = {}

    Object.values(temp).forEach((el: any) => {
      result[el.firstIndex] = el.elements
    })

    return result
  }

  const combinedFields = useMemo(() => combineObjectsByGroup(fields), [fields])
  const filteredFields = useMemo(
    () => fields.map(item => (item.group ? null : item)),
    [fields],
  )

  const renderField = (field, index) => (
    <FieldDataParser
      key={field.name + index}
      nestedIndex={nestedIndex}
      fieldData={field}
      onBlur={onFieldBlur}
      controlSectionName={controlSectionName}
      isRequiredErrors={isRequiredErrors}
      isFinalCheck={isFinalCheck}
      width={field.width}
    />
  )

  return (
    <Stack spacing={6}>
      {!!nestedSectionsMax ? (
        <NestedSectionDataParser
          sectionData={sectionData}
          isFinalCheck={isFinalCheck}
        />
      ) : (
        filteredFields.map((field, index) => {
          if (combinedFields[index]) {
            return (
              <Flex
                className="combined-fields-wrapper"
                key={index}
                alignItems="baseline"
                gap="5"
                w="100%"
                flexDirection={{ base: "column", tab: "row" }}
              >
                {combinedFields[index].map((field, fieldIndex) =>
                  renderField(field, fieldIndex),
                )}
              </Flex>
            )
          } else if (field === null) {
            return null
          } else {
            return renderField(field, index)
          }
        })
      )}
    </Stack>
  )
}
