import { useContext } from "react"
import { ApplicationData } from "types"
import { AppRoutes, IS_PRODUCTION } from "utilities/constants"
import { ClientContext } from "context/ClientContext"
import { LayoutProvider } from "./LayoutProvider"
import { Route, Routes, useLocation } from "react-router-dom"
import { LogoutPage } from "pages/LogoutPage"

export const Unauthenticated = (localAppData: ApplicationData) => {
  const { client } = useContext(ClientContext)
  const location = useLocation()

  const applicationData = IS_PRODUCTION ? client.data : localAppData

  const isDataLoaded = !!location.pathname.match(AppRoutes.logout)

  return (
    <LayoutProvider
      applicationData={applicationData}
      isDataLoaded={isDataLoaded}
    >
      <Routes>
        <Route
          key={AppRoutes.logout}
          path={`/${AppRoutes.logout}`}
          element={<LogoutPage applicationData={applicationData} />}
        />
      </Routes>
    </LayoutProvider>
  )
}
