import { useContext } from "react"
import { forwardRef } from "@chakra-ui/react"
import FormContext from "context/FormContext"
import { Searchbox } from "components/Searchbox"
import {
  AP_SUBJECTS,
  COUNTRIES,
  ACTIVITIES,
  IB_SUBJECTS,
  LANGUAGES,
  RELIGIONS,
  SPORTS,
  STATES,
  COUNTIES,
} from "data/dataSets"
import { CustomSearchTypes, TextInputProps, Option } from "types"
import { HighSchoolSearch } from "components/HighSchoolSearch"
import { CollegeSearch } from "components/CollegeSearch"
import { optionize } from "data/utils"

interface SearchHandlerProps extends Omit<TextInputProps, "onChange"> {
  onChange: (value: any) => void
}

export const SearchHandler = forwardRef<SearchHandlerProps, "div">(
  (props, ref) => {
    const { name, ...rest } = props
    const {
      form: { getValues },
    } = useContext(FormContext)

    const formValues = getValues()

    let searchOptions: Option[] = []

    switch (props.type as CustomSearchTypes) {
      case "highSchoolSearch":
        return <HighSchoolSearch ref={ref} {...props} />
      case "collegeSearch":
        return <CollegeSearch ref={ref} {...props} />
      case "countrySearch":
        searchOptions = optionize(COUNTRIES)
        break
      case "stateSearch":
        searchOptions = optionize(STATES)
        break
      case "countySearch":
        searchOptions = optionize(COUNTIES)
        break
      case "sportSearch":
        searchOptions = optionize(SPORTS)
        break
      case "activitySearch":
        searchOptions = optionize(ACTIVITIES)
        break
      case "apSearch":
        searchOptions = optionize(AP_SUBJECTS)
        break
      case "ibSearch":
        searchOptions = optionize(IB_SUBJECTS)
        break
      case "languageSearch":
        searchOptions = optionize(LANGUAGES)
        break
      case "religionSearch":
        searchOptions = optionize(RELIGIONS)
        break
      default:
        // @ts-ignore
        searchOptions = rest.options
    }

    // Don't include not found for certain searches
    const includeNotFound = (props.type as CustomSearchTypes) !== "stateSearch"
    if (includeNotFound && searchOptions) {
      searchOptions = [
        ...searchOptions,
        { label: "Not Found", value: "Not Found" },
      ]
    }

    // This is the synchronous search for when options are known on load
    return (
      <Searchbox
        ref={ref}
        name={props.name}
        defaultValue={formValues[name]}
        options={searchOptions}
        isAsync={false}
        {...rest}
      />
    )
  },
)
