import React from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Alert,
  Flex,
  AlertTitle,
  AlertDescription,
  Button,
  ModalCloseButton,
  ModalProps,
} from "@chakra-ui/react"
import { useFormContext } from "react-hook-form"
import { ConditionSet, RedirectFieldAttributes } from "types"

export interface RedirectModalProps
  extends RedirectFieldAttributes,
    ModalProps {
  show?: ConditionSet
  hide?: ConditionSet
  onSave: () => void
}

export const RedirectModal = ({
  heading,
  description,
  redirectButton,
  cancelButton,
  show,
  hide,
  onSave,
}: RedirectModalProps) => {
  const [isOpen, setIsOpen] = React.useState(true)
  const [isRedirecting, setIsRedirecting] = React.useState(false)
  const { setValue } = useFormContext()

  const fieldOfReference =
    show?.conditions?.[0]?.name || hide?.conditions?.[0]?.name

  const handleFieldReset = () => {
    setValue(fieldOfReference, "")
    onSave()
    setIsOpen(false)
  }

  const handleRedirect = () => {
    setIsRedirecting(true)
    setTimeout(() => {
      // Smooth the redirect transition with a delay
      window.location.href = redirectButton.redirectUrl
    }, 1500)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleFieldReset}
      closeOnOverlayClick={false}
      size={{ base: "sm", sm: "md", md: "lg" }}
    >
      <ModalOverlay />
      <ModalContent marginX={4} marginTop={{ base: "5rem", md: "12.5rem" }}>
        <Alert>
          <Flex direction="column" position="relative">
            <ModalCloseButton zIndex={1} top={-2} right={-2} />
            <AlertTitle mb={2}>{heading}</AlertTitle>
            <AlertDescription mb={6}>{description}</AlertDescription>
            <Flex
              flexDirection={{ base: "column", sm: "row" }}
              gap={3}
              justifyContent={{ base: "center", sm: "space-between" }}
              marginX={{ base: "auto", sm: 0 }}
              maxWidth={{ base: "fit-content", sm: "100%" }}
            >
              <Button
                onClick={handleRedirect}
                order={{ sm: 2 }}
                size={{ base: "sm", md: "md" }}
                isDisabled={isRedirecting}
              >
                {redirectButton.text}
              </Button>
              <Button
                onClick={handleFieldReset}
                variant="link"
                paddingX="0!"
                order={{ sm: 1 }}
                size={{ base: "sm", md: "md" }}
                isLoading={isRedirecting}
                loadingText="Redirecting..."
              >
                {cancelButton.text}
              </Button>
            </Flex>
          </Flex>
        </Alert>
      </ModalContent>
    </Modal>
  )
}
