import { extendTheme } from "@chakra-ui/react"

/**
 * This is the base theme that all future themes will extend.
 *
 * It is an extension of the default theme of Chakra UI and there are several
 * other `foundations` theme tokens available that are not being modified here.
 * They include: blur, borders, radius, transition and zIndices.
 *
 * Reference these default tokens here:
 * https://chakra-ui.com/docs/styled-system/theming/theme
 *
 * To modify or add to these tokens, please follow the folder structure here:
 * https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/foundations
 * As well as the pattern set up below.
 *
 * This pattern follows Chakra UI Scaling Out Your Project best practices:
 * https://chakra-ui.com/docs/theming/customize-theme#scaling-out-your-project
 *
 */
import { breakpoints } from "./breakpoints"
import { colors } from "./colors"
import { components } from "./components"
import { shadows } from "./shadows"
import { typography } from "./typography"

const foundations = {
  // blur,
  // borders,
  breakpoints,
  colors,
  // radius,
  shadows,
  sizes: {
    "10px": "0.625rem",
    "13px": "0.8125rem",
    "18px": "1.125rem",
  },
  space: {
    "10px": "0.625rem",
    "13px": "0.8125rem",
    "14px": "0.875rem",
    "18px": "1.125rem",
    11: "2.75rem",
  },
  // transition,
  ...typography,
  // zIndices
}

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
}

const styles = {
  global: {
    html: {
      minHeight: "100%",
      // Better match fonts to Figma:
      // https://forum.figma.com/t/font-in-browser-seem-bolder-than-in-the-figma/24656/6
      webkitFontSmoothing: "antialiased",
      mozOsxFontSmoothing: "grayscale",
      fontSmooth: "never",
    },
    "html, body, #root": {
      display: "flex",
      flex: 1,
    },
    body: {
      backgroundColor: "pageBgColor",
      color: "bodyColor",
      fontSize: { base: "bodyMobile", md: "bodyDesktop" },
      overflow: "auto",
    },
    "p, ol, ul": {
      "&:not(:last-child)": {
        marginBottom: 4,
      },
    },
    "ul, ol": {
      marginLeft: 5,
    },
    a: {
      color: "buttonLinkColor",
      textDecoration: "underline",
      _hover: { color: "buttonLinkHoverColor" },
    },

    ".is-grouped": {
      "&:only-child": {
        flexBasis: "100%",
      },
      "&:not(:only-child) label": {
        whiteSpace: "nowrap",
      },
    },
  },
}

const theme = extendTheme({
  ...foundations,
  components,
  styles,
  config,
})

export default theme
