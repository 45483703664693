import { OptionData } from "types"

export const ACTIVITIES: OptionData = [
  "Community Activities: 4H",
  "Community Activities: Boy Scouts or Girl Scouts",
  "Community Activities: Church or Religious",
  "Community Activities: Cultural or Ethnic",
  "Community Activities: Environmental",
  "Community Activities: Internship",
  "Community Activities: Job",
  "Community Activities: Other",
  "Community Activities: Political",
  "Community Activities: Service",
  "Community Activities: Social Conscience",
  "Community Service: Family",
  "Honors & Leadership: Academic Award",
  "Honors & Leadership: Athletics - Academic Award",
  "Honors & Leadership: Athletics - MVP",
  "Honors & Leadership: Athletics - Team Captain",
  "Honors & Leadership: Boy Scouts - Eagle Scout",
  "Honors & Leadership: Boys or Girls State",
  "Honors & Leadership: Girl Scouts - Gold Award",
  "Honors & Leadership: Honor Roll",
  "Honors & Leadership: Leadership Award",
  "Honors & Leadership: Leadership Conference",
  "Honors & Leadership: National Honor Society",
  "Honors & Leadership: National Merit Commended",
  "Honors & Leadership: National Merit Semi-Finalist",
  "Honors & Leadership: Other",
  "Honors & Leadership: Who's Who",
  "HS Activities: Academic Club",
  "HS Activities: Art",
  "HS Activities: Cheerleading",
  "HS Activities: Color Guard",
  "HS Activities: Computer/Tech",
  "HS Activities: Dance",
  "HS Activities: Dance Team",
  "HS Activities: DECA",
  "HS Activities: Drill Team",
  "HS Activities: FBLA",
  "HS Activities: Foreign Exchange",
  "HS Activities: Key Club",
  "HS Activities: LGBT",
  "HS Activities: Literary Magazine",
  "HS Activities: Mock Trial",
  "HS Activities: Model UN",
  "HS Activities: Music - Band",
  "HS Activities: Music - Choir",
  "HS Activities: Music - Jazz Band",
  "HS Activities: Music - Orchestra",
  "HS Activities: Music - Show Choir",
  "HS Activities: Newspaper",
  "HS Activities: Other",
  "HS Activities: Pom-Pom",
  "HS Activities: Radio - TV",
  "HS Activities: ROTC",
  "HS Activities: Speech & Debate",
  "HS Activities: Student Government Officer",
  "HS Activities: Student Government Representative",
  "HS Activities: Study Abroad",
  "HS Activities: Theater - Drama",
  "HS Activities: Theater - Musical",
  "HS Activities: Yearbook",
  "HS Athletics: Baseball",
  "HS Athletics: Basketball",
  "HS Athletics: Crew",
  "HS Athletics: Cross County",
  "HS Athletics: Football",
  "HS Athletics: Golf",
  "HS Athletics: Hockey",
  "HS Athletics: Lacrosse",
  "HS Athletics: Other",
  "HS Athletics: Polo",
  "HS Athletics: Rugby",
  "HS Athletics: Soccer",
  "HS Athletics: Softball",
  "HS Athletics: Swimming",
  "HS Athletics: Tennis",
  "HS Athletics: Track",
  "HS Athletics: Volleyball",
]
