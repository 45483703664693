import React from "react"
import {
  FormLabel as ChakraFormLabel,
  FormLabelProps as ChakraFormLabelProps,
  As,
} from "@chakra-ui/react"
import { FormControlOptionalLabel } from "components/common/FormControlOptionalLabel"

export interface FormControlLabelProps extends ChakraFormLabelProps {
  isFieldRequired?: boolean
  isFieldDisabled?: boolean
  isFieldSet?: boolean
}

export const FormControlLabel = ({
  children,
  isFieldRequired,
  isFieldDisabled,
  isFieldSet,
}: FormControlLabelProps) => {
  const sharedLabelProps = {
    as: (isFieldSet ? "legend" : undefined) as As,
    mb: 1,
    children,
  }

  if (isFieldRequired) {
    return <ChakraFormLabel {...sharedLabelProps} />
  }

  return (
    <FormControlOptionalLabel
      isFieldDisabled={isFieldDisabled}
      {...sharedLabelProps}
    />
  )
}
