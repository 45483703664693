import { OptionData } from "types"

export const LANGUAGES: OptionData = [
  "English",
  "Spanish",
  "Aceh",
  "Afrikaans",
  "Akan",
  "Albanian",
  "American Sign Language",
  "Amharic",
  "Arabic",
  "Armenian",
  "Assamese",
  "Awadhi",
  "Azerbaijani",
  "Bagheli",
  "Bali",
  "Baluchi",
  "Banjar",
  "Bavarian",
  "Belarusan",
  "Bemba",
  "Bengali",
  "Bhojpuri",
  "Bikol",
  "Bosnian",
  "Bugis",
  "Bulgarian",
  "Burmese",
  "Cantonese",
  "Catalan-Valencian-Balear",
  "Cebuano",
  "Chhattisgarhi",
  "Chittagonian",
  "Creole",
  "Croatian",
  "Czech",
  "Danish",
  "Deccan",
  "Dholuo",
  "Dogri",
  "Domari",
  "Dutch",
  "Estonian",
  "Éwé",
  "Filipino",
  "Finnish",
  "French",
  "Fulah",
  "Galician",
  "Ganda",
  "Georgian",
  "German, Standard",
  "German, Swiss",
  "Gikuvu",
  "Gilaki",
  "Godwari",
  "Greek",
  "Guarani",
  "Haitian Creole",
  "Haryanvi",
  "Hassaniyya",
  "Hausa",
  "Hawaiian",
  "Hebrew",
  "Hiligaynon",
  "Hindi",
  "Hmong",
  "Hungarian",
  "Hunsrik",
  "Igbo",
  "ilocano",
  "Indonesian",
  "Italian",
  "Jamaican Creole English",
  "Japanese",
  "Javanese",
  "Kabyle",
  "Kamba",
  "Kanauji",
  "Kannada",
  "Kanuri",
  "Kashmiri",
  "Kazakh",
  "Khmer, Central",
  "Kimbundu",
  "Kirundi",
  "Kituba",
  "Kongo",
  "Konkani",
  "Korean",
  "Kurdish",
  "Kyrgyz",
  "Lahnda",
  "Lambadi",
  "Lao",
  "Latvian",
  "Lingala",
  "Lithuanian",
  "Lombard",
  "Luba-Kasai",
  "Macedonian",
  "Madura",
  "Magahi",
  "Mainfränkisch",
  "Maithili",
  "Makhuwa",
  "Malagasy",
  "Malay",
  "Malayalam",
  "Mandarin",
  "Mandingo",
  "Marathi",
  "Marwari",
  "Mazanderani",
  "Mewati",
  "Mina",
  "Minangkabau",
  "Mongolian",
  "Mòoré",
  "Musi",
  "Napoletano-Calabrese",
  "Nepali",
  "Norwegian",
  "Nyanja",
  "Oriva",
  "Oromo",
  "Other",
  "Persian, Farsi",
  "Piemontese",
  "Polish",
  "Portuguese",
  "Punjabi",
  "Pushto",
  "Quechua",
  "Rajasthani",
  "Rangpuri",
  "Romanian",
  "Rundi",
  "Russian",
  "Rwanda, Kinyarwanda",
  "Santali",
  "Serbian",
  "Shan",
  "Shona",
  "Sicilian",
  "Sindhi",
  "Sinhala",
  "Slovak",
  "Slovene",
  "Somali",
  "Sotho, Northern",
  "Sotho, Southern",
  "Sukuma",
  "Sunda",
  "Swahili",
  "Swedish",
  "Sylheti",
  "Tachelhit",
  "Tagalog",
  "Tajiki",
  "Tamazight, Central Atlas",
  "Tamil",
  "Tatar",
  "Telugu",
  "Thai",
  "Thai, Northeastern",
  "Thai, Northern",
  "Thai, Southern",
  "Tigrigna",
  "Tsonga",
  "Tswana",
  "Turkish",
  "Turkmen",
  "Twi",
  "Ukrainian",
  "Umbundu",
  "Urdu",
  "Uyghur",
  "Uzbek",
  "Varhadi-Nagpuri",
  "Venetian",
  "Vietnamese",
  "Vlaams",
  "Wolof",
  "Xhosa",
  "Yiddish",
  "Yoruba",
  "Zhuang",
  "Zulu",
]
