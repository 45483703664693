import { env } from "env";

export enum AppRoutes {
  finalCheck = "final-check",
  confirmation = "confirmation",
  logout = "logout",
  authSignIn = "sign-in",
  authSignUp = "sign-up",
  authForgotPassword = "forgot-password",
}

export const AuthRoutes = [
  AppRoutes.authSignUp,
  AppRoutes.authSignIn,
  AppRoutes.authForgotPassword,
] as const

export type AuthRoute = (typeof AuthRoutes)[number]

export const DEFAULT_ADDITIONAL_SECTION_LIMIT = 10

export const BASE_FIELD_SPACING = 6
export const FIELD_SPACING = { base: BASE_FIELD_SPACING }

export const IS_PRODUCTION = env.REACT_APP_ENVIRONMENT === "production"

export const IS_DEVELOPMENT =
  env.REACT_APP_ENVIRONMENT === "development"

export const IDLE_TIMEOUT = 1000 * 60 * 30 // 30 minutes

export const CID_HEADER = "x-cid"

export const API_ROOT_HEADER = "x-base-url"

export const IGNORE_PREFIX = "ignore__"