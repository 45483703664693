import { env } from "env"
import { useContext, useEffect, PropsWithChildren } from "react"
import { TealiumViewTrack } from "tealium"
import { AuthContext } from "context/AuthContext"
import { ClientContext } from "context/ClientContext"
import { UserContext } from "context/UserContext"
import { ClientApi } from "api/client"
import { attachRedirectUrl, getParamFromUrlHash } from "utilities/helpers"
import { IS_DEVELOPMENT } from "utilities/constants"
import { log, SHOW_LOGS } from "utilities/logging"

interface ChildrenProps {
  children: React.ReactNode
}

const ClientWrapper: React.FC<PropsWithChildren<ChildrenProps>> = ({
  children,
}) => {
  const { cid } = useContext(UserContext)
  const { adb2cToken, token } = useContext(AuthContext)
  const { client, setClient } = useContext(ClientContext)
  const urlHash = window.location.hash

  useEffect(() => {
    if (!client) {
      ClientApi.find({ cid }).then(res => {
        setClient(res)
      })
    }
    // eslint-disable-next-line
  }, [cid])

  useEffect(() => {
    // If theres no tokens, go auth through ADB2C
    const idTokenFromHash = getParamFromUrlHash(urlHash, "id_token")
    const redirectionUrl = IS_DEVELOPMENT
      ? env.REACT_APP_ADB2C_REDIRECT_URL
      : client?.redirectUrl

    if (
      client &&
      !token &&
      !adb2cToken &&
      !idTokenFromHash &&
      redirectionUrl &&
      client.adb2cUrl
    ) {
      TealiumViewTrack({
        tealium_event: "app_auth_redirect",
        customer_id: client.cid,
      })

      const finalUrl = attachRedirectUrl(
        client.adb2cUrl,
        encodeURIComponent(redirectionUrl),
      )

      window.location.replace(finalUrl)
    }
    // esline-disable-next-line
  }, [token, adb2cToken, urlHash, client])

  if (!client) {
    log(SHOW_LOGS.CLIENT_ID, "Client not found, showing loader")
    return <></>
  }

  log(SHOW_LOGS.CLIENT_ID, `Client loaded: ${client.cid}`)
  return <>{children}</>
}

export default ClientWrapper
