import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { ApplicationData } from "types"

/**
 * Modify the page HTML to prepare for copying to ADB2C
 */
export const useAuthHead = (applicationData: ApplicationData) => {
  const navigate = useNavigate()

  useEffect(
    
    () => {
      // Add "auth" to root div for styling
      const root = document.querySelector("#root")
      if (root) {
        root.classList.add("auth")
      }

      // Remove <noscript> tag and sign-out link
      const noScript = document.body.querySelector("noscript")
      const userSignout = document.querySelector("#user-signout")
      if (noScript && userSignout) {
        noScript.remove()
        userSignout.remove()
      }

      // Update favicon, page title and description
      const favicon = document.querySelector(`[rel="icon"]`)
      const pageTitle = document.querySelector("title")
      const pageDescription = document.querySelector(`[name="description"]`)
      if (favicon && pageTitle && pageDescription) {
        favicon.setAttribute("href", applicationData.meta.favicon)
        pageTitle.innerHTML = applicationData.meta.title
        pageDescription.setAttribute("content", "") // blank, update if needed
      }
    },
    [applicationData, navigate],
  )
}
