import {
  InfoComponentTypes,
  OptionsHtmlInputType,
  SingleHtmlInputType,
} from "types/inputs"

export interface ApplicationData {
  layout: LayoutDetails
  meta: MetaData
  pages: PageData[]
  susiPages?: SusiPages
}

export type LayoutVersion = "1" | "2" | "centered" | "hollins"

export interface LayoutDetails {
  version: LayoutVersion
  contentWidth?: string
}

export interface MetaData {
  title: string
  name: string
  description?: string
  logo?: string
  footerLogo?: string
  favicon?: string
  contact: ContactInfo
  submissionRedirectUrl?: string
}

export interface SusiPageData {
  heading: string
  subheading?: string
  htmlContent?: string // string containing html tags
  formHeading?: string
}

export interface SusiPages {
  signIn: SusiPageData
  signUp: SusiPageData
  forgotPassword: SusiPageData
}

export interface ContactInfo {
  // address?: string
  email?: `${string}@${string}.${string}`
  phone?: `${string}-${string}-${string}`
  website?: `http${"s" | ""}://${string}.${string}`
}

export interface PageData {
  id: string
  slug: string
  title: string
  navTitle?: string // this isn't used in any components
  mobileNavTitle?: string
  description: string | null
  sections: SectionData[]
}

export interface SectionData {
  conditionalSection?: boolean
  title?: string
  description?: string
  fields: FieldAttributes[]
  nestedSectionsMin?: number
  // This attribute must be defined to get nested sections options
  nestedSectionsMax?: number
  nestedSectionTitle?: string
  nestedSectionDescription?: string | null
  nestedSectionItemName?: string
  controlSectionName?: string
  hide?: ConditionSet | boolean
  show?: ConditionSet | boolean
  isOptional?: boolean
}

/** Represents an array of option data, where each item is either a string or a tuple of two strings. */
export type OptionData = (string | [string, string])[]

export interface Option {
  label: string
  value: string
}

/**
 * Represents a complex option item with code, label, and optional custom value.
 */
export interface ComplexOptionItem {
  /** The code of the option. Example: "CSSD" */
  code: string
  /** The label of the option. Example: "Software Development" */
  label: string
  /**
   * Custom value to use when it differs from the default format.
   * If not provided, the value will be formatted as "{code} - {label}".
   * Example: "CSSD - Software Engineering"
   */
  customValue?: `${string} - ${string}`
}

export type FieldAttributes =
  | SingleValueFieldAttributes
  | SingleValueOptionsFieldAttributes
  | MultiValueOptionsFieldAttributes
  | InfoOnlyFieldAttributes
  | RedirectFieldAttributes

// Differentiate between the types for html inputs and data fields
export type SingleValueFieldTypes =
  | SingleHtmlInputType
  | CustomSearchTypes
  | DateFieldTypes
  | "zip_code"
  | "redirectModal"

export type DateFieldTypes = "date" | "month" | "year"

export type CustomSearchTypes =
  | "highSchoolSearch"
  | "collegeSearch"
  | "countrySearch"
  | "stateSearch"
  | "countySearch"
  | "apSearch"
  | "ibSearch"
  | "activitySearch"
  | "sportSearch"
  | "languageSearch"
  | "religionSearch"

export type CustomOptionsFieldType = "sienaBusinessConcentrationSelect"

// Differentiate between the types for html inputs and data fields
export type OptionsFieldTypes = OptionsHtmlInputType | CustomOptionsFieldType

// Attributes shared by all fields in the data
export interface PrefillProps {
  value: string | number | { label: string; value: string }
  conditionSet?: ConditionSet
  override?: boolean
}

export interface BaseFieldAttributes {
  name: string
  label: string
  validations?: Validation
  errorText?: string
  helperText?: string
  isDisabled?: boolean
  isInvalid?: boolean
  isReadOnly?: boolean
  show?: ConditionSet | boolean
  hide?: ConditionSet | boolean
  isRequired?: ConditionSet | boolean
  placeholder?: string
  prefill?: PrefillProps[]
  width?: string
  group?: number
  order?: number
  info?: string
}

export interface RedirectFieldAttributes extends BaseFieldAttributes {
  type: "redirectModal"
  heading: string
  description: string
  redirectButton: {
    text: string
    redirectUrl: string
  }
  cancelButton: {
    text: string
  }
}

// Attributes of a field fulfilled/answered by a single value
export interface SingleValueFieldAttributes extends BaseFieldAttributes {
  type: SingleValueFieldTypes
}

export interface InfoOnlyFieldAttributes extends BaseFieldAttributes {
  type: InfoComponentTypes
  value: string
}

// Attributes of a field fullfilled by one/many of a list of Options
export interface OptionsFieldAttributes extends BaseFieldAttributes {
  type: OptionsFieldTypes
  options: Option[]
}

// Attributes of a field fullfilled by ONE of a list of options
export interface SingleValueOptionsFieldAttributes
  extends OptionsFieldAttributes {
  type: "select" | "radio"
  defaultValue?: string
}

// Attributes of a field fulfilled by one OR multiple of a list of options
export interface MultiValueOptionsFieldAttributes
  extends OptionsFieldAttributes {
  defaultValue?: string[]
}

export interface Validation {
  length?: {
    is?: number
    min?: number
    max?: number
  }
  value?: {
    divisor?: number
    is?: number
    min?: number
    max?: number
  }
  date?: {
    isInPast?: true
    isInFuture?: true
    maxDaysInPast?: number
    maxDaysInFuture?: number
    maxMonthsInPast?: number
    maxMonthsInFuture?: number
    maxYearsInPast?: number
    maxYearsInFuture?: number
    // the before & after strings are string typs that match this 'MM/DD/YYYY'
    before?:
      | `${number}/${number}/${number}`
      | `${number}/${number}`
      | `${number}`
    after?:
      | `${number}/${number}/${number}`
      | `${number}/${number}`
      | `${number}`
  }
  fileAccepts?: string[]
}

// Currently fields with conditions cannot be validated by the schema as required,
// The BE could return an error and explain this but the FE cannot currently warn about this
export interface ConditionSet {
  // conditions arrays use && logic by default
  conditions: FieldCondition[]
  // To use || logic, add `logicalOr: true`
  logicalOr?: boolean
  // For complex conditionals, use `logic: "0&&(1||2||3)"`
  // where the number represents the index of an option.
  logic?: string
}

export interface DateCondition {
  before?: string
  after?: string
  equals?: string
}

export interface FieldCondition {
  name: string
  value?: any
  notValue?: any
  date?: DateCondition
}

export interface FieldMetaData {
  page?: PageData
  section?: SectionData
  pageNumber?: number
}

export enum DateFormat {
  date = "mm/dd/yyyy",
  month = "mm/yyyy",
  year = "yyyy",
}

export interface ComponentSingleFieldProps extends SingleValueFieldAttributes {
  isRequired?: boolean
}
