import { useRef, useEffect, useId } from "react"
import { useOptionalLabelManager } from "context/OptionalLabelContext"

export const useFieldWidthComparison = (
  label: string,
  optionalText: string,
) => {
  const fieldRef = useRef<HTMLDivElement>(null)
  const { showOptionalTags, registerField, unregisterField } =
    useOptionalLabelManager()
  const id = useId()

  const getTextWidth = (text: string) => {
    const canvas = document.createElement("canvas")
    const context = canvas.getContext("2d")
    return context.measureText(text).width
  }

  useEffect(() => {
    const updateWidth = () => {
      if (fieldRef.current) {
        const fieldWidth = fieldRef.current.offsetWidth
        const labelWidth = getTextWidth(label)
        const optionalWidth = getTextWidth(optionalText)

        const hasSpace = fieldWidth >= labelWidth + optionalWidth + 10 // 10px buffer
        registerField(id, hasSpace)
      }
    }

    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => {
      window.removeEventListener("resize", updateWidth)
      unregisterField(id)
    }
  }, [label, optionalText, id, registerField, unregisterField])

  return { fieldRef, showOptionalTags }
}
