import {
  filterOptionsByLabel,
  filterOutOptions,
  getOptionValueByLabel,
  createOptionDataFromItems,
} from "data/utils"
import { ComplexOptionItem } from "types"

const sienaMajors: ComplexOptionItem[] = [
  { code: "UNDE", label: "Not Sure - Undecided" },
  { code: "ACBS", label: "Accounting" },
  { code: "ACBS", label: "Accounting (3+1 Bachelor's/Master's)" },
  { code: "ATSC", label: "Actuarial Science" },
  { code: "AMST", label: "American Studies" },
  { code: "APEL", label: "Applied Physics - Electrical" },
  { code: "APML", label: "Applied Physics - Mechanical" },
  { code: "APPH", label: "Applied Physics (Pre-Engineering)" },
  { code: "VADS", label: "Art" },
  { code: "CSAI", label: "Artificial Intelligence" },
  { code: "APHY", label: "Astrophysics" },
  { code: "BICM", label: "Biochemistry" },
  { code: "BIBS", label: "Biology" },
  { code: "BUSI", label: "Business" },
  { code: "BABS", label: "Business Analytics" },
  { code: "CHEM", label: "Chemistry" },
  { code: "CBBS", label: "Chemistry-Business" },
  { code: "ECCE", label: "Childhood Education" },
  { code: "CLAS", label: "Classics" },
  { code: "COMM", label: "Communications" },
  { code: "CMJR", label: "Communications - Journalism" },
  {
    code: "CMMK",
    label: "Communications - Marketing Communications & Digital Marketing",
  },
  { code: "CMPL", label: "Communications - Political Communications" },
  { code: "CMSP", label: "Communications - Sports Communications" },
  { code: "PYCP", label: "Computational Physics" },
  { code: "CSIS", label: "Computer Science" },
  { code: "CSAI", label: "Computer Science - Artificial Intelligence" },
  { code: "CSCY", label: "Computer Science - Cybersecurity" },
  { code: "CSET", label: "Computer Science - Education" },
  { code: "CSEN", label: "Computer Science - Engineering 3/2" },
  { code: "CSEC", label: "Computer Science - Entrepreneurship" },
  { code: "CSGD", label: "Computer Science - Game Development" },
  { code: "CSIN", label: "Computer Science - Information Systems" },
  { code: "CSSD", label: "Computer Science - Software Development" },
  { code: "CREA", label: "Creative Arts" },
  { code: "CRJS", label: "Criminal Justice" },
  { code: "CRJF", label: "Criminal Justice - Forensics" },
  { code: "CRHR", label: "Criminal Justice - Human Rights" },
  {
    code: "CRJP",
    label: "Criminal Justice - Policy, Practice and Administration",
  },
  { code: "CSCY", label: "Cyber Security" },
  { code: "DASC", label: "Data Science" },
  { code: "DSAP", label: "Data Science, Applied" },
  { code: "VADS", label: "Design" },
  { code: "ECCE", label: "Early Child and Child Education (B-6)" },
  { code: "ECCE", label: "Early Childhood Education" },
  { code: "ECAR", label: "Economics - BA" },
  { code: "ECBU", label: "Economics - BS" },
  { code: "ASED", label: "Education - American Studies" },
  { code: "BIED", label: "Education - Biology" },
  { code: "CHED", label: "Education - Chemistry" },
  { code: "CHED", label: "Education - Elementary" },
  { code: "ENED", label: "Education - English" },
  { code: "UNBE", label: "Education - Exploring Business" },
  { code: "UNAE", label: "Education - Exploring Liberal Arts" },
  { code: "UNSE", label: "Education - Exploring Science" },
  { code: "FRED", label: "Education - French" },
  { code: "HYED", label: "Education - History" },
  { code: "MAED", label: "Education - Mathematics" },
  { code: "PYED", label: "Education - Physics" },
  { code: "SPED", label: "Education - Spanish" },
  { code: "ECCE", label: "Elementary Education" },
  { code: "EN32", label: "Engineering - 3/2 Program" },
  { code: "EN41", label: "Engineering - 4/1 Program" },
  { code: "ENGL", label: "English" },
  { code: "MGEN", label: "Entrepreneurship" },
  { code: "ENVS", label: "Environmental Science" },
  { code: "ENVS", label: "Environmental Science: Conservation Science Track" },
  { code: "ENVE", label: "Environmental Science - Earth Systems Track" },
  { code: "ENVA", label: "Environmental Studies" },
  { code: "UNBU", label: "Exploring Business (Undecided)" },
  { code: "UNAR", label: "Exploring Liberal Arts (Undecided)" },
  { code: "UNSC", label: "Exploring Science (Undecided)" },
  { code: "FINC", label: "Finance" },
  { code: "CRJF", label: "Forensics" },
  { code: "FREN", label: "French" },
  { code: "CSGD", label: "Game Development" },
  { code: "HLST", label: "Health Studies" },
  { code: "HSAD", label: "Health Studies - Administration" },
  { code: "HSHS", label: "Health Studies - Health Science" },
  { code: "HIST", label: "History" },
  { code: "MGHR", label: "Human Resources" },
  { code: "CSIN", label: "Information Systems" },
  { code: "MGIM", label: "International Management" },
  { code: "PSIR", label: "International Relations" },
  { code: "MGLD", label: "Leadership" },
  { code: "MGMT", label: "Management" },
  {
    code: "MGEN",
    label: "Management - Entrepreneurship and Small Business Management Track",
  },
  { code: "MGIM", label: "Management - International Management Track" },
  { code: "MGLD", label: "Management - Leadership Track" },
  { code: "MGPM", label: "Management - Project Management Track" },
  { code: "MGSP", label: "Management - Sports Management Track" },
  {
    code: "MGHR",
    label: "Management - Strategic Human Resource Management Track",
  },
  { code: "MGSM", label: "Management - Strategic Management Track" },
  { code: "MRKT", label: "Marketing" },
  { code: "MTBA", label: "Mathematics - BA" },
  { code: "MTBS", label: "Mathematics - BS" },
  { code: "MACL", label: "Mathematics, Applied - BA" },
  { code: "MAMA", label: "Mathematics, Applied - BS" },
  { code: "MTCL", label: "Mathematics, Applied - Classical BS" },
  {
    code: "MTMA",
    label:
      "Mathematics, Applied - Modeling and Analytics in the Natural Science BS",
  },
  { code: "MAST", label: "Mathematics, Applied - Statistics BA" },
  { code: "MTST", label: "Mathematics, Applied - Statistics BS" },
  { code: "NRBL", label: "Nursing" },
  { code: "PHIL", label: "Philosophy" },
  { code: "PHYS", label: "Physics" },
  { code: "POSC", label: "Political Science" },
  { code: "PSAP", label: "Political Science - American Politics & Justice" },
  { code: "PSIR", label: "Political Science - International Relations" },
  { code: "PSPA", label: "Political Science - Public Policy & Administration" },
  { code: "PSYC", label: "Psychology" },
  { code: "RELG", label: "Religious Studies" },
  { code: "SWRK", label: "Social Work" },
  { code: "SOCI", label: "Sociology" },
  {
    code: "CSSD",
    label: "Software Development",
    customValue: "CSSD - Software Engineering",
  },
  { code: "SPAN", label: "Spanish" },
  {
    code: "MGSP",
    label: "Sports Management",
    customValue: "MGSP - Management - Sports Management Track",
  },
  { code: "MTST", label: "Statistic" },
  { code: "THTR", label: "Theatre" },
  { code: "THTD", label: "Theatre Design/Tech" },
  { code: "THMG", label: "Theatre Management" },
  { code: "THPF", label: "Theatre Performance" },
  { code: "UNDE", label: "Undecided" },
  { code: "UNBU", label: "Undecided - Business" },
  { code: "UNAR", label: "Undecided - Liberal Arts" },
  { code: "UNSC", label: "Undecided - Science" },
  { code: "VADS", label: "Visual Art and Design" },
]

export const SIENA_MAJORS = createOptionDataFromItems(sienaMajors)

export const SIENA_BIOLOGY = filterOptionsByLabel(SIENA_MAJORS, "Biology")

const SIENA_NURSING = filterOptionsByLabel(SIENA_MAJORS, "Nursing")

export const SIENA_NURSING_VALUE = getOptionValueByLabel(
  SIENA_MAJORS,
  "Nursing",
)

/** All sienaMajors except Nursing */
export const SIENA_MAJORS_HEOP = filterOutOptions(SIENA_MAJORS, [SIENA_NURSING])
