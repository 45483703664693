import { useEffect } from "react"

interface UseForgotPasswordScriptProps {
  customEmailAddressLabel?: string
}

export const useForgotPasswordScript = (props: UseForgotPasswordScriptProps = {}) => {
  useEffect(() => {
    const {
      customEmailAddressLabel = "Email Address",
    } = props

    const selectors = {
      emailLabel: "#email_label",
      emailInput: "#email",
      workingDiv: ".working",
      continueButton: "button#continue",
      verifySendButton: "button#emailVerificationControl_but_send_code",
      verifySubmitButton: "button#emailVerificationControl_but_verify_code",
    }

    const script = document.createElement("script")
    script.type = "text/javascript"
    script.text = `
    function generateLink(userflow) {
      const url = new URL(window.location.href);
      url.searchParams.set("p", "b2c_1a_" + userflow);
      return url.toString();
    }

    function hideElement(el) {
      if (el) el.style.display = "none";
    }

    function showElement(el) {
      if (el) el.style.display = "block";
    }

    function customizeLabel() {
      const label = document.querySelector("${selectors.emailLabel}");
      if (label) {
        label.textContent = "${customEmailAddressLabel}";
        if (!label.querySelector('.required-star')) {
          const star = document.createElement('span');
          star.className = 'required-star';
          star.textContent = '*';
          label.appendChild(star);
        }
      }
      const verificationLabel = document.querySelector("#VerificationCode_label");
      if (verificationLabel) {
        if (!verificationLabel.querySelector('.required-star')) {
          const star = document.createElement('span');
          star.className = 'required-star';
          star.textContent = '*';
          verificationLabel.appendChild(star);
        }
      }
      const input = document.querySelector("${selectors.emailInput}");
      if (input) {
        input.placeholder = "${customEmailAddressLabel}";
        input.setAttribute('aria-label', "${customEmailAddressLabel}");
      }
    }

    function swapErrorAndInput() {
      const attrEntries = document.querySelectorAll('#emailVerificationControl .attrEntry');
      attrEntries.forEach(entry => {
        const errorDiv = entry.querySelector('.error.itemLevel');
        if (errorDiv) {
          errorDiv.remove();
          entry.appendChild(errorDiv);
        }
      });
    }

    function updateContinueButtonVisibility() {
      const continueButton = document.querySelector("${selectors.continueButton}");
      const workingDiv = document.querySelector("${selectors.workingDiv}");
      const verifySendButton = document.querySelector("${selectors.verifySendButton}");
      const verifySubmitButton = document.querySelector("${selectors.verifySubmitButton}");

      if (!continueButton) return;

      const isPageWorking = workingDiv && workingDiv.style.display !== "none";
      const isVerifySendVisible = verifySendButton && verifySendButton.style.display !== "none";
      const isVerifySubmitVisible = verifySubmitButton && verifySubmitButton.style.display !== "none";

      if (isPageWorking || isVerifySendVisible || isVerifySubmitVisible) {
        hideElement(continueButton);
      } else {
        showElement(continueButton);
      }
    }

    function observeChanges() {
      const workingDiv = document.querySelector("${selectors.workingDiv}");
      if (workingDiv) {
        const observer = new MutationObserver(updateContinueButtonVisibility);
        observer.observe(workingDiv, { attributes: true, attributeFilter: ['style'] });
      }
    }

    function updateDOM() {
      customizeLabel();
      swapErrorAndInput();
      updateContinueButtonVisibility();
    }

    function checkAndUpdate(attempts = 0, maxAttempts = 50) {
      if (document.querySelector("#attributeVerification")) {
        updateDOM();
        observeChanges();
      } else if (attempts < maxAttempts) {
        setTimeout(() => checkAndUpdate(attempts + 1, maxAttempts), 100);
      } else {
        console.warn("Document not found. Exiting.");
      }
    }

    // Run on load
    window.addEventListener('load', checkAndUpdate);

    // Also run immediately in case the page is already loaded
    checkAndUpdate();
    `

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [props])
}