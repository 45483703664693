import { env } from "env"
import { ApplicationData } from "types"
import { CREIGHTON_DATA } from "data/colleges/creighton/creighton"
import { SIENA_DATA } from "data/colleges/siena/siena"
import { VANGUARD_DATA } from "data/colleges/vanguard/vanguard"
import { HOLLINS_DATA } from "data/colleges/hollins/hollins"
import { TWO_OCEAN_DATA } from "data/colleges/_two_ocean/two_ocean"

export type College = {
  name: string
  data: ApplicationData
  tealiumProfile: string
  collegeId: string
  currentlyActive?: boolean
}

export const COLLEGES: College[] = [
  {
    name: "siena",
    data: SIENA_DATA,
    tealiumProfile: env.REACT_APP_TEALIUM_SIENA_PROFILE,
    collegeId: env.REACT_APP_SIENA_CID,
    currentlyActive: true,
  },
  {
    name: "creighton",
    data: CREIGHTON_DATA,
    tealiumProfile: env.REACT_APP_TEALIUM_CREIGHTON_PROFILE,
    collegeId: env.REACT_APP_CREIGHTON_CID,
  },
  {
    name: "twoOcean",
    data: TWO_OCEAN_DATA,
    tealiumProfile: env.REACT_APP_TEALIUM_TWO_OCEAN_PROFILE,
    collegeId: env.REACT_APP_TWO_OCEAN_CID,
  },
  {
    name: "vanguard",
    data: VANGUARD_DATA,
    tealiumProfile: env.REACT_APP_TEALIUM_VANGUARD_PROFILE,
    collegeId: env.REACT_APP_VANGUARD_CID,
  },
  {
    name: "hollins",
    data: HOLLINS_DATA,
    tealiumProfile: env.REACT_APP_TEALIUM_HOLLINS_PROFILE,
    collegeId: env.REACT_APP_HOLLINS_CID,
  },
]

export const TEST_DATA = TWO_OCEAN_DATA

//keys to transform plain object to object with nested structures
export const COLLEGE_NESTED_FIELDS = {
  siena: ["parent", "alumni", "institution", "course"],
  vanguard: ["parent", "alumni"],
  creighton: ["parent", "alumni", "sibling", "extracurricular", "institution"],
  hollins: ["parent", "sibling", "alumni"],
}

//keys to transform from nested structures to plain object
export const COLLEGE_NESTED_FIELDS_TO_PLAIN_OBJECT = {
  siena: [
    { key: "parent_existence", dependencyKey: "parents" },
    { key: "alumni_shared", dependencyKey: "alumnis" },
    { key: "institution_attended", dependencyKey: "institutions" },
    { key: "course_taken", dependencyKey: "courses" },
  ],
  vanguard: [
    { key: "parent_existence", dependencyKey: "parents" },
    { key: "alumni_shared", dependencyKey: "alumnis" },
  ],
  creighton: [
    { key: "parent_existence", dependencyKey: "parents" },
    { key: "alumni_shared", dependencyKey: "alumnis" },
    { key: "institution_attended", dependencyKey: "institutions" },
    { key: "sibling_existence", dependencyKey: "siblings" },
    {
      key: "extracurricular_activity_taken",
      dependencyKey: "extracurriculars",
    },
  ],
  hollins: [
    { key: "parent_existence", dependencyKey: "parents" },
    { key: "alumni_shared", dependencyKey: "alumnis" },
    { key: "sibling_existence", dependencyKey: "siblings" },
  ],
}
