import DOMPurify from "dompurify"

/** Array Methods
 ----------------------------------------------------*/
export const alphabetize = (
  options: any[],
  key: string,
  direction?: "asc" | "desc",
) => {
  const compare = (a: any, b: any) => {
    if (direction === "desc") {
      return a[key] < b[key] ? 1 : -1
    } else {
      return a[key] > b[key] ? 1 : -1
    }
  }

  return options.sort(compare)
}

/** Date Functions 
-----------------------------------------------------*/
export function timeAgo(date: Date) {
  const second = { label: "sec", value: 1000 }
  const minute = { label: "min", value: second.value * 60 }
  const hour = { label: "hour", value: minute.value * 60 }
  const day = { label: "day", value: hour.value * 24 }

  const currentDateTime = new Date().getTime()
  const savedDateTime = new Date(date).getTime()

  const dateTimeDifference = currentDateTime - savedDateTime

  const message = ({ label, value }: { label: string; value: number }) => {
    const time = (dateTimeDifference / value).toFixed()
    const unit = label + (parseInt(time) !== 1 ? "s" : "")
    return `${time} ${unit} ago`
  }

  switch (true) {
    case dateTimeDifference > day.value:
      return message(day)
    case dateTimeDifference > hour.value:
      return message(hour)
    case dateTimeDifference > minute.value:
      return message(minute)
    case dateTimeDifference > second.value:
      return message(second)
    default:
      return ""
  }
}

/** String Methods
 ----------------------------------------------------*/
export const dehyphenate = (string: string) => {
  return string.replace(/-/g, "")
}

export const lowercaseFirstLetter = (string: string) => {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

export const splitString = (string: string, separator = " ") =>
  string.toLowerCase().split(separator)

export const titleize = (string: string, separator = " ") => {
  // prettier-ignore
  const lowercaseWords = ["a", "an", "and", "at", "but", "by", "for", "in", "nor", "of", "on", "or", "so", "the", "to", "up", "yet"]

  // Capitalize the first, last, and any word not in array
  const shouldCapitalize = (string: string, index: number) => {
    return index <= 0 || !lowercaseWords.includes(string)
  }

  return splitString(string, separator)
    .map((word, index) =>
      shouldCapitalize(word, index) ? capitalize(word) : word,
    )
    .join(" ")
}

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const toLowercaseString = (value: string | number) => {
  return value.toString().toLowerCase()
}

export function sanitizeHTML(input: string): string {
  return DOMPurify.sanitize(input, {
    ALLOWED_TAGS: ["p", "strong", "em", "u", "a", "ul", "ol", "li", "br"],
    ALLOWED_ATTR: ["href", "class"],
  })
}

export function isValidHTMLString(input: string): boolean {
  if (!input?.trim()) {
    return false
  }

  // Decode the input if it's URI encoded
  const decodedInput = decodeURIComponent(input)

  // Sanitize the input
  const sanitized = sanitizeHTML(decodedInput)

  // Check if the sanitized string contains any HTML-like structure
  const doc = new DOMParser().parseFromString(sanitized, "text/html")
  const body = doc.body

  // Check if there are any elements other than just text nodes
  return (
    body.children.length > 0 ||
    body.innerHTML.trim() !== body.textContent?.trim()
  )
}
