import { OptionData } from "types"

export const VANGUARD_SPORTS: OptionData = [
  "Baseball - Men's",
  "Basketball - Men's",
  "Basketball - Women's",
  "Beach Volleyball - Women's",
  "Cross Country - Men's",
  "Cross Country - Women's",
  "Dance/Sideline Cheer - Women's",
  "Golf - Men's",
  "Golf - Women's",
  "Soccer - Men's",
  "Soccer - Women's",
  "Softball - Women's",
  "Stunt - Women's",
  "Track & Field - Men's",
  "Track & Field - Women's",
  "Volleyball - Men's",
  "Volleyball - Women's",
  "Wrestling - Men's",
  "Wrestling - Women's",
]
