import FormContext from "context/FormContext"
import { useContext, useMemo, useState } from "react"
import { Option, SelectInputProps } from "types"
import { useWatch } from "react-hook-form"
import { SIENA_BUSINESS_CONCENTRATIONS } from "data/colleges/siena/siena_options/siena_business_concentrations"
import { Searchbox } from "components/Searchbox"
import { concentrationsForRemove } from "./concentrationsMap"
import { optionize } from "data/utils"

interface BusinessConcentrationFieldProps {
  inputProps: SelectInputProps
}

/**
 * A custom component for Siena Business major for selecting a secondary concentration.
 * Necessary for now because the data set required to reflect this would be huge.
 *
 * This component will only work with a field named concentration_1 already answered
 */

export const BusinessConcentrationField = ({
  inputProps,
}: BusinessConcentrationFieldProps) => {
  const [concentrationOptions, setConcentrationOptions] = useState<Option[]>([])
  const {
    form: { control },
  } = useContext(FormContext)

  const SIENA_BUSINESS_CONCENTRATION_OPTIONS = optionize(
    SIENA_BUSINESS_CONCENTRATIONS,
  )

  const concentration1: { [key: string]: any } = useWatch({
    control,
    name: "concentration_1",
  })

  const firstConcentrationObject = SIENA_BUSINESS_CONCENTRATION_OPTIONS.find(
    item => item.value === concentration1?.value,
  )

  const itemsForRemove =
    firstConcentrationObject &&
    concentrationsForRemove[firstConcentrationObject.label]
      ? concentrationsForRemove[firstConcentrationObject.label]
      : []

  useMemo(() => {
    const filteredOptions = SIENA_BUSINESS_CONCENTRATION_OPTIONS.filter(
      concentration => {
        return (
          concentration.value !== concentration1?.value &&
          !itemsForRemove.includes(concentration.label)
        )
      },
    )
    setConcentrationOptions(filteredOptions)
    // eslint-disable-next-line
  }, [concentration1])

  return (
    <Searchbox {...inputProps} isAsync={false} options={concentrationOptions} />
  )
}
