import { ApplicationData } from "types"
import {
  VANGUARD_DENOMINATIONS,
  VANGUARD_MAJORS,
  VANGUARD_SPORTS,
} from "./vanguard_options"
import { optionize } from "data/utils"

export const VANGUARD_DATA: ApplicationData = {
  layout: {
    version: "1",
  },
  meta: {
    title: "Vanguard University",
    contact: {
      phone: "1-800-722-6279",
      website: "https://vanguard-university.org/privacypolicy/privacypolicy",
      email: "admissions@vanguard.edu",
    },
    logo: "https://www.vanguard-university.org/VanguardLogo.svg",
    footerLogo: "https://www.vanguard-university.org/VanguardFooterLogo.svg",
    favicon: "https://www.vanguard.edu/uploaded/favicon.ico",
    name: "Vanguard University",
    submissionRedirectUrl:
      "https://vanguard-university.org/application/submitted",
  },
  susiPages: {
    signIn: {
      heading: "Sign In",
      htmlContent:
        '<p>Enter your email address and password below to continue filling out your Your Vanguard Aspire Application. Need to start a brand new application? <a class="userflow">Sign up now.</a></p>',
    },
    signUp: {
      heading: "Your Vanguard Aspire Application is ready for you!",
      subheading:
        "Test scores are not required and you'll <strong>receive your application <span class='break-lg'></span>decision within two weeks</strong>!",
      htmlContent:
        '<div class="grid"><section><h2>Vanguard also offers you these benefits when you apply: </h2><ul><li>No application fee</li><li>Automatic academic scholarship consideration</li><li>Swag gift upon admission</li></ul></section><figure aria-hidden="true" /></div><p>Vanguard University encourages applications from students who desire an education which integrates Christian faith, learning and living; provides intellectual challenge and growth; and encourages spiritual commitment.</p><p>Enter your email address and create a password to begin filling out your <span class="nowrap-lg">Vanguard Aspire Application</span>, or <a class="userflow">continue an application you previously started</a>.</p>',
      formHeading: "Sign up to start a new application",
    },
    forgotPassword: {
      heading: "Passphrase Reset",
    },
  },
  pages: [
    {
      id: "about-you",
      slug: "about-you",
      title: "About You",
      navTitle: "About You",
      description: "We'd like to get to know you better.",
      sections: [
        {
          title: "Your Information",
          description: "",
          fields: [
            {
              name: "first_name",
              type: "text",
              label: "First Name (legal)",
              isRequired: true,
              group: 1,
              order: 1,
            },
            {
              name: "middle_name",
              type: "text",
              label: "Middle Name",
              group: 1,
              order: 2,
            },
            {
              name: "last_name",
              type: "text",
              label: "Last Name",
              isRequired: true,
              group: 1,
              order: 3,
            },
            {
              name: "preferred_name",
              type: "text",
              label: "Preferred First Name",
              helperText:
                "Would you like to share another first name that you go by? We may use it in future communications.",
            },
            {
              name: "date_of_birth",
              type: "date",
              label: "Date of Birth",
              isRequired: true,
            },
            {
              name: "sex",
              type: "radio",
              label: "Legal Gender",
              options: optionize(["Male", "Female"]),
              isRequired: true,
            },
          ],
        },
        {
          title: "Your Contact Information",
          description: "Let us know how best to reach you and where you live.",
          fields: [
            {
              name: "country",
              label: "Country",
              type: "countrySearch",
              isRequired: true,
            },
            {
              name: "phone_number",
              label: "Cell Phone",
              type: "tel",
              show: {
                conditions: [
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
              },
            },
            {
              name: "phone_text_opt-in",
              label: null,
              type: "checkbox",
              options: optionize([
                ["Yes, text me updates about my application.", "Yes"],
              ]),
              show: {
                conditions: [
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
              },
            },
            {
              name: "home_number",
              label: "Home Phone",
              type: "tel",
              show: {
                conditions: [
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
              },
            },
            {
              name: "address1",
              label: "Address",
              type: "text",
              isRequired: true,
              group: 4,
              order: 1,
              width: "60%",
            },
            {
              name: "address2",
              label: "Apartment, suite, etc.",
              type: "text",
              group: 4,
              order: 2,
              width: "40%",
            },
            {
              name: "city",
              label: "City",
              type: "text",
              isRequired: true,
              group: 2,
              order: 1,
            },
            {
              name: "state",
              label: "State",
              type: "stateSearch",
              group: 2,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logicalOr: true,
              },
              isRequired: {
                conditions: [
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "county",
              label: "County",
              type: "countySearch",
              group: 2,
              order: 4,
              show: {
                conditions: [
                  {
                    name: "state",
                    value: "CA",
                  },
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logic: "0&&(1||2)",
              },
              isRequired: {
                conditions: [
                  {
                    name: "state",
                    value: "CA",
                  },
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logic: "0&&(1||2)",
              },
            },
            {
              name: "state_province",
              label: "State/Province",
              type: "text",
              group: 2,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "country",
                    notValue: "",
                  },
                  {
                    name: "country",
                    notValue: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "country",
                    notValue: "",
                  },
                  {
                    name: "country",
                    notValue: "United States",
                  },
                ],
              },
            },
            {
              name: "zip",
              label: "Zip Code",
              type: "zip_code",
              group: 2,
              order: 3,
              validations: {
                length: {
                  is: 5,
                },
              },
              show: {
                conditions: [
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logicalOr: true,
              },
              isRequired: {
                conditions: [
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "postal_code",
              label: "Postal Code",
              group: 2,
              order: 3,
              type: "text",
              validations: {
                value: {
                  min: 2,
                  max: 15,
                },
              },
              show: {
                conditions: [
                  {
                    name: "country",
                    notValue: "",
                  },
                  {
                    name: "country",
                    notValue: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "country",
                    notValue: "",
                  },
                  {
                    name: "country",
                    notValue: "United States",
                  },
                ],
              },
            },
            {
              name: "email_address",
              label: "Email Address",
              type: "email",
              isRequired: true,
              helperText:
                "This is the email address you provided when you started your application so it can't be changed.",
            },
          ],
        },
        {
          title: "Your Denomination",
          description: "",
          fields: [
            {
              name: "religious_preference",
              label: "What do you consider your denomination?",
              type: "select",
              options: optionize(VANGUARD_DENOMINATIONS),
              helperText: "Start typing to find and select your denomination.",
              isRequired: true,
            },
          ],
        },
        {
          title: "Your Background",
          description:
            "This information is voluntary and used for statistical purposes only. It will not be used to determine eligibility for admission.",
          fields: [
            {
              name: "hispanic",
              label: "Do you consider yourself to be Hispanic or Latino?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "race",
              label:
                "Please indicate your ethnicity/race by selecting the appropriate answers.",
              type: "checkbox",
              options: optionize([
                "American Indian or Alaska Native",
                "Asian",
                "Black or African American",
                "Native Hawaiian or Other Pacific Islander",
                "White",
              ]),
            },
          ],
        },
        {
          title: "Citizenship",
          description: "",
          fields: [
            {
              name: "citizenship",
              label: "Your Citizenship",
              helperText:
                "U.S. citizenship is not a requirement for admission. Your response may prompt additional questions you'll need to answer.",
              type: "radio",
              options: optionize([
                "U.S. Citizen",
                ["U.S. Permanent Resident", "Permanent Resident"],
                "Other Citizenship",
              ]),
              isRequired: true,
            },
            {
              name: "resident_card",
              label: null,
              type: "subtext",
              value:
                "By selecting Permanent Resident, I confirm I hold a U.S. Permanent Resident Card.",
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "Permanent Resident",
                  },
                ],
              },
            },
            {
              name: "visa",
              type: "subtext",
              label: null,
              value:
                "By selecting Other Citizenship, I possess or may need a Visa, or am an undocumented immigrant.",
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "Other Citizenship",
                  },
                ],
              },
            },
            {
              name: "citizenship_country",
              label: "Country of Citizenship",
              helperText: "Start typing to find and select a country.",
              type: "countrySearch",
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "Other Citizenship",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "Other Citizenship",
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      id: "student-family",
      slug: "student-family",
      title: "Your Family",
      navTitle: "Family",
      description: "Help us get to know your family.",
      sections: [
        {
          title: "Your Parents & Guardians",
          description: "Tell us about your parents and/or guardians.",
          nestedSectionsMin: 1,
          nestedSectionsMax: 2,
          nestedSectionTitle: "Parent/Guardian {{x}}",
          nestedSectionItemName: "Parent/Guardian",
          controlSectionName: "parents",
          fields: [
            {
              name: "parent_first_name",
              label: "First Name",
              type: "text",
              isRequired: true,
              group: 1,
              order: 1,
            },
            {
              name: "parent_last_name",
              label: "Last Name",
              type: "text",
              isRequired: true,
              group: 1,
              order: 2,
            },
            {
              name: "parent_cell_phone",
              label: "Cell Phone",
              type: "tel",
            },
            {
              name: "parent_email_address",
              label: "Email Address",
              helperText:
                "We'd like to send your parent important updates about your application and about financial aid.",
              type: "email",
            },
            {
              name: "parent_same_address",
              label: "Is their address the same as yours?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
          ],
        },
        {
          title: "Household",
          description: null,
          fields: [
            {
              name: "household_spanish",
              type: "checkbox",
              label: null,
              options: [
                {
                  label:
                    "Check this box if your family's preferred first language is Spanish.",
                  value: "Yes",
                },
              ],
            },
            {
              name: "household_education",
              type: "radio",
              label:
                "What is the highest level of education your family has earned?",
              options: optionize([
                "Some High School",
                "High School",
                "GED",
                "Some College",
                "Associate's Degree",
                "Bachelor's Degree",
                "Master's Degree",
                "Doctorate",
                "Unknown",
              ]),
            },
          ],
        },
        {
          title: "Alumni (Optional)",
          conditionalSection: true,
          description:
            "You can share up to 4 family members who attended or are currently enrolled.",
          fields: [
            {
              name: "alumni_shared",
              label:
                "Has anyone else in your family attended Vanguard University?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              prefill: [
                {
                  value: "No",
                },
              ],
              isRequired: true,
            },
          ],
        },
        {
          nestedSectionsMax: 4,
          nestedSectionTitle: "Alumni {{x}}",
          nestedSectionItemName: "Alumni",
          controlSectionName: "alumnis",
          isOptional: true,
          hide: {
            conditions: [
              {
                name: "alumni_shared",
                value: "No",
              },
            ],
          },
          fields: [
            {
              name: "alumni_relationship",
              label: "Relationship",
              type: "select",
              options: optionize(["Grandparent", "Parent", "Sibling"]),
            },
            {
              name: "alumni_first_name",
              label: "First Name",
              type: "text",
              group: 1,
              order: 1,
            },
            {
              name: "alumni_last_name",
              label: "Last Name",
              type: "text",
              group: 1,
              order: 2,
            },
          ],
        },
      ],
    },
    {
      id: "your-experience",
      slug: "your-experience",
      title: "Your Experience",
      navTitle: "Your Experience",
      description: "We'd like to know about your accomplishments.",
      sections: [
        {
          title: "Most Recent Education",
          description: "Your Current or Most Recent High School",
          fields: [
            {
              name: "base_institution_ceeb",
              label: "Start typing to search for your school",
              type: "highSchoolSearch",
              isRequired: true,
            },
            {
              name: "base_institution_name",
              type: "text",
              label: "School Name",
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
            },
            {
              name: "base_institution_geo",
              type: "radio",
              label:
                "Is this a domestic (US) or international (outside US) high school?",
              options: optionize([
                "Domestic High School",
                "International High School",
              ]),
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
            },
            {
              name: "base_institution_from_date",
              label: "School Start Date",
              type: "month",
              group: 1,
              order: 1,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  isInPast: true,
                },
              },
              isRequired: true,
            },
            {
              name: "base_institution_to_date",
              label: "School Graduation Date",
              type: "month",
              group: 1,
              order: 2,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  maxYearsInFuture: 2,
                },
              },
            },
          ],
        },
        {
          title: "Optional: Your Education History",
          description: "Have you participated in any dual enrollment programs?",
          fields: [
            {
              name: "institution_1_dual_enrollment",
              label: null,
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "institution_1_ceeb",
              label: "Start typing to search for your college",
              type: "collegeSearch",
              isRequired: true,
              show: {
                conditions: [
                  {
                    name: "institution_1_dual_enrollment",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "institution_1_name",
              type: "text",
              label: "School Name",
              show: {
                conditions: [
                  {
                    name: "institution_1_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "institution_1_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
            },
          ],
        },
        {
          title: "Armed Forces (Optional):",
          description: "",
          fields: [
            {
              name: "us_armed_forces",
              label:
                "I am, or am the spouse or dependent of, a Service Member or Veteran.",
              helperText:
                "Service Member includes Active Duty, Reserve or National Guard.",
              type: "radio",
              options: optionize([
                "Service Member/Veteran",
                "Spouse/Dependent of Service Member/Veteran",
                "Neither",
              ]),
            },
          ],
        },
      ],
    },
    {
      id: "additional-information",
      slug: "additional-information",
      title: "Additional Information",
      navTitle: "Additional Information",
      description: "Tell us about your college plans.",
      sections: [
        {
          title: "Application Details",
          description: null,
          fields: [
            {
              name: "student_term",
              label: "Entering Term",
              type: "radio",
              hide: true,
              options: optionize(["Fall 2025"]),
              prefill: [
                {
                  value: "Fall 2025",
                },
              ],
            },
            {
              name: "intended_major",
              label: "Intended Major",
              helperText:
                "Let us know if you have a major in mind. If you don't yet, that's okay. You can select Undeclared.",
              type: "select",
              options: optionize(VANGUARD_MAJORS),
              isRequired: true,
            },
            {
              name: "student_housing",
              label: "Where do you plan to live?",
              helperText:
                "Vanguard requires all single students less than 21 years of age not living at home with a parent and/or legal guardian to live on campus.",
              type: "radio",
              isRequired: true,
              options: optionize(["On campus", "Off campus"]),
            },
          ],
        },
        {
          title: "Financial Aid",
          description:
            "There are many sources of financial assistance to help with the cost of college. It's helpful to know if you plan to apply for financial aid so we can provide you with more information.",
          fields: [
            {
              name: "student_finaid",
              label:
                "Will you apply for financial aid using the Free Application for Federal Student Aid (FAFSA)?",
              helperText:
                "Applying for financial aid by completing the Free Application for Federal Student Aid (FAFSA) is the best way to maximize any financial assistance you're eligible to receive. The FAFSA opens on October 1. Make sure to include the Vanguard school code: 001293.",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
          ],
        },
        {
          title: "Recruited Athlete Information",
          description: null,
          fields: [
            {
              name: "pursing_intercolleagiate",
              label:
                "I am pursuing a position on a competitive intercolleagiate athletic roster.",
              helperText: "Do not mark for club/intramural sport(s) interest.",
              type: "radio",
              isRequired: true,
              options: optionize(["Yes", "No"]),
            },
            {
              name: "recruited_athlete_sport",
              label: "Recruited Athlete Sport",
              type: "select",
              options: optionize(VANGUARD_SPORTS),
              show: {
                conditions: [
                  {
                    name: "pursing_intercolleagiate",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "pursing_intercolleagiate",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "coach_name",
              label: "Vanguard coach name",
              type: "text",
              show: {
                conditions: [
                  {
                    name: "pursing_intercolleagiate",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "pursing_intercolleagiate",
                    value: "Yes",
                  },
                ],
              },
            },
          ],
        },
        {
          title: "Church Background",
          description: null,
          fields: [
            {
              name: "church_name",
              label: "Name of church you regularly attend",
              helperText:
                "If you do not regularly attend a church, type `None.`",
              type: "text",
              isRequired: true,
            },
            {
              name: "church_city",
              label: "Church City",
              type: "text",
            },
            {
              name: "church_state",
              label: "Church State",
              type: "stateSearch",
            },
            {
              name: "church_denomination",
              label: "Church Denomination",
              type: "select",
              options: optionize(VANGUARD_DENOMINATIONS),
            },
            {
              name: "pastor_name",
              label: "Pastor/youth leader/spiritual mentor name",
              type: "text",
            },
            {
              name: "pastor_email",
              label: "Pastor Email",
              type: "email",
            },
            {
              name: "pastor_phone",
              label: "Pastor Phone",
              type: "tel",
            },
            {
              name: "jesus",
              label: "Have you accepted Jesus Christ as your personal savior?",
              type: "radio",
              isRequired: true,
              options: optionize(["Yes", "No"]),
            },
            {
              name: "jesus_explain",
              label: "Please explain.",
              type: "textarea",
              show: {
                conditions: [
                  {
                    name: "jesus",
                    value: "No",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "jesus",
                    value: "No",
                  },
                ],
              },
            },
          ],
        },
        {
          title: "Final Steps",
          description: null,
          fields: [
            {
              name: "service_dependent",
              label:
                "I am, or am the dependent of, a licensed/ordained minister or missionary in full-time Christian service.",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "staff_dependent",
              label:
                "I am, or am the dependent of, a current or vested VU faculty/staff member.",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
          ],
        },
        {
          title: "Spiritual and Social Expectations",
          description:
            '<p>Vanguard University is a Christian academic community that encourages individual integrity and responsibility in accordance with biblical Christianity and its social and ethical implications. Undergraduate students commit themselves to basic responsibilities. Though your perspectives might differ, you will be expected during your time as a student at Vanguard to respect and comply with these community responsibilities, which include, but are not limited to the following:</p><ol><li>Mandatory chapel attendance</li><li>Completion of 15 Bible units</li><li>Non-participation in practices known to be morally wrong by biblical standards, such as drunkenness, stealing, causing dissension among community members, slanderous or profane language, dishonesty, occult practices and/or inappropriate sexual behavior which includes, but is not limited to, adultery, same-sex romantic intimacy or sexual behavior, pornography usage and/or unmarried sexual behavior.</li><li>Abstention from the possession or use of alcoholic beverages, tobacco products, non-medical narcotics, or hallucinogenic drugs (including marijuana)</li></ol><p>For more information, read the online <a href="https://www.vanguard.edu/student-life" target="_blank">Student Handbook</a> and specifically the Community Living Values, General Expectations of the Vanguard Community, and Community Standards (Student Code of Conduct) sections. If I enroll, I understand that my image may be used in University marketing materials including, but not limited to, print and/or digital media.</p>',
          fields: [
            {
              name: "agree_spiritual",
              label: null,
              type: "checkbox",
              options: optionize([
                [
                  "I certify I have read, understand and agree to the Spiritual and Social Expectations statement above.",
                  "Yes",
                ],
              ]),
              isRequired: true,
            },
          ],
        },
        {
          title: "Religious Expression Disclosure",
          description:
            "Vanguard University (VU) is a private, coeducational, comprehensive university of liberal arts and professional studies that believes its Spirit-empowered Christian community provides a supportive and challenging environment in which to pursue a quality education. The University assumes that it is essential to offer educational opportunity within a context of free inquiry and academic integrity. VU is a community that encourages individual integrity and responsibility in accordance with biblical Christianity and its social and ethical implications. VU is committed to providing a learning and living environment that promotes safety, transparency, personal integrity, civility, mutual respect and freedom from discrimination. As a Christian university affiliated with the Southern California Network of the Assemblies of God, VU is exempted from certain provisions of federal Title IX of the Education Amendments of 1972 and the California Equity in Higher Education Act that are not consistent with its religious tenets and mission. These exemptions may apply to, but are not limited to, requirements in the university's community standards, housing policies, admissions processes, and employee hiring and general employment practices. VU retains all rights afforded under federal law and the laws of the State of California.",
          fields: [
            {
              name: "agree_religious_expression",
              label: null,
              type: "checkbox",
              options: optionize([
                [
                  "I certify I have read, understand and agree to the Religious Expression Disclosure statement above.",
                  "Yes",
                ],
              ]),
              isRequired: true,
            },
          ],
        },
        {
          title: "Certification",
          description:
            "Also, I certify that all information submitted in the admission process – including the application and supporting documentation – is my own work, factually true, and honestly presented, and that these documents will become the property of Vanguard University and will not be returned to nor duplicated for me. I understand that acceptance is subject to verification of final records from all institutions I have attended and that I may be subject to admission revocation should the information I have certified be false.",
          fields: [
            {
              name: "agree_certification",
              label: null,
              type: "checkbox",
              options: optionize([
                [
                  "I certify I have read, understand and agree to the Certification statement above.",
                  "Yes",
                ],
              ]),
              isRequired: true,
            },
          ],
        },
      ],
    },
  ],
}
