import { useEffect } from "react"

/**
 * This useEffect appends a script tag to the bottom of the <body>
 * that will update the URLs on the Sign Up / Sign In pages, which
 * include only vanilla JS, HTML and CSS.
 *
 * In order to correctly link a user between Sign Up, Sign In
 * and Forgot Password pages, we need to hijack the links that
 * Azure ADB2C provides and change the href and keep the token.
 */

interface UseSignUpScriptProps {
  customEmailAddressLabel?: string
  customPasswordLabel?: string
  customConfirmPasswordLabel?: string
  customCTAButtonText?: string
}

export const useSignUpScript = (props: UseSignUpScriptProps = {}) => {
  useEffect(() => {
    const {
      customEmailAddressLabel = "Email Address",
      customPasswordLabel = "Create Password",
      customConfirmPasswordLabel = "Confirm Password",
      customCTAButtonText = "Start my application",
    } = props
  
    /** These are created by Azure ADB2C */
    const selectors = {
      ctaButton: "button#continue",
      emailLabel: "#email_label",
      passwordLabel: "#newPassword_label",
      confirmPasswordLabel: "#reenterPassword_label",
      allLabels: "#attributeList label",
    }

    const script = document.createElement("script")
    script.type = "text/javascript"
    script.text = `    
    function generateLink(userflow) {
      // https://[hostname]/[pathname]?p=B2C_1a_signup&[query_params]
        const url = new URL(window.location.href);

      // replace the "p=" value of the URL with "userflow" arg
        url.searchParams.set("p", "b2c_1a_" + userflow);
        return url.toString()
    }
    
    // Set the correct input labels and placeholders
    function customizeLabels(selector, text) {
      const label = document.querySelector(selector);
      if (label) {
        label.textContent = text;
        const parentDiv = label.closest('li');
        if (parentDiv) {
          const input = parentDiv.querySelector('input');
          if (input) {
            input.placeholder = text;
          }
        }
      }
    }

    // Move the ADB2C error below the input field
    function swapErrorAndInput() {
      const attrEntries = document.querySelectorAll('.attrEntry');
      attrEntries.forEach(entry => {
        const errorDiv = entry.querySelector('.error.itemLevel');
        if (errorDiv) {
          // Remove the error div from its current position
          errorDiv.remove();
          // Append the error div to the end of the attrEntry
          entry.appendChild(errorDiv);
        }
      });
    }

    // Add "*" to required fields
    function addRequiredIndicators() {
      const labels = document.querySelectorAll("${selectors.allLabels}");
      labels.forEach(label => {
        if (!label.querySelector('.required-star')) {
          const star = document.createElement('span');
          star.className = 'required-star';
          star.textContent = '*';
          label.appendChild(star);
        }
      })
    }
    
    function updateDOM() {
      // Replace the signin links
        const signinLinks = Array.from(document.querySelectorAll(".userflow"))
        signinLinks.forEach(link => {
          link.setAttribute("href", generateLink("signin"))
        })
        
      // Custom text of CTA button
        const ctaButton = document.querySelector("${selectors.ctaButton}");
        if(ctaButton) {
          ctaButton.innerHTML = "${customCTAButtonText}"
        }

        customizeLabels("${selectors.emailLabel}", "${customEmailAddressLabel}");
        customizeLabels("${selectors.passwordLabel}", "${customPasswordLabel}");
        customizeLabels("${selectors.confirmPasswordLabel}", "${customConfirmPasswordLabel}");

        addRequiredIndicators();
        swapErrorAndInput();
    }

    function checkAndUpdate(attempts = 0, maxAttempts = 50) {
      if (document.querySelector("#attributeList")) {
        updateDOM();
      } else if (attempts < maxAttempts) {
        setTimeout(() => checkAndUpdate(attempts + 1, maxAttempts), 100);
      } else {
        console.warn("Document not found. Exiting.");
      }
    }

    // Run on load
    window.addEventListener('load', checkAndUpdate);

    // Also run immediately in case the page is already loaded
    checkAndUpdate();
    `

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [props])
}