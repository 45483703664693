import { OptionData } from "types"

export const IB_SUBJECTS: OptionData = [
  "Afrikaans A",
  "Afrikaans A: Literature",
  "Akan A",
  "Akan A: Literature",
  "Albanian A",
  "Albanian A: Literature",
  "Amharic A",
  "Amharic A: Literature",
  "Arabic A",
  "Arabic A: Language and Literature",
  "Arabic A: Literature",
  "Arabic AB",
  "Arabic B",
  "Armenian A",
  "Armenian A: Literature",
  "Art History",
  "Assyrian A",
  "Assyrian A: Literature",
  "Astronomy",
  "Azebaijani A",
  "Azerbaijani A: Literature",
  "Beginning Hindi",
  "Beginning Siswati",
  "Belarusian A: Literature",
  "Bemba A",
  "Bemba A: Literature",
  "Biology",
  "Bosnian A",
  "Bosnian A: Literature",
  "Brazilian Social Studies",
  "Bulgarian A",
  "Bulgarian A: Literature",
  "Burmese A",
  "Burmese A: Literature",
  "Business Management",
  "Catalan A",
  "Catalan A: Literature",
  "Chemistry",
  "Chichewa A",
  "Chichewa A: Literature",
  "Chile &amp; Pac.",
  "Chinese A",
  "Chinese A: Language and Literature",
  "Chinese A: Literature",
  "Chinese B",
  "Chinese B - Cantonese",
  "Chinese B - Mandarin",
  "Classical Greek",
  "Classical Greek and Roman Studies",
  "Computer Science",
  "Croatian A",
  "Croatian A: Literature",
  "Czech A",
  "Czech A: Literature",
  "Dance",
  "Danish A",
  "Danish A: Literature",
  "Danish AB",
  "Danish B",
  "Design Tech.",
  "Dhivehi A",
  "Dhivehi A: Literature",
  "Dutch A",
  "Dutch A: Language and Literature",
  "Dutch A: Literature",
  "Dutch AB",
  "Dutch B",
  "Dzongkha A",
  "Dzongkha A: Literature",
  "Economics",
  "Efik A",
  "English A",
  "English A: Language and Literature",
  "English A: Literature",
  "English AB",
  "English B",
  "Environmental Systems and Societies",
  "Estonian A",
  "Estonian A: Literature",
  "Faroese A",
  "Faroese A: Literature",
  "Fijian A",
  "Filipino A",
  "Filipino A: Literature",
  "Film",
  "Finnish A",
  "Finnish A: Literature",
  "Finnish B",
  "Food and Science and Technology",
  "French A",
  "French A: Language and Literature",
  "French A: Literature",
  "French AB",
  "French B",
  "Further Mathematics",
  "Gaelic A: Literature",
  "Galician A",
  "Geography",
  "Georgian A",
  "Georgian A: Literature",
  "German A",
  "German A: Language and Literature",
  "German A: Literature",
  "German AB",
  "German B",
  "Global Politics",
  "Greenlandic A",
  "Greenlandic A: Literature",
  "Gujarati A",
  "Hebrew A",
  "Hebrew A: Literature",
  "Hebrew B",
  "Hindi A",
  "Hindi A: Literature",
  "Hindi B",
  "History",
  "History of Africa",
  "History of Asia and Oceana",
  "History of Europe and the Middle East",
  "History of the Americas",
  "Human Rights",
  "Hungarian A",
  "Hungarian A: Literature",
  "Icelandic A",
  "Icelandic A: Literature",
  "Igbo A",
  "Igbo A: Literature",
  "Indonesian A",
  "Indonesian A: Language And Literature",
  "Indonesian A: Literature",
  "Indonesian AB",
  "Indonesian B",
  "Information Technology in a Global Society",
  "Irish A",
  "Irish A: Literature",
  "Italian A",
  "Italian A: Language and Literature",
  "Italian A: Literature",
  "Italian AB",
  "Italian B",
  "Japanese A",
  "Japanese A: Language and Literature",
  "Japanese A: Literature",
  "Japanese AB",
  "Japanese B",
  "Kannada A",
  "Kazakh A",
  "Kazakh A: Literature",
  "Khmer A",
  "Khmer A: Literature",
  "Kinyarwanda A",
  "Kinyarwanda A: Literature",
  "Korean A",
  "Korean A: Language and Literature",
  "Korean A: Literature",
  "Korean B",
  "Kurdish A",
  "Kurdish A: Literature",
  "Kyrgyz A",
  "Lao A",
  "Lao A: Literature",
  "Latin",
  "Latvian A",
  "Latvian A: Literature",
  "Literature and Performance",
  "Lithuanian A",
  "Lithuanian A: Literature",
  "Lozi A",
  "Luganda A",
  "Luganda A: Literature",
  "Lunda A",
  "Macedonian A",
  "Macedonian A: Literature",
  "Malagasy A",
  "Malagasy A: Literature",
  "Malay A",
  "Malay A: Literature",
  "Malay AB",
  "Malayalam A",
  "Mandarin AB",
  "Maori A",
  "Maori A: Literature",
  "Marathi A",
  "Marathi A: Literature",
  "Marine Science",
  "Mathematical Studies",
  "Mathematics",
  "Mathematics Analysis and Approaches",
  "Mathematics Applications and Interpretations",
  "Mathematics Calculus",
  "Mathematics Discrete",
  "Mathematics Sets",
  "Mathematics Statistics",
  "Modern Greek A",
  "Modern Greek A: Language and Literature",
  "Modern Greek A: Literature",
  "Modern History of Kazakhstan",
  "Mongolian A",
  "Mongolian A",
  "Music",
  "Music Creating",
  "Music Group Performance",
  "Music Solo Performance",
  "Nature of Science",
  "Ndebele A",
  "Ndebele A: Literature",
  "Nepali A",
  "Nepali A: Literature",
  "Norwegian A",
  "Norwegian A: Language and Literature",
  "Norwegian A: Literature",
  "Norwegian B",
  "Oromo A: Literature",
  "Oshindonga A: Literature",
  "Pashto A",
  "Pashto A: Literature",
  "Peace and Conflict Studies",
  "Persian A",
  "Persian A: Literature",
  "Philosophy",
  "Physics",
  "Polish A",
  "Polish A: Literature",
  "Political Thought",
  "Politics",
  "Portuguese A",
  "Portuguese A: Language and Literature",
  "Portuguese A: Literature",
  "Portuguese B",
  "Psychology",
  "Punjabi A",
  "Punjabi A: Literature",
  "Romanian A",
  "Romanian A: Literature",
  "Romansch A",
  "Russian A",
  "Russian A: Language and Literature",
  "Russian A: Literature",
  "Russian AB",
  "Russian B",
  "Samoan A",
  "Science, Technology and Society",
  "Serbian A",
  "Serbian A: Literature",
  "Sesotho A",
  "Sesotho A: Literature",
  "Setswana A",
  "Shona A",
  "Shona A: Literature",
  "Sinhalese A",
  "Sinhalese A: Literature",
  "Siswati A",
  "Siswati A: Literature",
  "Slovak A",
  "Slovak A: Literature",
  "Slovene A",
  "Slovene A: Literature",
  "Social and Cultural Anthropology",
  "Somali A",
  "Somali A: Literature",
  "Spanish A",
  "Spanish A: Language and Literature",
  "Spanish A: Literature",
  "Spanish AB",
  "Spanish B",
  "Sports Exercise and Health Science",
  "Swahili A",
  "Swahili A: Literature",
  "Swahili AB",
  "Swahili B",
  "Swedish A",
  "Swedish A: Language and Literature",
  "Swedish A: Literature",
  "Swedish AB",
  "Swedish B",
  "Tajik A",
  "Tajik A: Literature",
  "Tamil A",
  "Tamil A: Literature",
  "Tamil B",
  "Telugu A",
  "Telugu A: Literature",
  "Thai A",
  "Thai A: Language and Literature",
  "Thai A: Literature",
  "Theatre",
  "Theatre (Pilot)",
  "Tibetan A",
  "Tibetan A: Literature",
  "Tigrinya A",
  "Tigrinya A: Literature",
  "Tonga A",
  "Turkey In The 20th Century",
  "Turkish A",
  "Turkish A: Literature",
  "Ukrainian A",
  "Ukrainian A: Literature",
  "Urdu A",
  "Urdu A: Literature",
  "Uzbek A",
  "Vietnamese A",
  "Vietnamese A: Literature",
  "Visual Arts",
  "Welsh A",
  "Welsh A: Literature",
  "World Arts and Cultures",
  "World Politics",
  "World Religions",
  "Xhosa A",
  "Yoruba A",
  "Zulu A",
  "Zulu A: Literature",
]
