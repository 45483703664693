import { useContext, useEffect, useMemo, useState } from "react"
import { Box, Flex, Stack, useStyleConfig } from "@chakra-ui/react"
import FormContext from "context/FormContext"
import {
  getAllFieldsOnCard,
  getInvalidFields,
  getInvalidNestedFields,
  getNestedSectionCount,
  getRelevantSectionErrors,
  isNestedSection,
  transformDataToServerFormat,
} from "utilities/helpers"
import {
  AccordionCard,
  AccordionCardProps,
  AccordionCardBody,
  AccordionCardHeader,
  H2,
  Span,
} from "components/common"
import { StatusBadge } from "components/StatusBadge"
import { FormFieldsDisplay } from "components/FormFieldsDisplay"
import { useWatch } from "react-hook-form"
import { SectionData } from "types"
import { SectionDescription } from "./SectionDescription"
import { COLLEGE_NESTED_FIELDS_TO_PLAIN_OBJECT } from "data"

interface FinalCheckCardProps extends AccordionCardProps {
  sectionData: SectionData
  pageTitle: string
  pageNumber: number
  pageSlug: string
  sectionErrors: string[]
  updateSectionErrors: (sectionTitle: string, sectionErrors: string[]) => void
  client: string
}

export const FinalCheckCard = ({
  pageTitle,
  pageNumber,
  pageSlug,
  sectionData,
  sectionErrors,
  updateSectionErrors,
  client,
  ...rest
}: FinalCheckCardProps) => {
  const {
    form: { control, formState, getValues },
  } = useContext(FormContext)
  const cardStyles = useStyleConfig("FinalCheckCard")
  const cardHeaderStyles = useStyleConfig("FinalCheckCardHeader")
  const sectionFieldNames = useMemo(() => {
    return getAllFieldsOnCard(sectionData)
  }, [sectionData])
  const [isOpen, setIsOpen] = useState<boolean>(false)

  // Watch for changes to any field in the section

  const sectionFormValues = useWatch({
    control,
    name: sectionFieldNames,
  })

  useEffect(() => {
    const relevantErrors = getRelevantSectionErrors(
      sectionData,
      formState.errors
    )

    const arrayToCheck = COLLEGE_NESTED_FIELDS_TO_PLAIN_OBJECT[client]
    const formValues = getValues()

    const allValues = transformDataToServerFormat(formValues, arrayToCheck)

    const sectionFields: {
      [x: string]: any
    } = {}

    Object.keys(allValues).forEach(value => {
      if (sectionFieldNames.includes(value)) {
        sectionFields[value] = allValues[value]
      }
    })

    let fieldErrors: string[] = []
    if (isNestedSection(sectionData)) {
      // Generate field errors for each nested section that has any answers in it

      fieldErrors = getInvalidNestedFields(
        sectionData.fields,
        getNestedSectionCount(sectionData, getValues()),
        sectionData.nestedSectionsMin,
        allValues,
        relevantErrors,
        sectionData.controlSectionName
      )
    } else {
      fieldErrors = getInvalidFields(
        sectionData.fields,
        allValues,
        relevantErrors
      )
    }

    updateSectionErrors(sectionData.title, fieldErrors)
    // eslint-disable-next-line
  }, [sectionData, formState, sectionFormValues])

  const toggleIsOpen = () => {
    setIsOpen(prevState => !prevState)
  }

  const openCard = () => {
    setIsOpen(true)
  }

  return (
    <Box as={AccordionCard} __css={cardStyles} onClick={openCard} {...rest}>
      <Box
        as={AccordionCardHeader}
        __css={cardHeaderStyles}
        onClick={toggleIsOpen}
        {...rest}
      >
        <Stack spacing={1} width="full">
          <Flex justifyContent="space-between" alignItems="center">
            <Span className="card-header-eyebrow">
              {pageNumber + 1}. {pageTitle}
            </Span>
            <StatusBadge
              status={sectionErrors.length > 0 ? "ERROR" : "SUCCESS"}
              errorCount={sectionErrors.length}
              marginRight={{ base: -2, md: 0 }}
              className={
                sectionErrors.length > 0 ? "error-badge" : "success-badge"
              }
            />
          </Flex>
          <H2>
            {sectionData.title ||
              sectionData.nestedSectionTitle?.replace("{{x}}", "")}
          </H2>
        </Stack>
      </Box>

      {isOpen && (
        <AccordionCardBody paddingX={{ base: 4, md: 6 }} paddingBottom={8}>
          <SectionDescription
            description={sectionData.description}
            marginBottom={8}
          />
          <FormFieldsDisplay
            sectionData={sectionData}
            isRequiredErrors={true}
            isFinalCheck={true}
          />
        </AccordionCardBody>
      )}
    </Box>
  )
}
