declare global {
    interface Window {
      env: any
    }
  }
  
  // change with your own variables
  type EnvType = {
    REACT_APP_ADB2C_REDIRECT_URL: string,
    REACT_APP_ADB2C_URL: string,
    REACT_APP_API_BASE: string,
    REACT_APP_TEALIUM_TARGET_ENV: string,
    REACT_APP_ENVIRONMENT: string,
    NODE_ENV: string,
    PUBLIC_URL: string
    REACT_APP_TEALIUM_SIENA_PROFILE: string,
    REACT_APP_SIENA_CID: string,
    REACT_APP_TEALIUM_CREIGHTON_PROFILE: string,
    REACT_APP_CREIGHTON_CID: string,
    REACT_APP_TEALIUM_TWO_OCEAN_PROFILE: string,
    REACT_APP_TWO_OCEAN_CID: string,
    REACT_APP_TEALIUM_VANGUARD_PROFILE: string,
    REACT_APP_VANGUARD_CID: string,
    REACT_APP_TEALIUM_HOLLINS_PROFILE: string,
    REACT_APP_HOLLINS_CID: string

  }
  export const env: EnvType = { ...process.env, ...window.env }