import { useEffect } from "react"

/**
 * This useEffect appends a script tag to the bottom of the <body>
 * that will update the URLs on the Sign Up / Sign In pages, which
 * include only vanilla JS, HTML and CSS.
 *
 * In order to correctly link a user between Sign Up, Sign In
 * and Forgot Password pages, we need to hijack the links that
 * Azure ADB2C provides and change the href and keep the token.
 */

interface UseSignInScriptProps {
  customEmailAddressLabel?: string
  customPasswordLabel?: string
  customCTAButtonText?: string
}

export const useSignInScript = (props: UseSignInScriptProps = {}) => {
  useEffect(() => {
    const {
      customEmailAddressLabel = "Email Address",
      customPasswordLabel = "Password",
      customCTAButtonText = "Continue my application",
    } = props
    
    /** These are created by Azure ADB2C */
    const selectors = {
      ctaButton: "button#next",
      emailLabel: "label[for='signInName']",
      emailInput: "input#signInName",
      passwordLabel: "label[for='password']",
      passwordInput: "input#password",
      allLabels: ".entry-item label",
    }

    const script = document.createElement("script")
    script.type = "text/javascript"
    script.text = `    
    function generateLink(userflow) {
      // https://[hostname]/[pathname]?p=B2C_1a_signin&[query_params]
        const url = new URL(window.location.href);

      // replace the "p=" value of the URL with "userflow" arg
        url.searchParams.set("p", "b2c_1a_" + userflow);
        return url.toString()
    }

    // Move the ADB2C error below the input field
    function swapErrorAndInput() {
      const entryItems = document.querySelectorAll('.entry-item');
      entryItems.forEach(item => {
        const errorDiv = item.querySelector('.error.itemLevel');
        const input = item.querySelector('input');
        if (errorDiv && input && errorDiv.nextElementSibling === input) {
          item.insertBefore(input, errorDiv);
        }
      });
    }

    // Add "*" to required fields
    function addRequiredIndicators() {
      const labels = document.querySelectorAll("${selectors.allLabels}");
      labels.forEach(label => {
        if (!label.querySelector('.required-star')) {
          const star = document.createElement('span');
          star.className = 'required-star';
          star.textContent = '*';
          label.appendChild(star);
        }
      })
    }

    function updateDOM() {
      // Replace the signup link
      const signUpLink = document.querySelector(".userflow")
      signUpLink.setAttribute("href", generateLink("signup"))

      // Replace the forgotpassword link
      const forgotPasswordLink = document.getElementById("forgotPassword")
      forgotPasswordLink.setAttribute("href", generateLink("passwordreset"))
      forgotPasswordLink.onclick = function(e) {
        e.preventDefault();
        window.location = generateLink("passwordreset");
      };

      // Show password reset confirmation if user is coming from that page
      const passwordResetConfirmationText = document.getElementById("passwordResetConfirmation")
      if (window.location.href.indexOf("&passwordreset") > 0) {
        passwordResetConfirmationText.style.display = "block"
      }

      // Update email label and placeholder
      const emailLabel = document.querySelector("${selectors.emailLabel}");
      if (emailLabel) {
        emailLabel.textContent = "${customEmailAddressLabel}";
      }

      const emailInput = document.querySelector("${selectors.emailInput}");
      if (emailInput) {
        emailInput.placeholder = "${customEmailAddressLabel}";
        emailInput.setAttribute('aria-label', "${customEmailAddressLabel}");
      }

      const passwordLabel = document.querySelector("${selectors.passwordLabel}");
      if (passwordLabel) {
        passwordLabel.textContent = "${customPasswordLabel}";
      }

      const passwordInput = document.querySelector("${selectors.passwordInput}");
      if (passwordInput) {
        passwordInput.placeholder = "${customPasswordLabel}";
        passwordInput.setAttribute('aria-label', "${customPasswordLabel}");
      }

      // Update button text
      const ctaButton = document.querySelector("${selectors.ctaButton}");
      if (ctaButton) {
        ctaButton.textContent = "${customCTAButtonText}";
      }

      addRequiredIndicators();
      swapErrorAndInput();
    }

    function checkAndUpdate(attempts = 0, maxAttempts = 50) {
      if (document.querySelector('.entry')) {
        updateDOM();
      } else if (attempts < maxAttempts) {
        setTimeout(() => checkAndUpdate(attempts + 1, maxAttempts), 100);
      } else {
        console.warn("Document not found. Exiting.");
      }
    }

    // Run on load
    window.addEventListener('load', checkAndUpdate);

    // Also run immediately in case the page is already loaded
    checkAndUpdate();
    `

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [props])
}
