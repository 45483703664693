/**
 * Commented lines are Chakra UI default tokens.
 * Uncomment to overwrite, or leave and use as they are. e.g:
 *  <Text letterSpacing="wide" lineHeight="tall" fontWeight="hairline" fontSize="lg">
 */

export const typography = {
  letterSpacings: {
    // tighter: "-0.05em",
    // tight: "-0.025em",
    // normal: "0",
    // wide: "0.025em",
    // wider: "0.05em",
    // widest: "0.1em",
  },
  lineHeights: {
    // normal: "normal",
    // none: 1,
    alertTitleMobile: 1.2,
    alertTitleDesktop: 1.23,
    // shorter: 1.25,
    // short: 1.375,
    base: 1.75,
    button: 1.43,
    h1: 1.57,
    h2: 1.62,
    h3: 1.7,
    // tall: 1.625,
    // taller: "2",
    // "3": ".75rem",
    // "4": "1rem",
    // "5": "1.25rem",
    // "6": "1.5rem",
    // "7": "1.75rem",
    // "8": "2rem",
    // "9": "2.25rem",
    // "10": "2.5rem",
  },
  fontWeights: {
    // hairline: 100,
    // thin: 200,
    // light: 300,
    // normal: 400,
    // medium: 500,
    // semibold: 600,
    // bold: 700,
    // extrabold: 800,
    // black: 900,
  },
  fonts: {
    heading: "'Montserrat', sans-serif",
    body: "'Montserrat', sans-serif",
  },

  fontSizes: {
    xxs: "0.625rem",
    // xs: "0.75rem",
    "13px": "0.8125rem",
    // sm: "0.875rem",
    // helperTextMobile: "0.875rem",
    // helperTextDesktop: "0.875rem",
    // md: "1rem",
    bodyMobile: "1rem",
    bodyDesktop: "1rem",
    // lg: "1.125rem",
    // xl: "1.25rem",
    h3Mobile: "1.09rem",
    h3Desktop: "1.25rem",
    // "2xl": "1.5rem",
    h2Mobile: "1.42rem",
    h2Desktop: "1.625rem",
    // "3xl": "1.875rem",
    h1Mobile: "1.805rem",
    h1Desktop: "2.0625rem",
    alertTitleMobile: "1.25rem",
    alertTitleSm: "1.375rem",
    alertTitleDesktop: "1.625rem",
    // "4xl": "2.25rem",
    // "5xl": "3rem",
    // "6xl": "3.75rem",
    // "7xl": "4.5rem",
    // "8xl": "6rem",
    // "9xl": "8rem",
  },
}
