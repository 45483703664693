import { log, SHOW_LOGS } from "utilities/logging"

interface TealiumData {
  tealium_event: string
  page?: string
  email_address?: string
  customer_id?: string
}

// For tracking page changes (e.g. from a link, refresh, etc.)
export function TealiumViewTrack(tealiumData: TealiumData) {
  log(SHOW_LOGS.TEALIUM, tealiumData)
  ;(window as any).utag?.view({
    ...tealiumData,
  })
}

// For tracking events (on submit, on click, on validation, etc)
export function TealiumLinkTrack(tealiumData: TealiumData) {
  log(SHOW_LOGS.TEALIUM, tealiumData)
  ;(window as any).utag?.link({
    ...tealiumData,
  })
}
