import { createOptionDataFromItems } from "data/utils"
import { ComplexOptionItem } from "types"

const sienaBusinessConcentrations: ComplexOptionItem[] = [
  { code: "ATAT", label: "Accounting Theory And Foundations" },
  { code: "BRMG", label: "Brand Management" },
  { code: "BABA", label: "Business Analytics" },
  { code: "CFCF", label: "Corporate Finance" },
  { code: "ETPR", label: "Economic Theory And Practice" },
  { code: "ENEN", label: "Entrepreneurship" },
  { code: "FPFP", label: "Financial Planning" },
  { code: "FFFF", label: "Fundamentals of Finance" },
  { code: "IBIB", label: "International Business" },
  { code: "IFIF", label: "Investment Fundamentals" },
  { code: "LDMG", label: "Leadership" },
  { code: "MCMC", label: "Marketing Communications And Digital Marketing" },
  { code: "MRMR", label: "Marketing Research And Analytics" },
  { code: "NPGA", label: "Not-For-Profit Governmental Accounting" },
  { code: "PRMG", label: "Project Management" },
  { code: "PPEC", label: "Public Policy And Economics" },
  { code: "BBBB", label: "Sales And Business To Business Marketing" },
  { code: "SPMK", label: "Sports Marketing" },
  { code: "STDM", label: "Strategic Decision Making" },
  { code: "SHRD", label: "Strategic Human Resource Development" },
]

export const SIENA_BUSINESS_CONCENTRATIONS = createOptionDataFromItems(
  sienaBusinessConcentrations,
)
