import { OptionData } from "types"

export const VANGUARD_DENOMINATIONS: OptionData = [
  "Assemblies of God",
  "Baptist",
  "Bible Church",
  "Calvary Chapel",
  "Catholic",
  "Charismatic",
  "Christian",
  "Church of Christ",
  "Church of God",
  "Covenant",
  "Episcopal",
  "Evangelical",
  "Evangelical Free",
  "Four Square",
  "Friends",
  "Lutheran",
  "Methodist",
  "Nazarene",
  "Non-Denominational",
  "Pentecostal",
  "Presbyterian",
  "Vineyard",
  "None",
  "Other",
]
