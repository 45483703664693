import { OptionData } from "types"

export const SPORTS: OptionData = [
  "Archery",
  "Badminton",
  "Baseball",
  "Basketball",
  "Bowling",
  "Boxing",
  "Cheerleading",
  "Cricket",
  "Cross-country",
  "Diving",
  "Equestrian",
  "Fencing",
  "Field Hockey",
  "Figure Skating",
  "Football",
  "Football (non-tackle)",
  "Golf",
  "Gymnastics",
  "Handball",
  "Ice Hockey",
  "Indoor Track",
  "Judo",
  "Lacrosse",
  "Other Sport",
  "Outdoor Track",
  "Racquetball",
  "Rifle",
  "Rock climbing, Bouldering",
  "Rodeo",
  "Rowing (Crew)",
  "Rugby",
  "Sailing",
  "Skiing",
  "Skin Diving",
  "Soccer",
  "Softball",
  "Squash",
  "Swim",
  "Sync. Swim",
  "Table Tennis",
  "Tennis",
  "Track and Field",
  "Triathalon",
  "Ultimate Frisbee",
  "Volleyball",
  "Water Polo",
  " Weight Lifting",
  "Wrestling",
]
