import { env } from "env"
import { useContext, useEffect } from "react"
import { UserContext } from "context/UserContext"
import { API_ROOT_HEADER, CID_HEADER, IS_PRODUCTION } from "utilities/constants"
import { getHeaderFromHeaders } from "utilities/helpers"
import { log, SHOW_LOGS } from "utilities/logging"
import ApiRoot from "api/apiRoot"

interface CIDWrapperProps {
  children: React.ReactNode
  localCID: string
}

const CIDWrapper = ({ children, localCID }: CIDWrapperProps) => {
  const { cid, setCid } = useContext(UserContext)

  useEffect(
    function setCid_and_instantiateApi() {
      log(
        SHOW_LOGS.ENVIRONMENT,
        "ENVIRONMENT:",
        process.env.REACT_APP_ENVIRONMENT,
      )

      if (IS_PRODUCTION) {
        log(SHOW_LOGS.CLIENT_ID, "Requesting CDN headers")
        let req = new XMLHttpRequest()
        req.open("GET", window.location.href, false)
        req.send(null)

        let headers = req.getAllResponseHeaders().toLowerCase()
        if (headers) {
          const cid_header = getHeaderFromHeaders(headers, CID_HEADER)
          const api_root = getHeaderFromHeaders(headers, API_ROOT_HEADER)
          log(SHOW_LOGS.CLIENT_ID, "Using CID:", cid_header)
          log(SHOW_LOGS.API_ROUTE, "Using API_ROOT:", api_root)
          setCid(cid_header)
          ApiRoot.connect(api_root)
        }
      } else {
        // NOT_PRODUCTION
        log(SHOW_LOGS.CLIENT_ID, "Using Local CID:", localCID)
        log(
          SHOW_LOGS.API_ROUTE,
          "Using BASE_API:",
          process.env.REACT_APP_API_BASE,
        )
        ApiRoot.connect(env.REACT_APP_API_BASE)
        setCid(localCID)
      }
    },
    [setCid, localCID],
  )

  if (!cid) {
    log(SHOW_LOGS.CLIENT_ID, "CID not found, showing loader")
    return <></>
  }

  log(SHOW_LOGS.CLIENT_ID, `CID loaded: ${cid}`)
  return <>{children}</>
}

export default CIDWrapper
