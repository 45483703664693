import React from "react"
import {
  RadioGroup as ChakraRadioGroup,
  RadioGroupProps as ChakraRadioGroupProps,
  SystemProps as ChakraSystemProps,
  forwardRef,
  Stack,
} from "@chakra-ui/react"
import { FormControl, SharedFormControlProps } from "./FormControl"
import { Radio, RadioProps } from "./Radio"
import { StringToHTMLParser } from "components/StringToHTMLParser"

export interface RadioGroupProps
  extends Omit<ChakraRadioGroupProps, "children">,
    SharedFormControlProps {
  /**
   * Stack checkbox buttons vertically (default) or horizontally (inline)
   */
  isInline?: boolean
  /**
   * Array of options to become radios
   */
  options?: RadioProps[]
  /**
   * The space between radios. References theme "spacing" variables.
   */
  spacing?: ChakraSystemProps["margin"]
}

export const RadioGroup = forwardRef<RadioGroupProps, "fieldset">(
  (
    {
      errorText,
      helperText,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      label,
      //
      isInline,
      options,
      spacing,
      defaultValue,
      value,
      children,
      info,
      ...rest
    },
    ref,
  ) => {
    const FormControlValues = {
      errorText,
      helperText,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      label,
      info,
    }

    return (
      <FormControl asFieldSet {...FormControlValues}>
        <ChakraRadioGroup
          value={
            defaultValue && (value === null || value === "")
              ? defaultValue
              : value
          }
          ref={ref}
          {...rest}
        >
          <Stack
            direction={isInline ? "row" : "column"}
            spacing={spacing}
            className="radio-group"
          >
            {options
              ? options.map(option => (
                  <Radio
                    key={option.value}
                    children={<StringToHTMLParser string={option.label} />}
                    {...option}
                  />
                ))
              : children}
          </Stack>
        </ChakraRadioGroup>
      </FormControl>
    )
  },
)
