import { optionize } from "data/utils"
import { ApplicationData } from "types"

export const TWO_OCEAN_DATA: ApplicationData = {
  layout: {
    version: "1",
  },
  meta: {
    title: "Two Ocean University",
    contact: {
      email: "info@website.com",
      phone: "1-800-555-5555",
      website: "https://www.website.com",
    },
    logo: "https://static.wixstatic.com/media/5a3952_a6b2a74526b14aa99eb3df4a904eba36~mv2.png/v1/fill/w_404,h_108,al_c,usm_0.66_1.00_0.01,enc_auto/Two_Ocean_Logo_Horizontal_Color.png",
    favicon:
      "https://twooceanuniversity.azureedge.net/application/TwoOceanUniversity.svg",
    name: "Two Ocean University",
    submissionRedirectUrl: "https://www.two-ocean.org/application/thanks",
  },
  pages: [
    {
      id: "about-you",
      slug: "about-you",
      title: "About You",
      navTitle: "About You",
      description: "We'd like to get to know you better.",
      sections: [
        {
          title: "You",
          description: "This is a description for You.",
          fields: [
            {
              name: "first_name",
              type: "text",
              label: "First/Given Name",
              isRequired: true,
              group: 1,
              order: 1,
            },
            {
              name: "middle_name",
              type: "text",
              label: "Middle Name",
              group: 1,
              order: 2,
            },
            {
              name: "last_name",
              type: "text",
              label: "Last/Family/Surname",
              isRequired: true,
              group: 1,
              order: 3,
            },
            {
              name: "suffix",
              type: "text",
              label: "Suffix",
            },
            {
              name: "preferred_name",
              type: "text",
              label: "Preferred Name",
              helperText:
                "If would you like to share another first name that you go by? We may use it in future communications.",
            },
            {
              name: "date_of_birth",
              type: "date",
              label: "Date of Birth",
              isRequired: true,
            },
            {
              name: "sex",
              type: "radio",
              label: "Legal Sex",
              options: optionize(["Male", "Female"]),
              isRequired: true,
            },
            {
              name: "gender_identity",
              type: "text",
              label: "Gender",
              helperText: "Your personal sense of identity.",
            },
            {
              name: "preferred_pronouns",
              type: "radio",
              label: "Pronouns",
              options: optionize([
                "He / Him / His",
                "She / Her / Hers",
                "They / Them / Theirs",
                "Other",
              ]),
            },
          ],
        },
        {
          title: "Your Background",
          description:
            "The more info you share, the better we'll get to know you.",
          fields: [
            {
              name: "religious_preference",
              label: "Your Religious Preference",
              type: "religionSearch",
              helperText:
                "Start typing to find and select your religious preference.",
            },
            {
              name: "hispanic",
              label: "Are you Hispanic or Latino/a/x?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
            {
              name: "race",
              label: "Your Race",
              helperText:
                "No matter how you answer the question above, please let us know how you describe yourself.",
              type: "checkbox",
              options: optionize([
                "American Indian or Alaska Native",
                "Asian",
                "Black or African American",
                "Native Hawaiian or Other Pacific Islander",
                "White",
                "Prefer not to say",
              ]),
              isRequired: true,
            },
          ],
        },
        {
          title: "Citizenship",
          description: "",
          fields: [
            {
              name: "citizenship",
              label: "Your Citizenship",
              helperText:
                "U.S. citizenship is not a requirement for admission. Your response may prompt additional questions you'll need to answer.",
              type: "radio",
              options: optionize([
                "U.S. Citizen or U.S. National",
                "U.S. Dual Citizen",
                "U.S. Permanent Resident",
                "Not a U.S. Citizen",
              ]),
              isRequired: true,
            },
            {
              name: "birth_country",
              label: "Birth Country",
              helperText: "Start typing to find and select a country.",
              type: "countrySearch",
              hide: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "U.S. Citizen or U.S. National",
                  },
                ],
              },
            },
            {
              name: "primary_language",
              label: "What language is spoken at home?",
              type: "languageSearch",
              hide: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "U.S. Citizen or U.S. National",
                  },
                ],
              },
            },
            {
              name: "citizenship_country",
              label: "In which other country are you a citizen?",
              helperText: "Start typing to find and select a country.",
              type: "countrySearch",
              hide: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "U.S. Citizen or U.S. National",
                  },
                ],
              },
            },
            {
              name: "visa_type",
              label: "Visa Type",
              type: "radio",
              options: optionize([
                "B-2",
                "E-2",
                "F-1",
                "F-2",
                "G-1",
                "G-2",
                "G-3",
                "G-4",
                "H-4",
                "J-1",
                "J-2",
                "L2",
                "R-2",
              ]),
              show: {
                conditions: [
                  { name: "citizenship", value: "Not a U.S. Citizen" },
                ],
              },
            },
          ],
        },
        {
          title: "Your Contact Information",
          description: "Let us know how best to reach you and where you live.",
          fields: [
            {
              name: "email_address",
              label: "Email Address",
              type: "email",
              isRequired: true,
              helperText:
                "This is the email address you provided when you started your application so it can't be changed. ",
            },
            { name: "phone_number", label: "Preferred Phone", type: "tel" },
            {
              name: "phone_text_opt-in",
              label: null,
              type: "checkbox",
              options: optionize([
                [
                  "Yes, I'd like to receive application status and other important updates via text from Two Ocean University",
                  "Yes",
                ],
              ]),
            },
            {
              name: "country",
              label: "Country",
              helperText: "Start typing to find and select a country.",
              type: "countrySearch",
              isRequired: true,
            },
            {
              name: "address1",
              label: "Address Line 1",
              type: "text",
              isRequired: true,
            },
            { name: "address2", label: "Address Line 2", type: "text" },
            { name: "city", label: "City", type: "text", isRequired: true },
            {
              name: "state",
              label: "State",
              type: "stateSearch",
              show: {
                conditions: [
                  { name: "country", value: "" },
                  { name: "country", value: "United States" },
                ],
                logicalOr: true,
              },
              isRequired: {
                conditions: [
                  { name: "country", value: "" },
                  { name: "country", value: "United States" },
                ],
                logicalOr: true,
              },
            },
            {
              name: "state_province",
              label: "State/Province",
              type: "text",
              show: {
                conditions: [
                  { name: "country", notValue: "" },
                  { name: "country", notValue: "United States" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "country", notValue: "" },
                  { name: "country", notValue: "United States" },
                ],
              },
            },
            {
              name: "zip",
              label: "Zip Code",
              type: "zip_code",
              validations: { length: { is: 5 } },
              show: {
                conditions: [
                  { name: "country", value: "" },
                  { name: "country", value: "United States" },
                ],
                logicalOr: true,
              },
              isRequired: {
                conditions: [
                  { name: "country", value: "" },
                  { name: "country", value: "United States" },
                ],
                logicalOr: true,
              },
            },
            {
              name: "postal_code",
              label: "Postal Code",
              type: "text",
              validations: { value: { min: 2, max: 15 } },
              show: {
                conditions: [
                  { name: "country", notValue: "" },
                  { name: "country", notValue: "United States" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "country", notValue: "" },
                  { name: "country", notValue: "United States" },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      id: "student-family",
      slug: "student-family",
      title: "Your Family",
      navTitle: "Family",
      description: "Help us get to know your family.",
      sections: [
        {
          title: "Your Parents & Guardians",
          description: "Tell us about your parents and/or guardians.",
          nestedSectionsMin: 1,
          nestedSectionsMax: 4,
          nestedSectionTitle: "Parent/Guardian {{x}}",
          nestedSectionItemName: "Parent/Guardian",
          fields: [
            {
              name: "parent_{{x}}_type",
              label: "Relationship",
              type: "radio",
              options: optionize([
                "Mother",
                "Father",
                "Legal Guardian",
                "Other",
              ]),
              isRequired: true,
            },
            {
              name: "parent_{{x}}_living",
              label: "Is parent/guardian living?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
            {
              name: "parent_{{x}}_prefix",
              label: "Prefix",
              type: "checkbox",
              options: optionize(["Dr.", "Mr.", "Ms.", "Mrs."]),
              show: {
                conditions: [{ name: "parent_{{x}}_living", value: "Yes" }],
              },
            },
            {
              name: "parent_{{x}}_first_name",
              label: "First Name",
              type: "text",
              show: {
                conditions: [{ name: "parent_{{x}}_living", value: "Yes" }],
              },
              isRequired: {
                conditions: [{ name: "parent_{{x}}_living", value: "Yes" }],
              },
            },
            {
              name: "parent_{{x}}_last_name",
              label: "Last Name",
              type: "text",
              show: {
                conditions: [{ name: "parent_{{x}}_living", value: "Yes" }],
              },
              isRequired: {
                conditions: [{ name: "parent_{{x}}_living", value: "Yes" }],
              },
            },
            {
              name: "parent_{{x}}_cell_phone",
              label: "Mobile Phone",
              type: "tel",
              show: {
                conditions: [{ name: "parent_{{x}}_living", value: "Yes" }],
              },
            },
            {
              name: "parent_{{x}}_email_address",
              label: "Email Address",
              helperText:
                "We'd like to send your parent important updates about your application and about financial aid.",
              type: "email",
              show: {
                conditions: [{ name: "parent_{{x}}_living", value: "Yes" }],
              },
            },
            {
              name: "parent_{{x}}_same_address",
              label: "Is their address the same as yours?",
              type: "radio",
              show: {
                conditions: [{ name: "parent_{{x}}_living", value: "Yes" }],
              },
              options: optionize(["Yes", "No"]),
              isRequired: {
                conditions: [{ name: "parent_{{x}}_living", value: "Yes" }],
              },
            },
            {
              name: "parent_{{x}}_country",
              label: "Country",
              type: "countrySearch",
              helperText: "Start typing to find and select a country.",
              show: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  { name: "parent_{{x}}_same_address", value: "No" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  { name: "parent_{{x}}_same_address", value: "No" },
                ],
              },
            },
            {
              name: "parent_{{x}}_address1",
              label: "Address Line 1",
              type: "text",
              show: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  { name: "parent_{{x}}_same_address", value: "No" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  { name: "parent_{{x}}_same_address", value: "No" },
                ],
              },
            },
            {
              name: "parent_{{x}}_address2",
              label: "Address Line 2",
              type: "text",
              show: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  { name: "parent_{{x}}_same_address", value: "No" },
                ],
              },
            },
            {
              name: "parent_{{x}}_city",
              label: "City",
              type: "text",
              show: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  { name: "parent_{{x}}_same_address", value: "No" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  { name: "parent_{{x}}_same_address", value: "No" },
                ],
              },
            },
            {
              name: "parent_{{x}}_state",
              label: "State",
              type: "stateSearch",
              show: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  { name: "parent_{{x}}_same_address", value: "No" },
                  { name: "parent_{{x}}_country", value: "United States" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  { name: "parent_{{x}}_country", value: "United States" },
                  { name: "parent_{{x}}_same_address", value: "No" },
                ],
              },
            },
            {
              name: "parent_{{x}}_state_province",
              label: "State/Province",
              type: "text",
              show: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  { name: "parent_{{x}}_same_address", value: "No" },
                  {
                    name: "parent_{{x}}_country",
                    notValue: "United States",
                  },
                  { name: "parent_{{x}}_country", notValue: "" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  {
                    name: "parent_{{x}}_country",
                    notValue: "United States",
                  },
                  { name: "parent_{{x}}_country", notValue: "" },
                ],
              },
            },
            {
              name: "parent_{{x}}_zip_code",
              label: "Zip Code",
              type: "zip_code",
              show: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  { name: "parent_{{x}}_same_address", value: "No" },
                  { name: "parent_{{x}}_country", value: "United States" },
                ],
              },
              validations: { length: { is: 5 } },
              isRequired: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  { name: "parent_{{x}}_country", value: "United States" },
                ],
                logic: "0&&(1||2)",
              },
            },
            {
              name: "parent_{{x}}_postal_code",
              label: "Postal Code",
              type: "text",
              show: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  { name: "parent_{{x}}_same_address", value: "No" },
                  {
                    name: "parent_{{x}}_country",
                    notValue: "United States",
                  },
                  { name: "parent_{{x}}_country", notValue: "" },
                ],
              },
              validations: { length: { min: 5, max: 15 } },
              isRequired: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  {
                    name: "parent_{{x}}_country",
                    notValue: "United States",
                  },
                  { name: "parent_{{x}}_country", notValue: "" },
                ],
              },
            },
            {
              name: "parent_{{x}}_occupation",
              label: "Occupation",
              type: "text",
              show: {
                conditions: [{ name: "parent_{{x}}_living", value: "Yes" }],
              },
            },
            {
              name: "parent_{{x}}_employer",
              label: "Employer",
              type: "text",
              show: {
                conditions: [{ name: "parent_{{x}}_living", value: "Yes" }],
              },
            },
            {
              name: "parent_{{x}}_education_level",
              label: "Education Level",
              type: "select",
              options: optionize([
                "Some grade/primary school",
                "Completed grade/primary school",
                "Some high/secondary school",
                "Graduated from high/secondary school (or equivalent)",
                "Some trade school or community college",
                "Some college/university",
                "Graduated from college/university",
                "Graduate School",
                "Doctoral Program",
              ]),
              show: {
                conditions: [{ name: "parent_{{x}}_living", value: "Yes" }],
              },
            },
            {
              name: "parent_{{x}}_alumni",
              label: "Did they attend Two Ocean University?",
              type: "radio",
              show: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  {
                    name: "parent_{{x}}_education_level",
                    value: "Some college/university",
                  },
                  {
                    name: "parent_{{x}}_education_level",
                    value: "Graduated from college/university",
                  },
                  {
                    name: "parent_{{x}}_education_level",
                    value: "Graduate School",
                  },
                  {
                    name: "parent_{{x}}_education_level",
                    value: "Doctoral Program",
                  },
                ],
                logic: "0&&(1||2||3||4)",
              },
              options: optionize(["Yes", "No"]),
              validations: { length: { min: 5, max: 15 } },
              isRequired: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  { name: "parent_{{x}}_country", value: "US" },
                ],
              },
            },
            {
              name: "parent_{{x}}_college_ceebcode",
              label: "Parent's/Guardian's College",
              helperText: "Search for your parent's/guardian's college.",
              type: "collegeSearch",
              show: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  { name: "parent_{{x}}_alumni", notValue: "Yes" },
                  {
                    name: "parent_{{x}}_education_level",
                    value: "Some college/university",
                  },
                  {
                    name: "parent_{{x}}_education_level",
                    value: "Graduated from college/university",
                  },
                  {
                    name: "parent_{{x}}_education_level",
                    value: "Graduate School",
                  },
                ],
                logic: "0&&1&&(2||3||4)",
              },
            },
            {
              name: "parent_{{x}}_college_name",
              label: "College Name",
              type: "text",
              show: {
                conditions: [
                  { name: "parent_{{x}}_living", value: "Yes" },
                  { name: "parent_{{x}}_alumni", notValue: "Yes" },
                  { name: "parent_{{x}}_college_ceebcode", notValue: null },
                  {
                    name: "parent_{{x}}_education_level",
                    value: "Some college/university",
                  },
                  {
                    name: "parent_{{x}}_education_level",
                    value: "Graduated from college/university",
                  },
                  {
                    name: "parent_{{x}}_education_level",
                    value: "Graduate School",
                  },
                  {
                    name: "parent_{{x}}_education_level",
                    value: "Doctoral Program",
                  },
                ],
                logic: "0&&1&&2&&(3||4||5||6)",
              },
            },
          ],
        },
        {
          title: "Optional: Siblings",
          description:
            "Tell us about any other children of your parents or guardians.",
          nestedSectionsMax: 5,
          nestedSectionTitle: "Sibling {{x}}",
          nestedSectionItemName: "Sibling",
          fields: [
            {
              name: "sibling_{{x}}_first_name",
              type: "text",
              label: "First Name",
            },
            {
              name: "sibling_{{x}}_last_name",
              type: "text",
              label: "Last Name",
            },
            {
              name: "sibling_{{x}}_relationship",
              type: "radio",
              label: "Relationship",
              options: optionize([
                "Brother",
                "Sister",
                "Step-Brother",
                "Step-Sister",
              ]),
            },
            {
              name: "sibling_{{x}}_education_level",
              label: "Education Level",
              type: "select",
              options: optionize([
                "Some grade/primary school",
                "Completed grade/primary school",
                "Some high/secondary school",
                "Graduated from high/secondary school (or equivalent)",
                "Some trade school or community college",
                "Some college/university",
                "Graduated from college/university",
                "Graduate School",
                "Doctoral Program",
              ]),
            },
            {
              name: "sibling_{{x}}_alumni",
              label: "Did they attend Two Ocean University?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  {
                    name: "sibling_{{x}}_education_level",
                    value: "Some college/university",
                  },
                  {
                    name: "sibling_{{x}}_education_level",
                    value: "Graduated from college/university",
                  },
                  {
                    name: "sibling_{{x}}_education_level",
                    value: "Graduate School",
                  },
                  {
                    name: "sibling_{{x}}_education_level",
                    value: "Doctoral Program",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "sibling_{{x}}_college_ceebcode",
              label: "College",
              type: "collegeSearch",
              show: {
                conditions: [
                  {
                    name: "sibling_{{x}}_education_level",
                    value: "Some college/university",
                  },
                  {
                    name: "sibling_{{x}}_education_level",
                    value: "Graduated from college/university",
                  },
                  {
                    name: "sibling_{{x}}_education_level",
                    value: "Graduate School",
                  },
                  {
                    name: "sibling_{{x}}_education_level",
                    value: "Doctoral Program",
                  },
                  {
                    name: "sibling_{{x}}_alumni",
                    value: "No",
                  },
                ],
                logic: "(0||1||2||3)&&4",
              },
            },
          ],
        },
        {
          title: "Optional: Alumni",
          description:
            "Has anyone else in your family attended Two Ocean University? You can share up to 4 family members who attended or are currently enrolled.",
          nestedSectionsMax: 4,
          nestedSectionTitle: "Alumni {{x}}",
          nestedSectionItemName: "Alumni",
          fields: [
            {
              name: "alumni_{{x}}_relationship",
              label: "Relationship",
              type: "select",
              options: optionize([
                "Mother",
                "Father",
                "Step-Mother",
                "Step-Father",
                "Brother",
                "Sister",
                "Step-Brother",
                "Step-Sister",
                "Grandparent",
                "Uncle",
                "Aunt",
                "Cousin",
                "Other",
              ]),
            },
            {
              name: "alumni_{{x}}_first_name",
              label: "First Name",
              type: "text",
            },
            {
              name: "alumni_{{x}}_last_name",
              label: "Last Name",
              type: "text",
            },
            {
              name: "alumni_{{x}}_current",
              label: "Do they currently attend Two Ocean University?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "alumni_{{x}}_grad_year",
              label: "Graduation Year",
              type: "year",
            },
            {
              name: "alumni_{{x}}_email_address",
              label: "Email Address",
              type: "email",
            },
          ],
        },
      ],
    },
    {
      id: "your-experience",
      slug: "your-experience",
      title: "Your Experience",
      navTitle: "Your Experience",
      description: "We'd like to know about your accomplishments.",
      sections: [
        {
          title: "Most Recent Education",
          description: "Your Current or Most Recent Education",
          fields: [
            {
              hide: {
                conditions: [
                  {
                    name: "base_institution_school_type",
                    value: "High School",
                  },
                ],
              },
              name: "base_institution_school_type",
              label: "Institution Type",
              type: "radio",
              options: optionize(["High School", "College"]),
              isRequired: true,
            },
            {
              name: "base_institution_ceeb_high_school",
              label: "Start typing to search for your school",
              type: "highSchoolSearch",
              isRequired: true,
              show: {
                conditions: [
                  {
                    name: "base_institution_school_type",
                    value: "High School",
                  },
                ],
              },
            },
            {
              name: "base_institution_ceeb_college",
              label: "Start typing to search for your school",
              type: "collegeSearch",
              isRequired: true,
              show: {
                conditions: [
                  { name: "base_institution_school_type", value: "College" },
                ],
              },
            },
            {
              name: "base_institution_name",
              type: "text",
              label: "Institution Name",
              show: {
                conditions: [
                  {
                    name: "base_institution_school_type",
                    value: "High School",
                  },
                  { name: "base_institution_ceeb_high_school", value: "" },
                  { name: "base_institution_school_type", value: "College" },
                  { name: "base_institution_ceeb_college", value: "" },
                ],
                logic: "(0&&1)||(2&&3)",
              },
              isRequired: {
                conditions: [
                  { name: "base_institution_ceeb_high_school", value: "" },
                  { name: "base_institution_ceeb_college", value: "" },
                ],
              },
            },
            {
              name: "base_institution_country",
              type: "countrySearch",
              label: "Institution Country",
              show: {
                conditions: [
                  { name: "base_institution_ceeb_high_school", value: "" },
                  { name: "base_institution_ceeb_college", value: "" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "base_institution_ceeb_high_school", value: "" },
                  { name: "base_institution_ceeb_college", value: "" },
                ],
              },
            },
            {
              name: "base_institution_address1",
              type: "text",
              label: "Institution Address",
              show: {
                conditions: [
                  { name: "base_institution_ceeb_high_school", value: "" },
                  { name: "base_institution_ceeb_college", value: "" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "base_institution_ceeb_high_school", value: "" },
                  { name: "base_institution_ceeb_college", value: "" },
                ],
              },
            },
            {
              name: "base_institution_city",
              type: "text",
              label: "Institution City",
              show: {
                conditions: [
                  { name: "base_institution_ceeb_high_school", value: "" },
                  { name: "base_institution_ceeb_college", value: "" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "base_institution_ceeb_high_school", value: "" },
                  { name: "base_institution_ceeb_college", value: "" },
                ],
              },
            },
            {
              name: "base_institution_state",
              type: "stateSearch",
              label: "Institution State",
              show: {
                conditions: [
                  { name: "base_institution_ceeb_high_school", value: "" },
                  { name: "base_institution_ceeb_college", value: "" },
                  { name: "base_institution_country", value: "United States" },
                  { name: "base_institution_country", value: "" },
                ],
                logic: "0&&1&&(2||3)",
              },
              isRequired: {
                conditions: [
                  { name: "base_institution_ceeb_high_school", value: "" },
                  { name: "base_institution_ceeb_college", value: "" },
                  { name: "base_institution_country", value: "United States" },
                  { name: "base_institution_country", value: "" },
                ],
                logic: "0&&1&&(2||3)",
              },
            },
            {
              name: "base_institution_state_province",
              label: "Institution State/Province",
              type: "text",
              show: {
                conditions: [
                  { name: "base_institution_ceeb_high_school", value: "" },
                  { name: "base_institution_ceeb_college", value: "" },
                  {
                    name: "base_institution_country",
                    notValue: "United States",
                  },
                  { name: "base_institution_country", notValue: "" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "base_institution_ceeb_high_school", value: "" },
                  { name: "base_institution_ceeb_college", value: "" },
                  {
                    name: "base_institution_country",
                    notValue: "United States",
                  },
                  { name: "base_institution_country", notValue: "" },
                ],
                logic: "0&&1&&(2||3)",
              },
            },
            {
              name: "base_institution_zip_code",
              label: "Institution Zip Code",
              type: "zip_code",
              validations: { length: { is: 5 } },
              show: {
                conditions: [
                  { name: "base_institution_ceeb_high_school", value: "" },
                  { name: "base_institution_ceeb_college", value: "" },
                  { name: "base_institution_country", value: "United States" },
                  { name: "base_institution_country", value: "" },
                ],
                logic: "0&&1&&(2||3)",
              },
              isRequired: {
                conditions: [
                  { name: "base_institution_ceeb_high_school", value: "" },
                  { name: "base_institution_ceeb_college", value: "" },
                  { name: "base_institution_country", value: "United States" },
                  { name: "base_institution_country", value: "" },
                ],
                logic: "0&&1&&(2||3)",
              },
            },
            {
              name: "base_institution_postal_code",
              label: "Institution Postal Code",
              type: "text",
              show: {
                conditions: [
                  { name: "base_institution_ceeb_high_school", value: "" },
                  { name: "base_institution_ceeb_college", value: "" },
                  {
                    name: "base_institution_country",
                    notValue: "United States",
                  },
                  { name: "base_institution_country", notValue: "" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "base_institution_ceeb_high_school", value: "" },
                  { name: "base_institution_ceeb_college", value: "" },
                  {
                    name: "base_institution_country",
                    notValue: "United States",
                  },
                  { name: "base_institution_country", notValue: "" },
                ],
                logic: "0&&1&&(2||3)",
              },
            },
            {
              name: "base_institution_from_date",
              label: "Institution Start Date",
              type: "month",
              validations: {
                date: { maxYearsInPast: 100, isInPast: true },
              },
              isRequired: true,
            },
            {
              name: "base_institution_to_date",
              label: "Institution End Date",
              type: "month",
              validations: {
                date: { maxYearsInPast: 100, maxYearsInFuture: 2 },
              },
              isRequired: true,
            },
            {
              name: "base_institution_hs_did_you_graduate",
              label: "Have you or will you graduate?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
            {
              name: "base_institution_hs_equivalency_degree",
              label: "Will you receive a high school equivalency degree?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  { name: "base_institution_hs_did_you_graduate", value: "No" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "base_institution_hs_did_you_graduate", value: "No" },
                ],
              },
            },
            {
              name: "base_institution_college_course_details",
              label: "Do any of the following describe your enrollment?",
              type: "radio",
              options: optionize([
                "Dual Enrollment with High School",
                "Summer Program",
                "Credit Awarded directly by college",
              ]),
            },
          ],
        },
        {
          title: "Optional: Your Education History",
          description:
            "If you've attended any other high schools/secondary schools or colleges/universities, please enter them below, including any college courses you completed while in high school.",
          nestedSectionsMax: 5,
          nestedSectionTitle: "Additional Education History {{x}}",
          nestedSectionItemName: "Education History",
          fields: [
            {
              name: "institution_{{x}}_school_type",
              label: "Institution Type",
              type: "radio",
              options: optionize(["High School", "College"]),
              isRequired: true,
            },
            {
              name: "institution_{{x}}_ceeb_high_school",
              label: "Start typing to search for your school",
              type: "highSchoolSearch",
              isRequired: true,
              show: {
                conditions: [
                  {
                    name: "institution_{{x}}_school_type",
                    value: "High School",
                  },
                ],
              },
            },
            {
              name: "institution_{{x}}_ceeb_college",
              label: "Start typing to search for your school",
              type: "collegeSearch",
              isRequired: true,
              show: {
                conditions: [
                  { name: "institution_{{x}}_school_type", value: "College" },
                ],
              },
            },
            {
              name: "institution_{{x}}_name",
              type: "text",
              label: "Institution Name",
              show: {
                conditions: [
                  { name: "institution_{{x}}_ceeb_high_school", value: "" },
                  { name: "institution_{{x}}_ceeb_college", value: "" },
                ],
              },
            },
            {
              name: "institution_{{x}}_country",
              type: "countrySearch",
              label: "Institution Country",
              show: {
                conditions: [
                  { name: "institution_{{x}}_ceeb_high_school", value: "" },
                  { name: "institution_{{x}}_ceeb_college", value: "" },
                ],
              },
            },
            {
              name: "institution_{{x}}_address1",
              type: "text",
              label: "Institution Address",
              show: {
                conditions: [
                  { name: "institution_{{x}}_ceeb_high_school", value: "" },
                  { name: "institution_{{x}}_ceeb_college", value: "" },
                ],
              },
            },
            {
              name: "institution_{{x}}_city",
              type: "text",
              label: "Institution City",
              show: {
                conditions: [
                  { name: "institution_{{x}}_ceeb_high_school", value: "" },
                  { name: "institution_{{x}}_ceeb_college", value: "" },
                ],
              },
            },
            {
              name: "institution_{{x}}_state",
              type: "stateSearch",
              label: "Institution State",
              show: {
                conditions: [
                  { name: "institution_{{x}}_ceeb_high_school", value: "" },
                  { name: "institution_{{x}}_ceeb_college", value: "" },
                  { name: "institution_{{x}}_country", value: "United States" },
                ],
              },
            },
            {
              name: "institution_{{x}}_state_province",
              label: "Institution State/Province",
              type: "text",
              show: {
                conditions: [
                  { name: "institution_{{x}}_ceeb_high_school", value: "" },
                  { name: "institution_{{x}}_ceeb_college", value: "" },
                  {
                    name: "institution_{{x}}_country",
                    notValue: "United States",
                  },
                ],
              },
            },
            {
              name: "institution_{{x}}_zip_code",
              label: "Institution Zip Code",
              type: "zip_code",
              validations: { length: { is: 5 } },
              show: {
                conditions: [
                  { name: "institution_{{x}}_ceeb_high_school", value: "" },
                  { name: "institution_{{x}}_ceeb_college", value: "" },
                  { name: "institution_{{x}}_country", value: "United States" },
                ],
              },
            },
            {
              name: "institution_{{x}}_postal_code",
              label: "Institution Postal Code",
              type: "text",
              show: {
                conditions: [
                  { name: "institution_{{x}}_ceeb_high_school", value: "" },
                  { name: "institution_{{x}}_ceeb_college", value: "" },
                  {
                    name: "institution_{{x}}_country",
                    notValue: "United States",
                  },
                ],
              },
            },
            {
              name: "institution_{{x}}_from_date",
              label: "Institution Start Date",
              type: "month",
              validations: {
                date: { maxYearsInPast: 100, isInPast: true },
              },
              isRequired: true,
            },
            {
              name: "institution_{{x}}_to_date",
              label: "Institution End Date",
              type: "month",
              validations: {
                date: { maxYearsInPast: 100, maxYearsInFuture: 2 },
              },
              isRequired: true,
            },
            {
              name: "institution_{{x}}_hs_did_you_graduate",
              label: "Have you or will you graduate?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "institution_{{x}}_hs_equivalency_degree",
              label: "Will you receive a high school equivalency degree?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  {
                    name: "institution_{{x}}_hs_did_you_graduate",
                    value: "No",
                  },
                ],
              },
            },
            {
              name: "institution_{{x}}_college_course_details",
              label: "Do any of the following describe your enrollment?",
              type: "radio",
              options: optionize([
                "Dual Enrollment with High School",
                "Summer Program",
                "Credit Awarded directly by college",
              ]),
            },
          ],
        },
        {
          title: "High School GPA",
          description: "",
          fields: [
            {
              label: "HS GPA Scale",
              name: "hs_gpa_scale",
              type: "select",
              options: optionize([
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "100",
                "None",
              ]),
              isRequired: true,
            },
            {
              name: "hs_gpa_cumulative",
              label: "HS GPA Cumulative",
              type: "number",
              isRequired: true,
              validations: { value: { max: 100 } },
              errorText:
                "GPA must be less than (or equal to) maximum GPA possible",
            },
            {
              name: "hs_gpa_weighted_or_unweighted",
              label: "HS GPA Weighted or Unweighted",
              type: "radio",
              options: optionize(["Weighted", "Unweighted"]),
              isRequired: true,
            },
          ],
        },
        {
          title: "High School Counselor",
          description:
            "Please provide the name and email address for your high school guidance counselor. You'll also want to make sure your guidance counselor knows you've applied to Two Ocean University.",
          fields: [
            {
              name: "counselor_first_name",
              label: "First Name",
              type: "text",
              isRequired: true,
            },
            {
              name: "counselor_last_name",
              label: "Last Name",
              type: "text",
              isRequired: true,
            },
            {
              name: "counselor_email_address",
              label: "Email Address",
              type: "email",
              isRequired: true,
            },
          ],
        },
        {
          title: "Letters of Recommendation Access Waiver",
          description: "",
          fields: [
            {
              name: "letters_of_rec_access_waiver",
              label: "Letters of Recommendation Access Waiver",
              helperText:
                "Letters of recommendation support your application and provide helpful information to the admissions office. Waiving your right to review these allows your teacher or counselor to provide their best assessment of you in terms of admission.",
              isRequired: true,
              type: "radio",
              options: optionize([
                ["Yes, I waive my right to review", "Yes"],
                ["No, I DO NOT waive my right to review", "No"],
              ]),
            },
          ],
        },
        {
          title: "Optional: Letters of Recommendation",
          description:
            "We'd like to hear from others who know you well and can speak to your accomplishments. Teachers, counselors, mentors, pastors/priests and employers who know you well are ideal people to write your letters of recommendation. ",
          nestedSectionTitle: "Recommender {{x}}",
          nestedSectionItemName: "Recommender",
          nestedSectionsMax: 4,
          fields: [
            {
              name: "letters_of_rec_{{x}}_first_name",
              label: "First Name",
              type: "text",
            },
            {
              name: "letters_of_rec_{{x}}_last_name",
              label: "Last Name",
              type: "text",
            },
            {
              name: "letters_of_rec_{{x}}_relationship",
              label: "Relationship",
              type: "select",
              options: optionize([
                "Teachers",
                "Counselors",
                "Mentors",
                "Pastors/Priests",
                "Employers",
              ]),
            },
            {
              name: "letters_of_rec_{{x}}_email",
              label: "Email Address",
              type: "email",
            },
          ],
        },
        {
          title: "Optional: Courses",
          description:
            "Tell us about the courses you are currently taking this academic year.",
          nestedSectionTitle: "Course {{x}}",
          nestedSectionItemName: "Course",
          nestedSectionsMax: 20,
          fields: [
            { name: "course_{{x}}_title", type: "text", label: "Course Name" },
            {
              name: "course_{{x}}_semester",
              type: "radio",
              label: "Course Semester",
              options: optionize(["Fall", "Spring", "Summer", "Full Year"]),
            },
          ],
        },
        {
          title: "Optional: High School Honors",
          description: "Let us know about any honor or awards you've received.",
          nestedSectionTitle: "Honor {{x}}",
          nestedSectionItemName: "Honor",
          nestedSectionsMax: 5,
          fields: [
            {
              name: "hs_honor_{{x}}_title",
              label: "Honor Name",
              helperText: "What was your honor/award for?",
              type: "text",
            },
            {
              name: "hs_honor_{{x}}_level",
              label: "Honor Year(s)",
              helperText: "When did you receive this honor/award?",
              type: "checkbox",
              options: optionize(["9", "10", "11", "12"]),
            },
            {
              name: "hs_honor_{{x}}_recognition_level",
              label: "Honor Recognition Level",
              type: "radio",
              options: optionize([
                "School",
                "State",
                "National",
                "International",
              ]),
            },
          ],
        },
        {
          title: "Optional: Extracurricular Activities",
          description:
            "Share the activities you have participated in during high school/secondary school.",
          nestedSectionTitle: "Extracurricular Activity {{x}}",
          nestedSectionItemName: "Extracurricular",
          nestedSectionsMax: 6,
          fields: [
            {
              name: "extracurricular_activity_{{x}}_type",
              label: "Type of Activity",
              type: "select",
              options: optionize(["Sports", "Clubs or Groups", "Other"]),
            },
            {
              name: "extracurricular_activity_{{x}}_name",
              label: "Name of Activity",
              type: "activitySearch",
              show: {
                conditions: [
                  {
                    name: "extracurricular_activity_{{x}}_type",
                    value: "Clubs or Groups",
                  },
                ],
              },
            },
            {
              name: "extracurricular_activity_{{x}}_activity_name",
              label: "Name of Activity",
              type: "text",
              show: {
                conditions: [
                  {
                    name: "extracurricular_activity_{{x}}_type",
                    value: "Other",
                  },
                ],
              },
            },
            {
              name: "extracurricular_activity_{{x}}_sports_level",
              label: "Sport Level",
              type: "radio",
              options: optionize(["Athletics: Club", "Athletics: JV/Varsity"]),
              show: {
                conditions: [
                  {
                    name: "extracurricular_activity_{{x}}_type",
                    value: "Sports",
                  },
                ],
              },
            },
            {
              name: "extracurricular_activity_{{x}}_team_name",
              label: "Sport/team",
              type: "sportSearch",
              show: {
                conditions: [
                  {
                    name: "extracurricular_activity_{{x}}_type",
                    value: "Sports",
                  },
                ],
              },
            },
            {
              name: "extracurricular_activity_{{x}}_position",
              label: "Position/Leadership description",
              type: "text",
            },
            {
              name: "extracurricular_activity_{{x}}_organization_name",
              label: "Organization Name",
              type: "text",
            },
            {
              name: "extracurricular_activity_{{x}}_grade_levels",
              label: "During which years did you participate?",
              type: "checkbox",
              options: optionize(["9", "10", "11", "12"]),
            },
            {
              name: "extracurricular_activity_{{x}}_timing",
              label: "During which part of the year did the activity occur?",
              helperText:
                "When did you participate in this activity? Select all that apply.",
              type: "checkbox",
              options: optionize([
                "During School Year",
                "During Break",
                "All Year",
              ]),
            },
            {
              name: "extracurricular_activity_{{x}}_continue_in_college",
              label: "Will you plan on participating when in college?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "extracurricular_activity_{{x}}_description",
              label:
                "Please describe this activity, including what you accomplished and any recognition you received, etc.",
              type: "textarea",
            },
            {
              name: "extracurricular_activity_{{x}}_hours",
              label: "Hours spent per week",
              type: "number",
              validations: { value: { max: 99 } },
            },
            {
              name: "extracurricular_activity_{{x}}_weeks",
              label: "Weeks spent per year",
              type: "number",
              validations: { value: { max: 52 } },
            },
          ],
        },
        {
          title: "Optional: Test Scores",
          description:
            "Test Optional School: Submitting test scores is optional. Let us know if you'd like us to consider them.",
          fields: [
            {
              name: "test_scores_considered",
              label: "Do you want to provide test scores?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
          ],
        },
        {
          title: "SAT",
          description: "",
          show: {
            conditions: [{ name: "test_scores_considered", value: "Yes" }],
          },
          fields: [
            {
              name: "sat_considered",
              label: "Do you want SAT test scores considered?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "sat_date_taken",
              label: "SAT Date Taken",
              type: "month",
              show: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "sat_considered", value: "Yes" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "sat_considered", value: "Yes" },
                ],
              },
              validations: { date: { maxYearsInPast: 5, isInPast: true } },
            },
            {
              name: "sat_highest_evidence_based_reading_and_writing_score",
              label: "Highest Evidence-Based Reading and Writing Score",
              type: "integer",
              show: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "sat_considered", value: "Yes" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "sat_considered", value: "Yes" },
                ],
              },
              validations: { value: { min: 200, max: 800, divisor: 10 } },
            },
            {
              name: "sat_highest_math_score",
              label: "Highest Math Score",
              type: "integer",
              show: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "sat_considered", value: "Yes" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "sat_considered", value: "Yes" },
                ],
              },
              validations: { value: { min: 200, max: 800, divisor: 10 } },
            },
            {
              name: "sat_future_tests",
              label: "Future Test Dates",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "sat_considered", value: "Yes" },
                ],
              },
            },
            {
              name: "sat_future_testing_date_1",
              label: "Future Testing Date 1",
              type: "month",
              show: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "sat_considered", value: "Yes" },
                  { name: "sat_future_tests", value: "Yes" },
                ],
              },
              validations: {
                date: { maxMonthsInPast: 6, maxYearsInFuture: 1 },
              },
            },
            {
              name: "sat_future_testing_date_2",
              label: "Future Testing Date 2",
              type: "month",
              show: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "sat_considered", value: "Yes" },
                  { name: "sat_future_tests", value: "Yes" },
                ],
              },
              validations: {
                date: { maxMonthsInPast: 6, maxYearsInFuture: 1 },
              },
            },
            {
              name: "sat_future_testing_date_3",
              label: "Future Testing Date 3",
              type: "month",
              show: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "sat_considered", value: "Yes" },
                  { name: "sat_future_tests", value: "Yes" },
                ],
              },
              validations: {
                date: { maxMonthsInPast: 6, maxYearsInFuture: 1 },
              },
            },
          ],
        },
        {
          title: "ACT",
          description: "",
          show: {
            conditions: [{ name: "test_scores_considered", value: "Yes" }],
          },
          fields: [
            {
              name: "act_considered",
              label: "Do you want ACT test scores considered?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "act_date_taken",
              label: "ACT Date Taken",
              type: "month",
              show: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "act_considered", value: "Yes" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "act_considered", value: "Yes" },
                ],
              },
              validations: {
                date: { maxYearsInPast: 5, isInPast: true },
              },
            },
            {
              name: "act_highest_composite_score",
              label: "ACT Highest Composite Score",
              type: "integer",
              show: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "act_considered", value: "Yes" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "act_considered", value: "Yes" },
                ],
              },
              validations: { value: { min: 0, max: 36 } },
            },
            {
              name: "act_future_tests",
              label: "Future Test Dates",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "act_considered", value: "Yes" },
                ],
              },
            },
            {
              name: "act_future_testing_date_1",
              label: "Future Testing Date 1",
              type: "month",
              show: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "act_considered", value: "Yes" },
                  { name: "act_future_tests", value: "Yes" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "act_considered", value: "Yes" },
                  { name: "act_future_tests", value: "Yes" },
                ],
              },
              validations: {
                date: { maxMonthsInPast: 6, maxYearsInFuture: 1 },
              },
            },
            {
              name: "act_future_testing_date_2",
              label: "Future Testing Date 2",
              type: "month",
              show: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "act_considered", value: "Yes" },
                  { name: "act_future_tests", value: "Yes" },
                ],
              },
              validations: {
                date: { maxMonthsInPast: 6, maxYearsInFuture: 1 },
              },
            },
            {
              name: "act_future_testing_date_3",
              label: "Future Testing Date 3",
              type: "month",
              show: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "act_considered", value: "Yes" },
                  { name: "act_future_tests", value: "Yes" },
                ],
              },
              validations: {
                date: { maxMonthsInPast: 6, maxYearsInFuture: 1 },
              },
            },
          ],
        },
        {
          title: "AP Tests Considered?",
          description: "",
          show: {
            conditions: [{ name: "test_scores_considered", value: "Yes" }],
          },
          fields: [
            {
              name: "ap_test_considered",
              label: "Do you plan to take, or have you taken any AP tests?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
          ],
        },
        {
          title: "AP Tests",
          description: "",
          nestedSectionTitle: "AP Test {{x}}",
          nestedSectionItemName: "AP Test",
          nestedSectionsMax: 15,
          show: {
            conditions: [
              { name: "ap_test_considered", value: "Yes" },
              { name: "test_scores_considered", value: "Yes" },
            ],
          },
          fields: [
            {
              name: "ap_test_{{x}}_subject",
              label: "AP Test {{x}} Subject",
              type: "apSearch",
            },
            {
              name: "ap_test_{{x}}_score",
              label: "AP Test {{x}} Score",
              type: "integer",
              validations: { value: { min: 0, max: 5 } },
            },
          ],
        },
        {
          title: "IB Tests Considered?",
          description: "",
          show: {
            conditions: [{ name: "test_scores_considered", value: "Yes" }],
          },
          fields: [
            {
              name: "ib_test_considered",
              label: "Do you plan to take, or have you taken any IB tests?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
          ],
        },
        {
          title: "IB Tests",
          description: "",
          nestedSectionTitle: "IB Test {{x}}",
          nestedSectionItemName: "IB Test",
          nestedSectionsMax: 15,
          show: {
            conditions: [
              { name: "ib_test_considered", value: "Yes" },
              { name: "test_scores_considered", value: "Yes" },
            ],
          },
          fields: [
            {
              name: "ib_test_{{x}}_subject",
              label: "IB Test {{x}} Subject",
              type: "ibSearch",
            },
            {
              name: "ib_test_{{x}}_score",
              label: "IB Test {{x}} Score",
              type: "integer",
              validations: { value: { min: 0, max: 7 } },
            },
          ],
        },
        {
          title: "TOEFL",
          description: "",
          show: {
            conditions: [{ name: "test_scores_considered", value: "Yes" }],
          },
          fields: [
            {
              name: "toefl_ielts_considered",
              label: "Do you want your TOEFL test scores considered?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [{ name: "test_scores_considered", value: "Yes" }],
              },
            },
            {
              name: "toefl_ielts_date_taken",
              label: "TOEFL Date Taken",
              type: "month",
              validations: { date: { maxYearsInPast: 5, isInPast: true } },
              show: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "toefl_ielts_considered", value: "Yes" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "toefl_ielts_considered", value: "Yes" },
                ],
              },
            },
            {
              name: "toefl_ielts_highest_score",
              label: "TOEFL Highest Score",
              type: "integer",
              show: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "toefl_ielts_considered", value: "Yes" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "test_scores_considered", value: "Yes" },
                  { name: "toefl_ielts_considered", value: "Yes" },
                ],
              },
              validations: { value: { min: 0, max: 120 } },
            },
          ],
        },
        {
          title: "Duolingo",
          description: "",
          show: {
            conditions: [{ name: "test_scores_considered", value: "Yes" }],
          },
          fields: [
            {
              name: "duolingo_english_test_considered",
              label:
                "Do you plan to take, or have you taken the Duolingo English Test?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "duolingo_english_test_date_taken",
              label: "Duolingo English Test Date Taken",
              type: "month",
              show: {
                conditions: [
                  { name: "duolingo_english_test_considered", value: "Yes" },
                ],
              },
              validations: { date: { maxYearsInPast: 5, isInPast: true } },
            },
            {
              name: "duolingo_english_test_score",
              label: "Duolingo English Test Score",
              type: "integer",
              validations: { value: { min: 10, max: 160 } },
              show: {
                conditions: [
                  { name: "duolingo_english_test_considered", value: "Yes" },
                ],
              },
            },
            {
              name: "duolingo_english_test_literacy_score",
              label: "Duolingo English Test Literacy Score",
              type: "integer",
              validations: { value: { min: 10, max: 160 } },
              show: {
                conditions: [
                  { name: "duolingo_english_test_considered", value: "Yes" },
                ],
              },
            },
            {
              name: "duolingo_english_test_conversation_score",
              label: "Duolingo English Test Conversation Score",
              type: "integer",
              validations: { value: { min: 10, max: 160 } },
              show: {
                conditions: [
                  { name: "duolingo_english_test_considered", value: "Yes" },
                ],
              },
            },
            {
              name: "duolingo_english_test_comprehension_score",
              label: "Duolingo English Test Comprehension Score",
              type: "integer",
              validations: { value: { min: 10, max: 160 } },
              show: {
                conditions: [
                  { name: "duolingo_english_test_considered", value: "Yes" },
                ],
              },
            },
            {
              name: "duolingo_english_test_production_score",
              label: "Duolingo English Test Production Score",
              type: "integer",
              validations: { value: { min: 10, max: 160 } },
              show: {
                conditions: [
                  { name: "duolingo_english_test_considered", value: "Yes" },
                ],
              },
            },
          ],
        },
        {
          title: "Optional: Armed Forces",
          description: "",
          fields: [
            {
              name: "us_armed_forces",
              label:
                "Have you or are you currently serving in the armed forces?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
          ],
        },
        {
          title: "Legal Disclosure",
          description: "",
          fields: [
            {
              name: "misdemeanor_or_felony",
              label: "Have you ever been convicted of a misdemeanor or felony?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
            {
              name: "misdemeanor_or_felony_explanation",
              label: 'If "yes", please provide a brief explanation.',
              type: "textarea",
              show: {
                conditions: [{ name: "misdemeanor_or_felony", value: "Yes" }],
              },
              isRequired: {
                conditions: [{ name: "misdemeanor_or_felony", value: "Yes" }],
              },
            },
          ],
        },
        {
          title: "Optional: Employment History",
          description: "Let us know about your work experience.",
          nestedSectionTitle: "Job {{x}}",
          nestedSectionItemName: "Job",
          nestedSectionsMax: 2,
          fields: [
            { name: "job_{{x}}_employer", label: "Employer", type: "text" },
            { name: "job_{{x}}_type", label: "Job Title", type: "text" },
            { name: "job_{{x}}_from_date", label: "Start Date", type: "month" },
            {
              name: "job_{{x}}_to_date",
              label: "End Date",
              helperText: "Leave empty if still employed",
              type: "month",
            },
            {
              name: "job_{{x}}_hours_per_week",
              label: "Weekly Hours",
              helperText: "On average, how many hours did you work a week?",
              type: "number",
              validations: { value: { max: 40 } },
            },
          ],
        },
      ],
    },
    {
      title: "Program Options",
      id: "program-options",
      slug: "program-options",
      description: "",
      navTitle: "Program Options",
      sections: [
        {
          title: "Application Details",
          description: "",
          fields: [
            {
              name: "intended_major",
              label: "Intended Major",
              helperText: "Let us know if you have a major in mind.",
              type: "select",
              options: optionize([
                "Major 1",
                "Major 2",
                "Major 3",
                "Major 4",
                "Major 5",
                "Major 6",
                "Major 7",
                "Major 8",
              ]),
              isRequired: true,
            },
            {
              name: "student_app_type",
              label: "Admission Type",
              helperText:
                "Please identify any special considerations you would like for your application.",
              type: "radio",
              options: optionize([
                ["Early Action ([[Early Action Date]])", "Early Action"],
                [
                  "Early Decision ([[Early Decision Due Date]])",
                  "Early Decision",
                ],
                [
                  "Regular Admissions ([[Regular Admissions Due Date]])",
                  "Regular Admissions",
                ],
              ]),
              isRequired: true,
            },
            {
              name: "student_term",
              label: "Entering Term",
              type: "radio",
              options: optionize(["Fall 2023", "Spring 2024"]),
              isRequired: true,
            },
            {
              name: "student_applied_before",
              label: "Have you applied to Two Ocean University in the past?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
            {
              name: "student_housing",
              label: "Student Housing",
              type: "radio",
              options: optionize(["On-Campus", "Commuter"]),
              isRequired: true,
            },
          ],
        },
        {
          title: "Financial Aid",
          description:
            "There are many sources of financial assistance to help with the cost of college. We automatically consider all students for scholarships.",
          fields: [
            {
              name: "student_finaid",
              label: "Do you plan to apply for Financial Aid?",
              helperText:
                "Will you complete the Free Application for Federal Student Aid (FAFSA)? If so, our school code is [FAFSA Code].",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
          ],
        },
        {
          title: "Essay",
          description:
            "A writing sample can help us get to know you better and demonstrate your readiness for college level coursework.",
          fields: [
            {
              name: "essay_ready_to_submit",
              label: "Would you like to submit your essay?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
            {
              name: "essay_prompt",
              label: "Please pick your essay subject.",
              type: "select",
              options: optionize(["Topic 1", "Topic 2"]),
              show: {
                conditions: [{ name: "essay_ready_to_submit", value: "Yes" }],
              },
              isRequired: {
                conditions: [{ name: "essay_ready_to_submit", value: "Yes" }],
              },
            },
            {
              name: "essay_inline_upload",
              label: "Do you have your essay in a file you'd like to upload?",
              type: "radio",
              options: optionize([
                ["Yes, I have my essay written in a file.", "Yes"],
                ["No, I'll write my essay here.", "No"],
              ]),
              show: {
                conditions: [{ name: "essay_ready_to_submit", value: "Yes" }],
              },
              isRequired: {
                conditions: [{ name: "essay_ready_to_submit", value: "Yes" }],
              },
            },
            {
              name: "essay_file_upload",
              label: "Upload File",
              show: {
                conditions: [
                  { name: "essay_ready_to_submit", value: "Yes" },
                  { name: "essay_inline_upload", value: "Yes" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "essay_ready_to_submit", value: "Yes" },
                  { name: "essay_inline_upload", value: "Yes" },
                ],
              },
              type: "file",
            },
            {
              name: "essay",
              label: "Type your essay here, if you did not upload one above.",
              type: "textarea",
              show: {
                conditions: [
                  { name: "essay_ready_to_submit", value: "Yes" },
                  { name: "essay_inline_upload", value: "No" },
                ],
              },
              isRequired: {
                conditions: [
                  { name: "essay_ready_to_submit", value: "Yes" },
                  { name: "essay_inline_upload", value: "No" },
                ],
              },
            },
          ],
        },
      ],
    },
  ],
}
