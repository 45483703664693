import {
  BoxProps,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Button,
  useDisclosure,
  VStack,
  IconButton,
  Flex,
  IconButtonProps,
  HStack,
  Text,
  useToast,
  CardHeader,
  Card,
  CardBody,
} from "@chakra-ui/react"
import { College, COLLEGES } from "data"
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CopyIcon,
  DownloadIcon,
} from "@chakra-ui/icons"

const downloadCollegeData = (college: College) => {
  const fileName = `${college.name}.json`
  const jsonContent = JSON.stringify(college.data, null, 2)
  const blob = new Blob([jsonContent], { type: "application/json" })
  const url = URL.createObjectURL(blob)

  const a = document.createElement("a")
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(url)

  return fileName
}

const copyCollegeData = (college: College) => {
  const jsonContent = JSON.stringify(college.data, null, 2)
  navigator.clipboard.writeText(jsonContent)
}

export const DevToolsSidebar = ({ children }: BoxProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()

  const exclusions = ["twoOcean"]

  const collegeData = COLLEGES.filter(
    college => !exclusions.includes(college.name),
  ).sort((a, b) => a.name.localeCompare(b.name))

  const handleDownload = (college: College) => {
    const fileName = downloadCollegeData(college)
    toast({
      title: "File downloaded",
      description: `${fileName} has been downloaded to your default download folder.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    })
  }

  const handleCopy = (college: College) => {
    copyCollegeData(college)
    toast({
      title: "Copied to clipboard",
      description: `${college.name} JSON data has been copied to your clipboard.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    })
  }

  return (
    <>
      <DevToolsTrigger open onClick={onOpen} />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <Flex direction="column" height="100%">
            <DrawerHeader borderBottomWidth="1px">Dev Tools</DrawerHeader>
            <DrawerBody flex={1} padding={4}>
              <Card>
                <CardHeader>College Data</CardHeader>
                <CardBody>
                  <VStack spacing={0} align="stretch">
                    {collegeData.map(college => (
                      <Flex
                        key={college.name}
                        justifyContent="space-between"
                        alignItems="center"
                        borderTopWidth="1px"
                        paddingY={3}
                        _last={{
                          borderBottomWidth: "1px",
                        }}
                      >
                        <Text textTransform="capitalize" margin="0!">
                          {college.name} JSON
                        </Text>
                        <HStack spacing={2}>
                          <Button
                            leftIcon={<CopyIcon />}
                            aria-label={`Copy ${college.name} JSON`}
                            size="sm"
                            variant="ghost"
                            onClick={() => handleCopy(college)}
                            borderRadius={4}
                          >
                            Copy
                          </Button>
                          <Button
                            leftIcon={<DownloadIcon />}
                            aria-label={`Download ${college.name} JSON`}
                            size="sm"
                            variant="ghost"
                            onClick={() => handleDownload(college)}
                            borderRadius={4}
                          >
                            Download
                          </Button>
                        </HStack>
                      </Flex>
                    ))}
                  </VStack>
                </CardBody>
              </Card>

              {children}
            </DrawerBody>
            <DevToolsTrigger close onClick={onClose} />
          </Flex>
        </DrawerContent>
      </Drawer>
    </>
  )
}

interface DevToolsTriggerProps extends Omit<IconButtonProps, "aria-label"> {
  open?: boolean
  close?: boolean
}
const DevToolsTrigger = ({ open, close, ...props }: DevToolsTriggerProps) => {
  const commonProps: IconButtonProps = {
    "aria-label": open ? "Open Dev Tools" : "Close Dev Tools",
    icon: open ? <ArrowRightIcon /> : <ArrowLeftIcon />,
    variant: "ghost",
    colorScheme: "teal",
    zIndex: 100,
    minHeight: 12,
    minWidth: 6,
    borderEndRadius: 2,
    boxShadow: "none!",
    top: 2,
    title: props["aria-label"],
    transition: "transform 0.2s",
    _hover: {
      transform: open ? "translateX(4px)" : null,
    },
    ...props,
  }

  const positionProps = open
    ? { left: -1, position: "fixed" as const }
    : { right: -6, position: "absolute" as const }

  return <IconButton aria-label="Foo" {...commonProps} {...positionProps} />
}
