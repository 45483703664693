// conditions.ts
import { uniq } from "lodash"
import {
  FieldCondition,
  FieldAttributes,
  SectionData,
  ConditionSet,
  DateCondition,
} from "types"
import { isEmptyEquals, getValueOrNestedValue } from "./helpers"
/**
 * Determine if a field should be shown or hidden. By default, it should be shown
 *
 * @param fieldData The JSON representing the field
 */
export const determineShowField = (
  fieldData: FieldAttributes | SectionData,
  formValues?: { [key: string]: any },
  nestedIndex?: number,
  isFinalCheck?: boolean,
  isMultipleSection?: boolean,
): boolean => {
  // Check for boolean truthiness first
  if (fieldData.show !== undefined && typeof fieldData.show === "boolean") {
    return fieldData.show
  }

  if (fieldData.hide !== undefined && typeof fieldData.hide === "boolean") {
    return !fieldData.hide
  }

  const showConditionSet = fieldData.show as ConditionSet
  const hideConditionSet = fieldData.hide as ConditionSet

  // Check condition set truthiness
  if (hideConditionSet?.conditions?.length > 0) {
    // If the hide condition set evaluates to true, then the field should be hidden (return false)
    return !isConditionSetMet(
      hideConditionSet,
      formValues,
      nestedIndex,
      isFinalCheck,
      isMultipleSection,
    )
  }
  if (showConditionSet?.conditions?.length > 0) {
    // If the show condition set evaluates to true, the the field should be shown (return true)
    return isConditionSetMet(
      showConditionSet,
      formValues,
      nestedIndex,
      isFinalCheck,
      isMultipleSection,
    )
  }
  // Show the field by default
  return true
}

export const determineIsRequired = (
  fieldData: FieldAttributes,
  formValues?: { [key: string]: any },
  nestedIndex?: number,
  isFinalCheck?: boolean,
  isMultipleSection?: boolean,
): boolean => {
  if (fieldData.isRequired === undefined) {
    return false
  }

  if (typeof fieldData.isRequired === "boolean") {
    return fieldData.isRequired
  }

  const requiredConditionSet = fieldData.isRequired as ConditionSet
  if (requiredConditionSet.conditions?.length > 0) {
    // If the required condition set evaluates to true, the the field should be required (return true)
    return isConditionSetMet(
      requiredConditionSet,
      formValues,
      nestedIndex,
      isFinalCheck,
      isMultipleSection,
    )
  }
  // Show the field by default
  return false
}

export const determineShouldPrefill = (
  fieldData: FieldAttributes,
  hasPrefilled: boolean,
  formValues?: { [key: string]: any },
  nestedIndex?: number,
): { shouldPrefill: boolean; prefillValue: any; shouldClear: boolean } => {
  // Prefill a value if all conditions are met
  // Conditions:
  // 1. The field has a prefill
  // 2. The current value is empty (null/undefined) and you haven't already prefilled OR override is true
  // 3. The current value is not already equal to the override
  // 4. The prefill conditionSet is met
  const prefills = fieldData.prefill

  if (!prefills) {
    return { shouldPrefill: false, prefillValue: null, shouldClear: false }
  }

  const fieldValue = formValues[fieldData.name]

  const fieldHasNoValue =
    [null, undefined, ""].includes(fieldValue) && !hasPrefilled

  const prefillContainsOverride = prefills.some(p => p.override)

  const valueMayBeUpdated = fieldHasNoValue || prefillContainsOverride

  const possibleValues = prefills.map(p => getValueOrNestedValue(p.value))

  const hasNewValue = !possibleValues.every(v => v === fieldValue)

  const fieldConditions = prefills.map(p =>
    isConditionSetMet(p.conditionSet, formValues, nestedIndex),
  )

  const hasMetConditions = fieldConditions.some(c => c === true)

  const shouldPrefill = valueMayBeUpdated && hasNewValue && hasMetConditions

  const shouldClear = !fieldHasNoValue && !hasMetConditions

  return {
    shouldPrefill: !!shouldPrefill,
    prefillValue: shouldPrefill ? prefills[0].value : null,
    shouldClear,
  }
}

export const isConditionSetMet = (
  conditionSet?: ConditionSet,
  formValues?: { [key: string]: any },
  nestedIndex?: number,
  isFinalCheck?: boolean,
  isMultipleSection?: boolean,
) => {
  if (!conditionSet) {
    return true
  }

  const conditionsTruthiness = conditionSet.conditions.map(condition => {
    return isConditionMet(
      condition,
      formValues,
      nestedIndex,
      isFinalCheck,
      isMultipleSection,
    )
  })

  return parseLogicFromTruthValues(
    conditionsTruthiness,
    conditionSet.logic || "",
    conditionSet.logicalOr || false,
  )
}

export const isConditionMet = (
  condition: FieldCondition,
  formValues: { [key: string]: any },
  nestedIndex?: number,
  isFinalCheck?: boolean,
  isMultipleSection?: boolean,
): boolean => {
  let fieldName = condition.name

  if (nestedIndex !== undefined) {
    // If there is a nested index, the field you're looking for uses index instead of {{x}}
    fieldName =
      isFinalCheck && isMultipleSection
        ? fieldName.replace("_", `_${nestedIndex + 1}_`)
        : fieldName
  }

  const formValue = formValues[fieldName] ?? null

  // Checkboxes return an array of values
  const isArrayIncludesCondition =
    Array.isArray(formValue) &&
    formValue.includes(condition.value || condition.notValue)

  const currentValue = getValueOrNestedValue(formValue)

  if (condition.date) {
    return isDateConditionMet(condition.date, currentValue)
  }

  if (condition.value !== undefined) {
    if (isArrayIncludesCondition) {
      return true
    }

    return isEmptyEquals(currentValue, condition.value)
  }

  if (condition.notValue !== undefined) {
    if (isArrayIncludesCondition) {
      return false
    }
    // In a notValue scenario, empty could mean "" or null or undefined
    return !isEmptyEquals(currentValue, condition.notValue)
  }

  // No conditions to test
  return true
}

export const isDateConditionMet = (
  dateCondition: DateCondition,
  currentValue: string,
): boolean => {
  if (
    currentValue === null ||
    currentValue === undefined ||
    currentValue === ""
  ) {
    return false
  }

  const parseDate = (dateString: string): Date | null => {
    // First, try parsing as-is
    let date = new Date(dateString)
    if (!isNaN(date.getTime())) {
      return date
    }

    // If that fails, try to parse common date formats
    const formats = [
      /^(\d{1,2})[-/](\d{1,2})[-/](\d{4})$/, // DD-MM-YYYY or MM-DD-YYYY
      /^(\d{4})[-/](\d{1,2})[-/](\d{1,2})$/, // YYYY-MM-DD
    ]

    for (const format of formats) {
      const match = format.exec(dateString)
      if (match) {
        // Assume American format (MM-DD-YYYY) if day and month are ambiguous
        const [, first, second, year] = match
        date = new Date(
          `${year}-${first.padStart(2, "0")}-${second.padStart(2, "0")}`,
        )
        if (!isNaN(date.getTime())) {
          return date
        }
      }
    }
    // If all parsing attempts fail, return null
    return null
  }

  const currentDate = parseDate(currentValue)

  if (currentDate === null) {
    // Only warn if the input looks like it should be a complete date
    if (currentValue.replace(/\D/g, "").length >= 8) {
      console.warn("Unable to parse date input:", currentValue)
    }
    return false
  }

  if (dateCondition.before) {
    const beforeDate = parseDate(dateCondition.before)
    if (beforeDate === null) {
      console.warn("Invalid before date:", dateCondition.before)
      return false
    }
    return currentDate < beforeDate
  }

  if (dateCondition.after) {
    const afterDate = parseDate(dateCondition.after)
    if (afterDate === null) {
      console.warn("Invalid after date:", dateCondition.after)
      return false
    }
    return currentDate > afterDate
  }

  if (dateCondition.equals) {
    const equalsDate = parseDate(dateCondition.equals)
    if (equalsDate === null) {
      console.warn("Invalid equals date:", dateCondition.equals)
      return false
    }
    return currentDate.getTime() === equalsDate.getTime()
  }

  return false
}

export const getConditionalFieldsToWatch = (
  data: FieldAttributes | SectionData,
  name?: string,
  nestedIndex?: number,
): string[] => {
  // Always watch your own field
  let fieldsToWatch = name ? [name] : []

  if (data.hide !== undefined && typeof data.hide !== "boolean") {
    const hideConditionSet = data.hide as ConditionSet

    fieldsToWatch = [
      ...fieldsToWatch,
      ...hideConditionSet.conditions.map(condition =>
        condition.name.replaceAll(
          "{{x}}",
          nestedIndex !== undefined ? `${nestedIndex + 1}` : "{{x}}",
        ),
      ),
    ]
  }
  if (data.show !== undefined && typeof data.show !== "boolean") {
    const showConditionSet = data.show as ConditionSet
    fieldsToWatch = [
      ...fieldsToWatch,
      ...showConditionSet.conditions.map(condition =>
        condition.name.replaceAll(
          "{{x}}",
          nestedIndex !== undefined ? `${nestedIndex + 1}` : "{{x}}",
        ),
      ),
    ]
  }

  // Type cast to check for required field
  // @ts-ignore
  if (data.isRequired !== undefined) {
    const dataField = data as FieldAttributes
    if (
      dataField.isRequired !== undefined &&
      typeof dataField.isRequired !== "boolean"
    ) {
      const requiredConditions = dataField.isRequired as ConditionSet
      fieldsToWatch = [
        ...fieldsToWatch,
        ...requiredConditions.conditions.map(condition =>
          condition.name.replaceAll(
            "{{x}}",
            nestedIndex !== undefined ? `${nestedIndex + 1}` : "{{x}}",
          ),
        ),
      ]
    }
  }

  // Look for fields that the prefill value might depend on
  // @ts-ignore
  if (data.prefill !== undefined) {
    const dataField = data as FieldAttributes
    const requiredConditions = dataField.prefill[0].conditionSet
    if (requiredConditions) {
      fieldsToWatch = [
        ...fieldsToWatch,
        ...requiredConditions.conditions.map(condition =>
          condition.name.replaceAll(
            "{{x}}",
            nestedIndex !== undefined ? `${nestedIndex + 1}` : "{{x}}",
          ),
        ),
      ]
    }
  }

  return uniq(fieldsToWatch)
}

export const parseLogicFromTruthValues = (
  truthValues: boolean[],
  logic: string,
  logicalOr?: boolean,
): boolean => {
  if (truthValues.length === 0) {
    // Without values, assume true
    return true
  }
  if (truthValues.length === 1) {
    // With a single values, the truth of the set is the truth of the value
    return truthValues[0]
  }

  if (!logic) {
    // If no custom logic...
    return logicalOr
      ? // If using logicalOr, return true if ANY condition is true
        truthValues.some(value => !!value)
      : // Else, whether or not ALL conditions are true
        !truthValues.some(value => !value)
  }

  let logicStr = logic

  for (let i = truthValues.length - 1; i >= 0; i--) {
    // Convert each number in the logic string into its truth array equivalent.
    // E.G Convert the number 2 into the value at truthValues[2].
    // [false, true] --> 0&&1 --> (false)&&(true)
    logicStr = logicStr.replaceAll(`${i}`, `(${truthValues[i]})`)
  }

  // This throws a console warning because it thinks we're being dumb but this is necessary
  // eslint-disable-next-line
  const logicFunction = new Function(`return ${logicStr}`)

  return logicFunction()
}
