import React, { useEffect, useRef, useState } from "react"
import { Box, Flex, Text, useStyleConfig } from "@chakra-ui/react"
import { SiteHeader, PageHeader, Main } from "components/LayoutComponents"
import { Nav } from "components/Nav"
import { H1 } from "components/common"
import { SiteLogo } from "components/SiteLogo"
import { useMaxWidth } from "hooks"
import { FullWidthFooter } from "components/FullWidthFooter"
import { LoadingScreen } from "./LoadingScreen"
import { motion, AnimatePresence } from "framer-motion"
import { useLocation } from "react-router-dom"

const PageContent = ({ children }) => (
  <AnimatePresence mode="wait">
    <motion.div
      className="page-content"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0 } }}
      transition={{ duration: 0.2, delay: 0.1 }}
    >
      {children}
    </motion.div>
  </AnimatePresence>
)

export const Layout1 = (props: any) => {
  const {
    applicationData,
    children,
    currentPage,
    layout,
    mainRef,
    navRef,
    progressBarHeight,
    showLoadingScreen,
  } = props

  const { isMaxTabletView } = useMaxWidth()
  const location = useLocation()
  const siteHeaderRef = useRef(null)
  const [siteHeaderHeight, setSiteHeaderHeight] = useState(0)

  const isCenteredLayout = layout.version === "centered"
  const isCenterAligned = isMaxTabletView || isCenteredLayout
  const centeredStyles = useStyleConfig("LayoutCentered")

  const baseStyles = {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "full",
  }

  const styles = isCenteredLayout
    ? { ...baseStyles, ...centeredStyles }
    : baseStyles

  useEffect(() => {
    if (siteHeaderRef.current) {
      setSiteHeaderHeight(siteHeaderRef.current.offsetHeight)
    }
  }, [])

  return (
    <Box __css={styles}>
      <SiteHeader ref={siteHeaderRef}>
        <SiteLogo
          alt={applicationData.meta.title}
          src={applicationData.meta.logo}
        />
      </SiteHeader>

      {showLoadingScreen ? (
        <LoadingScreen
          data-testid="layout-1_loading-screen"
          height="full"
          as={motion.div}
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.5 }}
        />
      ) : (
        <Flex
          as={isCenteredLayout ? motion.main : motion.div}
          alignItems={isCenterAligned ? null : "flex-start"}
          flexDirection={isCenterAligned ? "column" : "row"}
          maxWidth="6xl"
          width="full"
          paddingX={{ md: 4 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {isCenteredLayout ? (
            <PageContent key={location.pathname}>{children}</PageContent>
          ) : (
            <>
              <Nav
                ref={navRef}
                flex={isMaxTabletView ? null : "0 1 27%"}
                pages={applicationData.pages}
              />

              <Main
                ref={mainRef}
                flex="1"
                overflow="auto"
                minHeight={`calc(100vh - ${siteHeaderHeight}px)`}
              >
                <PageContent key={location.pathname}>
                  <PageHeader>
                    <H1>{currentPage.title}</H1>
                    {currentPage.description && (
                      <Text>{currentPage.description}</Text>
                    )}
                  </PageHeader>
                  {children}
                </PageContent>
              </Main>
            </>
          )}
        </Flex>
      )}
      <FullWidthFooter
        mb={progressBarHeight}
        applicationData={applicationData}
      />
    </Box>
  )
}
