import { assetsUrl } from "data/colleges/hollins/hollins"
import { colors } from "themes/hollins/colors"
import { appComponents } from "themes/hollins/components"

const MD_MAIN_COL = 953
const MD_CANVAS = 1440
const LG_MAIN_COL = 1080
const LG_CANVAS = 1920

const AUTH_MAIN_SM_WIDTH = `${(MD_MAIN_COL / MD_CANVAS) * 100}%`
const AUTH_MAIN_LG_WIDTH = `${(LG_MAIN_COL / LG_CANVAS) * 100}%`
const AUTH_SIDEBAR_SM_WIDTH = `${((MD_CANVAS - MD_MAIN_COL) / MD_CANVAS) * 100}%`
const AUTH_SIDEBAR_LG_WIDTH = `${((LG_CANVAS - LG_MAIN_COL) / LG_CANVAS) * 100}%`

const TWO_COL_AUTH_BREAKPOINT = "lg"
const TWO_COL_AUTH_WIDE_BREAKPOINT = "2xl"

const heroImage = assetsUrl + "20240613172549_ca-hollins-hero.jpg"
const heroBgImage = assetsUrl + "20240613172549_ca-hollins-paper.png"
const heroBelieveImage = assetsUrl + "20240613172548_ca-hollins-believe.png"

const AuthPage = {
  baseStyle: {
    background: {
      [TWO_COL_AUTH_BREAKPOINT]: `linear-gradient(90deg, ${colors.white} 0%, ${colors.white} ${AUTH_MAIN_SM_WIDTH}, ${colors.accent[10]} ${AUTH_MAIN_SM_WIDTH})`,
      [TWO_COL_AUTH_WIDE_BREAKPOINT]: `linear-gradient(90deg, ${colors.white} 0%, ${colors.white} ${AUTH_MAIN_LG_WIDTH}, ${colors.accent[10]} ${AUTH_MAIN_LG_WIDTH})`,
    },
    display: "flex",
    flexDirection: "column",
    gap: {
      base: 5,
      [TWO_COL_AUTH_BREAKPOINT]: 0,
    },
    justifyContent: {
      [TWO_COL_AUTH_BREAKPOINT]: "center",
    },
    maxWidth: "none",
    paddingX: "0!important",
    paddingTop: {
      base: 5,
      md: 7,
      [TWO_COL_AUTH_BREAKPOINT]: 0,
    },

    "> [class*='wrapper']": {
      display: "flex",
      justifyContent: {
        base: "center",
        [TWO_COL_AUTH_BREAKPOINT]: "flex-start",
      },

      marginX: {
        base: "auto",
        [TWO_COL_AUTH_BREAKPOINT]: 0,
      },
      maxWidth: {
        base: "37.5rem",
        [TWO_COL_AUTH_BREAKPOINT]: "none",
      },
      width: {
        base: "100%",
        [TWO_COL_AUTH_BREAKPOINT]: "auto",
      },
      paddingX: {
        base: 5,
        md: 0,
      },

      "&.logo-wrapper": {
        marginBottom: {
          base: 5,
          md: 4,
          [TWO_COL_AUTH_BREAKPOINT]: "4.625rem",
        },
      },

      "&.outer-form-wrapper": {
        gap: {
          md: 5,
        },
        marginY: {
          md: 5,
          [TWO_COL_AUTH_BREAKPOINT]: 0,
        },
      },

      "&.copy-wrapper": {
        display: {
          [TWO_COL_AUTH_BREAKPOINT]: "flex",
        },
        fontSize: {
          base: "lg",
          [TWO_COL_AUTH_BREAKPOINT]: "md",
        },
        lineHeight: {
          base: 1.55556,
          [TWO_COL_AUTH_BREAKPOINT]: 1.5,
        },
        marginBottom: {
          base: 5,
          md: "3.25rem",
          [TWO_COL_AUTH_BREAKPOINT]: 0,
        },
      },
    },

    ".desktop-left": {
      justifyContent: {
        [TWO_COL_AUTH_BREAKPOINT]: "center",
      },
      marginRight: {
        [TWO_COL_AUTH_BREAKPOINT]: AUTH_SIDEBAR_SM_WIDTH,
        [TWO_COL_AUTH_WIDE_BREAKPOINT]: AUTH_SIDEBAR_LG_WIDTH,
      },

      "> div": {
        position: "relative",
        width: "100%",
        maxWidth: "52.125rem", // 843px
        left: {
          [TWO_COL_AUTH_BREAKPOINT]: `calc((13 * 100vw) / ${MD_CANVAS})`,
          [TWO_COL_AUTH_WIDE_BREAKPOINT]: `calc((17 * 100vw) / ${LG_CANVAS})`,
        },
      },
    },

    ".desktop-right": {
      position: {
        [TWO_COL_AUTH_BREAKPOINT]: "absolute",
      },
      alignSelf: {
        [TWO_COL_AUTH_BREAKPOINT]: "flex-end",
      },
      width: {
        [TWO_COL_AUTH_BREAKPOINT]: AUTH_SIDEBAR_SM_WIDTH,
        [TWO_COL_AUTH_WIDE_BREAKPOINT]: AUTH_SIDEBAR_LG_WIDTH,
      },

      "&#auth-footer": {
        backgroundColor: {
          [TWO_COL_AUTH_BREAKPOINT]: "transparent",
        },
        borderTop: {
          [TWO_COL_AUTH_BREAKPOINT]: "none",
        },
        bottom: {
          [TWO_COL_AUTH_BREAKPOINT]: 0,
        },
        paddingTop: {
          base: 5,
          md: 5,
          [TWO_COL_AUTH_BREAKPOINT]: 0,
        },
        paddingBottom: {
          base: 6,
          md: 6,
          [TWO_COL_AUTH_BREAKPOINT]: 10,
        },
        zIndex: {
          [TWO_COL_AUTH_BREAKPOINT]: 0,
        },
      },

      ".footer-container": {
        alignItems: {
          base: "center",
        },
        gap: {
          [TWO_COL_AUTH_BREAKPOINT]: 2,
        },
        marginX: {
          base: "auto",
        }
      },
    },

    ".logo": {
      height: "auto",
      width: {
        base: "155px",
        [TWO_COL_AUTH_BREAKPOINT]: "200px",
      },
      marginX: {
        base: "auto",
        [TWO_COL_AUTH_BREAKPOINT]: 0,
      },
    },

    "#auth-page-heading": {
      h1: {
        color: "bodyColor",
        fontSize: {
          md: "2.5rem",
          [TWO_COL_AUTH_BREAKPOINT]: "4xl",
        },
        lineHeight: {
          base: 1.286,
          md: 1.2,
          [TWO_COL_AUTH_BREAKPOINT]: 1.22222,
        },
        marginBottom: 0,
        textAlign: {
          base: "center",
          [TWO_COL_AUTH_BREAKPOINT]: "left",
        },

        "&.sign-up-heading:after": {
          [TWO_COL_AUTH_BREAKPOINT]: {
            content: "''",
            display: "block",
            width: "5.25rem",
            height: 2,
            background: colors.accent[60],
            marginTop: 7,
            marginBottom: 8,
          },
        },
      },
    },

    "#auth-page-copy > div": {
      display: "flex",
      fontSize: {
        base: "lg",
        [TWO_COL_AUTH_BREAKPOINT]: "md",
      },
      lineHeight: {
        base: 1.55556,
        [TWO_COL_AUTH_BREAKPOINT]: 1.5,
      },

      "& section": {
        flex: 1,
      },

      figure: {
        display: {
          base: "none",
          [TWO_COL_AUTH_BREAKPOINT]: "flex",
        },
        justifyContent: "end",
        position: "relative",

        "&.sign-up-image": {
          marginLeft: "2.125rem",
          maxWidth: "30rem",
          flexBasis: "52.3%", // == 430px @ 1440px design preview

          "&:before": {
            content: "''",
            display: "block",
            // height divided by width of Figma container to create aspect ratio
            paddingBottom: "calc(100% * 410 / 430)",
          },

          "#hero-background": {
            display: "flex",
            alignItems: "center",
            position: "relative",
            top: "-40px",
            width: "100%",
            background: `url(${heroBgImage})`,
            backgroundPosition: "left 98px top",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",

            "&:after": {
              content: "''",
              position: "absolute",
              right: 0,
              bottom: "-12px",
              width: "120.553px",
              height: "47.532px",
              background: `url(${heroBelieveImage}) center center no-repeat`,
              backgroundSize: "95%",
              transform: "rotate(-16.157deg)",
              filter: "opacity(0.65)",
            },
          },

          "#hero-image": {
            display: "flex",
            width: "calc(100% * 368 / 430)",
            height: "calc(100% * 330 / 410)",
            background: `url(${heroImage})`,
            backgroundPosition: "right 20% center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            position: "relative",
            boxShadow: "md",
          },
        },

        "&.sign-in-image": {
          display: {
            lg: "flex",
          },
          flexBasis: "100%",
          marginTop: 9,
          maxWidth: "597px",

          "&:before": {
            content: "''",
            display: "block",
            // height divided by width of Figma container to create aspect ratio
            paddingBottom: "calc(100% * 419 / 597)",
          },

          "#hero-background": {
            display: "flex",
            alignItems: "center",
            position: "relative",
            top: "-40px",
            width: "100%",
            background: `url(${heroBgImage})`,
            backgroundPosition: "left 49px top",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",

            "&:after": {
              content: "''",
              position: "absolute",
              right: 0,
              bottom: "-24px",
              width: "164.76px",
              height: "64.96px",
              background: `url(${heroBelieveImage}) center center no-repeat`,
              backgroundSize: "95%",
              transform: "rotate(-16.157deg)",
              filter: "opacity(0.65)",
            },
          },

          "#hero-image": {
            display: "flex",
            width: "calc(100% * 525 / 597)",
            height: "calc(100% * 330 / 420)",
            background: `url(${heroImage})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            position: "relative",
            boxShadow: "md",
          },
        },
      },
    },

    "#emailVerificationControl_but_change_claims": {
      display: "none!important"
    },

    "#auth-form-container": {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      alignItems: "center",

      "#cancel": {
        display: "none",
      },

      "&.forgot-password": {
        "#attributeList + .buttons": {
          "#cancel": {
            ...appComponents.Button.baseStyle,
            ...appComponents.Button.sizes.md,
            ...appComponents.Button.variants.link,
            display: "block",
          },
        },
      },

      "#auth-form": {
        background: {
          base: colors.accent[10],
          [TWO_COL_AUTH_BREAKPOINT]: "none",
        },
        display: "flex",
        flexDirection: "column",
        gap: 5,
        maxWidth: {
          [TWO_COL_AUTH_WIDE_BREAKPOINT]: "35rem",
        },
        paddingX: {
          base: 5,
          md: "3.75rem",
          [TWO_COL_AUTH_BREAKPOINT]: "4rem",
          [TWO_COL_AUTH_WIDE_BREAKPOINT]: 0,
        },
        paddingY: {
          base: 5,
          md: 6,
          [TWO_COL_AUTH_BREAKPOINT]: 0,
        },
        width: "100%",
      },

      "#auth-form-heading": {
        color: colors.accent[90],
        fontFamily: "heading",
        fontSize: {
          base: "1.25rem",
          md: "1.375rem",
          [TWO_COL_AUTH_BREAKPOINT]: "1.375rem",
        },
        fontWeight: "bold",
        lineHeight: {
          base: 1.2,
          md: 1.09091,
          [TWO_COL_AUTH_BREAKPOINT]: 1.1,
        },
        marginBottom: {
          base: "unset",
        },
      },

      "#auth-form-subtext": {
        color: "bodyColor",
        lineHeight: {
          base: 1.375,
          [TWO_COL_AUTH_BREAKPOINT]: 1.57143,
        },
        fontSize: {
          base: "md",
          [TWO_COL_AUTH_BREAKPOINT]: "sm",
        },

        a: {
          fontWeight: "semibold",
        },
      },
    },
  },
}

/** Overrides of ADB2C form */
const AuthFormWrapper = {
  baseStyle: {
    background: "unset",
    borderRadius: "unset",
    boxShadow: "unset",
    padding: "unset",

    "#api": {
      marginBottom: "unset",

      // Sign Up, Sign In, & Forgot Password input groups
      "#attributeList.attr li + li, #localAccountForm .entry-item + .entry-item,#emailVerificationControl li + li .attrEntry":
        {
          marginTop: 3,
        },

      input: {
        ...appComponents.Input.baseStyle.field,
        ...appComponents.Input.variants.outline.field,
        fontSize: {
          base: "sm",
          md: "md",
        },
        lineHeight: {
          base: 1.57143,
          md: 1.5,
        },
      },
      button: {
        ...appComponents.Button.baseStyle,
        ...appComponents.Button.sizes.md,
        ...appComponents.Button.variants.solid,
        flexBasis: "auto",
        fontSize: {
          base: "lg",
          [TWO_COL_AUTH_BREAKPOINT]: "md",
        },
        lineHeight: {
          base: 1.55556,
          [TWO_COL_AUTH_BREAKPOINT]: 1.5,
        },
        paddingX: {
          base: 5,
        },
        paddingY: {
          base: 3,
        },
        width: {
          base: "100%",
          md: "83.333%",
          [TWO_COL_AUTH_BREAKPOINT]: "72.222%",
        },

        marginY: {
          base: 5,
          [TWO_COL_AUTH_BREAKPOINT]: 6,
        },
      },
      ul /*labels */: {
        listStyle: "none!",
        margin: "0!",
      },
      "ul li, label": {
        ...appComponents.FormLabel.baseStyle,
      },
      label: {
        lineHeight: {
          base: 1.57143,
          md: 1.5,
        },
        marginBottom: {
          base: 1,
        },
        textAlign: "left",
      },

      ".password-label": {
        alignItems: "baseline",
        display: "flex",
        justifyContent: "space-between",
        fontSize: { ...appComponents.FormLabel.baseStyle.fontSize },

        a: {
          fontSize: "xs",
        },
      },
    },
  },
}

export const authComponents = {
  AuthFormWrapper,
  AuthPage,
}
