import { alertAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

export const Button = {
  baseStyle: {
    borderRadius: "md",
    boxShadow: "02",
    fontWeight: "bold",
    lineHeight: "button",
    _hover: {
      boxShadow: "02-light",
      _disabled: {
        boxShadow: "none",
      },
    },
    _active: {
      boxShadow: "08",
    },
  },
  sizes: {
    xs: {
      height: "auto",
      paddingX: 2,
      paddingY: 1,
    },
    sm: {
      height: "auto",
      paddingX: 3,
      paddingY: 2,
    },
    md: {
      fontSize: "sm",
      height: "auto",
      paddingX: 4,
      paddingY: "10px",
    },
    lg: {
      fontSize: "md",
      height: "auto",
      lineHeight: 1,
      paddingX: 5,
      paddingY: 4,
    },
    xl: {
      fontSize: "md",
      height: "auto",
      paddingX: 8,
      paddingY: 5,
    },
  },
  variants: {
    solid: {
      background: "buttonLinkColor",
      color: "white",
      _hover: {
        background: "buttonLinkHoverColor",
        _disabled: {
          background: "buttonLinkColor",
        },
      },
      _active: {
        background: "buttonLinkColor",
      },
    },
    outline: {
      border: "2px",
      backgroundColor: "white",
      borderColor: "buttonLinkColor",
      color: "buttonLinkColor",
      _hover: {
        backgroundColor: "white",
        borderColor: "buttonLinkHoverColor",
        color: "buttonLinkHoverColor",
        _disabled: {
          borderColor: "buttonLinkColor",
          color: "buttonLinkColor",
        },
      },
      _active: {
        background: "white",
        color: "buttonLinkColor",
      },
    },
    ghost: {
      background: "white",
      borderColor: "transparent",
      color: "buttonLinkColor",
      _hover: {
        backgroundColor: "white",
        color: "buttonLinkHoverColor",
        _disabled: {
          color: "buttonLinkColor",
        },
      },
      _active: {
        color: "buttonLinkColor",
      },
    },
    link: {
      boxShadow: "none",
      color: "buttonLinkColor",
      _hover: {
        color: "buttonLinkHoverColor",
        boxShadow: "none",
        _disabled: {
          color: "buttonLinkColor",
        },
      },
      _active: {
        boxShadow: "none",
        color: "buttonLinkColor",
      },
    },
  },
}

const Checkbox = {
  parts: ["control", "icon", "label"],
  baseStyle: {
    control: {
      background: "formInputBackgroundColor",
      border: "1px",
      borderColor: "formInputBorderColor",
      borderRadius: "sm",
      _hover: {
        borderColor: "formInputHoveredBorderColor",
      },
      _checked: {
        background: "checkedBoxMarkColor",
        borderColor: "checkedBoxMarkColor",
        boxShadow: "none",
        _hover: {
          background: "checkedBoxHoverColor",
          borderColor: "checkedBoxHoverColor",
        },
        _focus: {
          _hover: {
            background: "checkedBoxMarkColor",
            borderColor: "checkedBoxMarkColor",
          },
        },
      },
      _disabled: {
        background: "disabledBackground",
        borderColor: "disabledBorder",
        opacity: "1",
        _hover: {
          background: "checkedBoxHoverColor",
          borderColor: "disabledBorder",
        },
        _checked: {
          background: "disabledCheckedBackground",
          border: "none",
        },
      },
    },
  },
  sizes: {
    sm: {
      control: {
        height: 6,
        width: 6,
        borderRadius: "base",
      },
      icon: {
        fontSize: "0.675rem",
        color: "white",
      },
      label: {
        fontSize: "sm",
        _checked: {
          fontWeight: "600",
        },
      },
    },
    md: {
      control: {
        height: 8,
        width: 8,
      },
      icon: {
        fontSize: "0.795rem",
      },
      label: {
        fontSize: "sm",
      },
    },
    lg: {
      control: {
        height: 10,
        width: 10,
      },
      icon: {
        fontSize: "0.915rem",
      },
      label: {
        fontSize: "md",
      },
    },
  },
  defaultProps: {
    size: "lg",
  },
}

const contentMaxWidth = "72rem" // Chakra UI "6xl"
const minPadding = "0.5rem"
const maxPadding = "1.5rem" // Chakra UI "6"

const ContentWrapper = {
  baseStyle: {
    maxWidth: contentMaxWidth,
    width: "full",
    paddingX: { 
      base: 4, 
      md: `clamp(${minPadding}, 
          calc((100vw - ${contentMaxWidth}) / 2), 
          ${maxPadding})`
    },
    marginX: "auto",
  },
}

const FinalCheckCard = {
  baseStyle: {
    backgroundColor: { md: "pageBgColorShade1" },
    borderRadius: { md: "2xl" },
    boxShadow: { md: "default" },
    borderTop: "none",
    borderBottom: "2px solid",
    borderBottomColor: "borderColor",
    _focusWithin: { boxShadow: "outline" },
  },

  "& .chakra-collapse": {
    overflow: "visible!important",
  },
}

const FinalCheckCardHeader = {
  baseStyle: {
    alignItems: "start",
    borderRadius: { md: "2xl" },
    paddingLeft: { base: 4, md: 6 },
    paddingRight: { base: 0, md: 3 },
    paddingY: 8,
    width: "full",
    _focus: {
      boxShadow: null,
    },
    _expanded: {
      borderBottomRadius: 0,
    },
    "& .card-header-eyebrow": {
      fontWeight: "bold",
      lineHeight: "1.43",
      fontSize: "sm",
      color: "headingColor",
    },
  },
}

const FormLabel = {
  baseStyle: {
    color: "formLabelColor",
    fontWeight: "bold",
    fontSize: { base: "bodyMobile", md: "bodyDesktop" },
    lineHeight: "base",
  },
}

const headingSharedStyles = {
  color: "headingColor",
  fontFamily: "heading",
  fontWeight: "bold",
}

const Headings = {
  H1: {
    baseStyle: {
      ...headingSharedStyles,
      fontSize: {
        base: "h1Mobile",
        md: "h1Desktop",
      },
      lineHeight: "h1",
    },
  },

  H2: {
    baseStyle: {
      ...headingSharedStyles,
      fontSize: {
        base: "h2Mobile",
        md: "h2Desktop",
      },
      lineHeight: "h2",
    },
  },

  H3: {
    baseStyle: {
      ...headingSharedStyles,
      fontSize: {
        base: "h3Mobile",
        md: "h3Desktop",
      },
      lineHeight: "h3",
    },
  },
  H4: {
    baseStyle: {
      ...headingSharedStyles,
    },
  },
  H5: {
    baseStyle: {
      ...headingSharedStyles,
    },
  },
  H6: {
    baseStyle: {
      ...headingSharedStyles,
    },
  },
}

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys)

export const Alert = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    container: {
      background: "white",
      borderRadius: "lg",
      padding: 8,
    },
    title: {
      ...headingSharedStyles,
      fontWeight: 600,
      fontSize: {
        base: "alertTitleMobile",
        sm: "alertTitleSm",
        md: "alertTitleDesktop",
      },
      lineHeight: {
        base: "alertTitleMobile",
        sm: "alertTitleSm",
        md: "alertTitleDesktop",
      },
    },
    description: {
      lineHeight: {
        base: 1.5,
        md: 1.55556,
      },
    },
  }),
  defaultProps: {
    variant: "subtle",
  },
})

const Input = {
  baseStyle: {
    field: {
      color: "formInputTextColor",
      fontWeight: "normal",
      flex: 1,
    },
  },
  sizes: {
    // TODO: Figure out why child themes don't over-ride these
    xs: {
      fontSize: "sm",
      height: 9,
      borderRadius: "base",
    },
    sm: {
      field: {
        fontSize: "sm",
        height: 10,
        borderRadius: "lg",
      },
    },
    md: {
      field: {
        fontSize: "md",
        height: 12,
        borderRadius: "lg",
      },
    },
    lg: {
      field: {
        fontSize: "md",
        height: 14,
        borderRadius: "lg",
      },
    },
  },
  variants: {
    outline: {
      field: {
        background: "formInputBackgroundColor",
        borderColor: "formInputBorderColor",
        _hover: {
          borderColor: "formInputHoveredBorderColor",
        },
        _disabled: {
          background: "disabledBackground",
          borderColor: "disabledBorder",
          color: "disabledText",
          opacity: "1",
          _hover: {
            borderColor: "disabledBorder",
          }
        },
      },
    },
  },
  defaultProps: {
    size: "lg",
    variant: "outline",
  },
}

const HelperText = {
  baseStyle: {
    color: "helperTextColor",
    marginTop: 0,
    marginBottom: 1,
  },
}

const LayoutCentered = {
  baseStyle: {
    "& > main": {
      flex: 1,
      marginX: "auto",
      maxWidth: "4xl",
      width: "full",
      wrapperProps: { paddingX: { base: 0, md: 6 } },
    },
  },
}

const Nav = {
  baseStyle: {
    fontFamily: "heading",
    fontSize: "md",
    fontWeight: "bold",
    color: "navLinkColor",
    lineHeight: 1,
    paddingY: { md: 2 },
    position: "sticky",
    top: 0,
    zIndex: {
      base: "sticky",
      lg: null,
    },

    "& .nav-numbers": {
      fontSize: "h2Desktop",
      lineHeight: 0.925,
      marginRight: 4,
    },
  },
}

const NestedSectionDataParser = {
  baseStyle: {
    backgroundColor: "transparent",
    border: "1px",
    borderColor: "borderColor",
    borderRadius: "base",
    padding: { base: 4, md: 6 },
  },
}

const OptionalTag = {
  baseStyle: {
    color: "bodyColorLight",
    display: "inline-block",
    fontSize: "13px",
    fontWeight: "normal",
    lineHeight: 1,
  },
}

const PageHeader = {
  baseStyle: {
    marginBottom: {
      base: 0,
    },
    paddingTop: {
      base: 6,
      md: 12,
    },
    paddingBottom: {
      base: 6,
      md: 12,
    },
  },
}

const Radio = {
  parts: ["control", "label"],
  baseStyle: {
    control: {
      background: "white",
      border: "1px",
      borderColor: "formInputBorderColor",
      _invalid: {
        borderColor: "error",
      },
      _hover: {
        background: "formInputBackgroundColor",
        borderColor: "formInputHoveredBorderColor",
      },
      _checked: {
        background: "checkedBoxBackgroundColor",
        borderColor: "formInputBorderColor",
        _hover: {
          borderColor: "formInputBorderColor",
        },
        _before: {
          background: "checkedBoxMarkColor",
          height: "50%",
          width: "50%",
        },
      },
      _disabled: {
        background: "disabledBackground",
        borderColor: "disabledCheckedBackground",
        opacity: "1",
        _hover: {
          background: "disabledBackground",
          borderColor: "disabledBorder",
        },
        _checked: {
          background: "white",
          borderColor: "disabledCheckedBackground",
          _before: {
            background: "disabledCheckedBackground",
          },
        },
      },
    },
  },
  sizes: {
    sm: {
      control: {
        height: 6,
        width: 6,
      },
      label: {
        fontSize: "sm",
      },
    },
    md: {
      control: {
        height: 8,
        width: 8,
      },
      label: {
        fontSize: "sm",
      },
    },
    lg: {
      control: {
        height: 10,
        width: 10,
      },
      label: {
        fontSize: "md",
      },
    },
  },
  defaultProps: {
    colorScheme: "whiteAlpha",
    size: "lg",
  },
}

const SectionDataParser = {
  baseStyle: {
    backgroundColor: { base: "transparent" },
    borderBottom: { base: "2px", md: "none" },
    borderBottomColor: "borderColor",
    borderRadius: { md: "md" },
    boxShadow: { md: "none" },
    paddingX: { base: 4, md: 6 },
    paddingY: { base: 8 },
  },
}

const SelectMenuNav = {
  baseStyle: {
    background: "navLinkHoverBackgroundColor",

    "&#mobile-nav": {
      padding: 6,
    },
  },
}

const SiteFooter = {
  baseStyle: {
    backgroundColor: "siteFooterBackgroundColor",
    fontSize: "13px",
    lineHeight: 1.85,
    paddingY: {
      base: 4,
      md: 6,
    },
    width: "full",
    "& a": {
      display: "block",
      whiteSpace: "nowrap",
    },
    "& a, & p, & .sign-out-button": {
      fontSize: "inherit",
      lineHeight: "inherit",
      fontFamily: "body",
    },
    "& .logo": {
      height: "64px",
      width: "312px",
    },
  },
}

const SiteHeader = {
  baseStyle: {
    backgroundColor: "siteHeaderBackgroundColor",
    paddingY: {
      base: 6,
      md: 8,
    },
    textAlign: "center",
    width: "full",
    zIndex: "docked",
    "& .logo": {
      marginX: "auto",
      height: "64px",
      width: {
        base: "312px",
        md: "416px",
      },
    },
  },
}

const Textarea = {
  baseStyle: {
    color: "formInputTextColor",
    paddingX: 4,
    paddingY: "14px",
    fontWeight: "300",
  },
  sizes: {
    xs: {
      fontSize: "sm",
      borderRadius: "base",
    },
    sm: {
      fontSize: "sm",
      borderRadius: "lg",
    },
    md: {
      fontSize: "md",
      borderRadius: "lg",
    },
    lg: {
      fontSize: "md",
      borderRadius: "none",
    },
  },
  variants: {
    outline: {
      background: "formInputBackgroundColor",
      borderColor: "formInputBorderColor",
      _hover: {
        borderColor: "formInputHoveredBorderColor",
      },
      _disabled: {
        background: "disabledBackground",
        borderColor: "disabledBorder",
        color: "disabledText",
        opacity: "1",
      },
    },
  },
  defaultProps: {
    size: "lg",
    variant: "outline",
  },
}

const IconButton = {
  baseStyle: {
    flex: 0,
  },
  variants: {
    ...Button.variants,
    link: {
      background: "transparent!important",
      boxShadow: "none!important",
      color: "buttonLinkColor",
      _hover: {
        color: "buttonLinkHoverColor",
      },
    },
  },
  sizes: {
    ...Button.sizes,
    sm: {
      height: 10,
      width: 10,
    },
  },
  defaultProps: {
    size: "sm",
    variant: "ghost",
  },
}

// ------------------------------------------------------------------------------
// AuthPage and AuthFormWrapper use styles from components above.
const AuthPage = {
  baseStyle: {
    alignItems: "top",
    display: "flex",
    flex: "1",
    marginX: "auto",
    maxWidth: "8xl", // 90rem = 1440px
    paddingX: { base: 4, md: 7 },
    width: "full",

    "#authPageWrapper": {
      padding: { base: 6, md: 10 },
      position: "relative",
    },
    "& .text-content > *": {
      marginBottom: { base: 4, md: 6 },
    },

    "&#sign-up": {
      "& ": {
        "button#cancel": {
          display: "none",
        },
        "#form-heading": {
          display: "none",
        },
        "#form-subtext": {
          textAlign: "center",

          "& a.userflow": {
            display: "block",
          },
        },
        "#authPageWrapper": {
          alignItems: "baseline",
          columnGap: {
            md: "3vw",
          },
          display: "grid",
          gridTemplateAreas: {
            base: `
              "heading"
              "form"
              "description"`,

            md: `
              "heading form"
              "description form"`,
          },
          gridTemplateColumns: {
            md: "minmax(min-content, 47.75rem) minmax(min-content, 30.25rem)",
          },
          gridTemplateRows: { md: "auto 1fr auto" },

          paddingY: { base: 4, sm: 6, md: 8 },
        },
      },
    },

    "&#sign-in, &#forgot-password": {
      maxWidth: "3xl",
      width: "full",

      "& #authPageWrapper ": {
        width: "full",

        "& ": {
          ".text-content": {
            textAlign: "center",
          },
          ".intro, .divider": {
            display: "none",
          },
          ".create": {
            textAlign: "center",
            marginTop: 5,

            "& #createAccount": {
              marginLeft: 2,
            },
          },
          ".password-label": {
            display: "flex",
            justifyContent: "space-between",
          },
        },
      },
    },

    "&#forgot-password": {
      "& ": {
        "#emailVerificationControl": {
          "& .buttons": {
            flexWrap: "wrap",

            "& #emailVerificationControl_but_send_new_code": {
              ...Button.variants.outline,
            },
          },
        },
        "#attributeList + .buttons": {
          display: "flex",
          flexWrap: "wrap",

          "& #continue": {
            order: 1,
          },
          "& #cancel": {
            order: 2,
            ...Button.variants.ghost,
          },
        },
        "#emailVerificationControl_but_change_claims": {
          display: "none!important",
        },
      },
    },
  },
}

const AuthFormWrapper = {
  baseStyle: {
    background: "pageBgColorShade1",
    borderRadius: { md: "md" },
    boxShadow: { md: "default" },
    padding: { base: 4, md: 6 },

    "& #api": {
      marginBottom: { base: 4, md: 6 },

      // Sign Up, Sign In, & Forgot Password input groups
      "#attributeList.attr li + li, #localAccountForm .entry-item + .entry-item,#emailVerificationControl li + li .attrEntry":
        {
          marginTop: { base: 4, md: 6 },
        },

      "& ": {
        ".heading, .intro, .helpLink": {
          display: "none",
        },
        ul /*labels */: {
          listStyle: "none!important",
          margin: "0!important",
        },
        "ul li, label": {
          ...FormLabel.baseStyle,
        },
        ".attrEntry, .entry-item": {
          display: "flex",
          flexDirection: "column",
        },
        input: {
          border: "1px solid",
          borderRadius: { base: "sm", sm: "lg" },
          fontSize: { base: "sm", md: "md" },
          height: { base: 10, md: 12, lg: 14 },
          paddingX: 4,
          ...Input.baseStyle.field,
          ...Input.variants.outline.field,
          "&:has(~ .error.show)": {
            borderColor: "error!",
            _focus: {
              outline: "1px solid",
              outlineColor: "error",
            },
          },
        },
        ".error": {
          color: "errorText",
          fontSize: "xs",
          fontWeight: "normal",
          marginLeft: 1,
          marginTop: 1,
          textAlign: "left",
        },
        "#requiredFieldMissing": {
          display: "none!important",
        },
        "#emailVerificationControl_success_message:not([aria-hidden='true'])": {
          display: "block",
          marginBottom: 8,
        },
        ".buttons": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
        },
        button: {
          border: "2px solid",
          borderColor: "primaryColor",
          ...Button.baseStyle,
          ...Button.variants.solid,
          display: "block",
          flex: "0 1 20rem",
          fontSize: { base: "sm", md: "md" },
          height: "auto",
          marginInline: "0!important",
          marginX: "auto",
          marginTop: { base: 4, sm: 6, md: 8 },
          paddingY: { base: 2, sm: 3, md: 4, lg: 5 },
          paddingInline: "2rem",
          whiteSpace: "nowrap",

          "&#continue": {
            order: 2,
          },
          "&#cancel": {
            ...Button.variants.outline,
            order: 1,
          },
          "&#emailVerificationControl_but_verify_code:not([aria-hidden='true'])":
            {
              display: "block!important",
              width: "100%",
              margin: 0,
            },
          "&#emailVerificationControl_but_send_new_code:not([aria-hidden='true'])":
            {
              ...Button.variants.outline,
            },
        },
        ".required-star": {
          color: "errorText",
          marginLeft: 1,
        },
      },
    },
  },
}

const PhoneInput = {
  baseStyle: {
    containerStyle: {
      width: "100%",
    },
    buttonStyle: {
      borderRight: "none",
      background: "white",
    },
    inputStyle: {
      height: "48px",
      borderRadius: "0",
      width: "100%",
      fontSize: "18px",
      paddingLeft: "60px",
    },
    searchStyle: {
      width: "100%",
      border: "none",
    },
  },
}

const MultiSelect = {
  baseStyle: {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isFocused || state.selectProps.isInvalid
          ? "white"
          : "formInputBackgroundColor",
      svg: {
        width: "28px",
        height: "28px",
      },
      width: "45px",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "white" : "formInputBackgroundColor",
      transition: "none",
      _invalid: {
        ...provided["_invalid"],
        backgroundColor: "white",
      },
    }),
    menuList: provided => {
      return {
        ...provided,
        "&::-webkit-scrollbar": {
          width: "6px",
        },

        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px white",
        },

        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#e4e7ed",
          borderRadius: "4px",
          height: "60px",
        },
      }
    },
  },
}

export const components = {
  Alert,
  PhoneInput,
  AuthFormWrapper,
  AuthPage,
  Button,
  Checkbox,
  ContentWrapper,
  FinalCheckCard,
  FinalCheckCardHeader,
  FormLabel,
  ...Headings,
  HelperText,
  Input,
  LayoutCentered,
  Nav,
  NestedSectionDataParser,
  NumberInput: {
    ...Input,
  },
  OptionalTag,
  PageHeader,
  Radio,
  SectionDataParser,
  Select: {
    ...Input,
  },
  SelectMenuNav,
  SiteFooter,
  SiteHeader,
  Textarea,
  IconButton,
  MultiSelect,
}
