import { useEffect, useRef } from "react"
import { logGroup, SHOW_LOGS } from "utilities/logging"

export const useLogInitialValues = (initialUserValues: {
  [key: string]: any
}) => {
  const prevUserFormValues = useRef(initialUserValues)

  useEffect(() => {
    if (initialUserValues !== prevUserFormValues.current) {
      logGroup(
        SHOW_LOGS.INITIAL_USER_VALUES,
        "Initial User Form Values",
        initialUserValues
      );
      prevUserFormValues.current = initialUserValues;
    }
  }, [initialUserValues])
}
