import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { ApplicationData, SusiPageData } from "types"
import { PageDataParser } from "./PageDataParser"
import { ScrollToTop } from "components/common"
import { AppRoutes, AuthRoutes, IS_PRODUCTION } from "utilities/constants"
import { FinalCheckPage } from "pages/FinalCheckPage"
import ConfirmationPage from "pages/ConfirmationPage"
import { LogoutPage } from "pages/LogoutPage"
import { UserContext } from "context/UserContext"
import { useContext, useEffect, useState } from "react"
import { DefaultAuthPage } from "pages/susi/DefaultAuthPage"
import { HollinsAuthPage } from "pages/susi/hollins/HollinsAuthPage"
import { ClientContext } from "context/ClientContext"

/**
 * A component for generating an application given the applications data in JSON format
 *
 * @param applicationData The JSON representing the application information
 * @returns The component representation of the application data
 */

export const ApplicationDataParser = (applicationData: ApplicationData) => {
  const { pages } = applicationData
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const { client } = useContext(ClientContext)

  const [isAppComplete, setIsAppComplete] = useState(!!user.submittedAt)

  useEffect(() => {
    if (isAppComplete) {
      navigate(AppRoutes.confirmation)
    }
  }, [navigate, isAppComplete])

  return (
    <ScrollToTop>
      <Routes>
        {pages.map(pageData => (
          <Route
            key={pageData.slug}
            path={`/${pageData.slug}`}
            element={<PageDataParser pageData={pageData} />}
          />
        ))}
        <Route
          key={AppRoutes.finalCheck}
          path={`/${AppRoutes.finalCheck}`}
          element={
            <FinalCheckPage
              applicationData={applicationData}
              setIsAppComplete={setIsAppComplete}
            />
          }
        />
        <Route
          key={AppRoutes.confirmation}
          path={`/${AppRoutes.confirmation}`}
          element={
            isAppComplete ? (
              <ConfirmationPage applicationData={applicationData} />
            ) : (
              <Navigate to={AppRoutes.finalCheck} />
            )
          }
        />
        <Route
          key={AppRoutes.logout}
          path={`/${AppRoutes.logout}`}
          element={<LogoutPage applicationData={applicationData} />}
        />

        {/* View auth pages for CSS/JS work to copy as HTML to Azure */}
        {!IS_PRODUCTION &&
          AuthRoutes.map(route => {
            const { signUp, signIn, forgotPassword } = applicationData.susiPages
            const { authSignUp, authSignIn } = AppRoutes

            let pageData: SusiPageData
            switch (route) {
              case authSignUp:
                pageData = signUp
                break
              case authSignIn:
                pageData = signIn
                break
              default:
                pageData = forgotPassword
            }

            let AuthPage = (
              <DefaultAuthPage
                applicationData={applicationData}
                pageName={route}
              />
            )

            if (client.theme === "hollins") {
              AuthPage = <HollinsAuthPage data={pageData} pageName={route} />
            }

            return <Route key={route} path={`/${route}`} element={AuthPage} />
          })}
        {/* If no match, redirect to first page of form */}
        <Route path="*" element={<Navigate to={pages[0].slug} replace />} />
      </Routes>
    </ScrollToTop>
  )
}
