import { OptionData } from "types"

export const AP_SUBJECTS: OptionData = [
  "Art History",
  "2-D Art and Design",
  "3-D Art and Design",
  "Drawing",
  "Music Theory",
  "English exams",
  "English",
  "English Language and Composition",
  "English Literature and Composition",
  "Macroeconomics",
  "Microeconomics",
  "Human Geography",
  "Comparative Government and Politics",
  "United States Government and Politics",
  "European History",
  "United States History",
  "World History: Modern",
  "Psychology",
  "Biology",
  "Chemistry",
  "Environmental Science",
  "Physics 1: Algebra-Based",
  "Physics 2: Algebra-Based",
  "Physics B",
  "Physics C: Electricity and Magnetism",
  "Physics C: Mechanics",
  "Calculus AB",
  "Calculus BC",
  "Computer Science A",
  "Computer Science Principles",
  "Statistics",
  "AP Capstone",
  "AP Capstone",
  "Capstone: Seminar",
  "Capstone: Research",
  "Chinese Language and Culture",
  "French Language and Culture",
  "German Language and Culture",
  "Italian Language and Culture",
  "Japanese Language and Culture",
  "Latin: Vergil",
  "Spanish Language and Culture",
  "Spanish Literature and Culture",
]
