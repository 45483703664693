import { useEffect, useRef } from "react"
import { logGroup, SHOW_LOGS } from "utilities/logging"
import { ApplicationData } from "types"

export const useLogFormJSON = (formJSON: ApplicationData) => {
  const prevFormJSON = useRef(formJSON)

  useEffect(() => {
    if (formJSON !== prevFormJSON.current) {
      logGroup(
        SHOW_LOGS.APP_JSON,
        "App JSON",
        JSON.stringify(formJSON, null, "\t"),
      )
      prevFormJSON.current = formJSON
    }
  }, [formJSON])
}
