import React, { createContext, useState, useCallback, useEffect } from "react"

interface OptionalLabelManagerState {
  showOptionalTags: boolean
  registerField: (fieldId: string, labelHasSpace: boolean) => void
  unregisterField: (fieldId: string) => void
}

interface FieldLabelSpace {
  hasSpaceForLabel: boolean
}

type FieldStatuses = Record<string, FieldLabelSpace>

const initialState: OptionalLabelManagerState = {
  showOptionalTags: true,
  registerField: () => {},
  unregisterField: () => {},
}

export const OptionalLabelContext =
  createContext<OptionalLabelManagerState>(initialState)

export const OptionalLabelManager = ({ children }: React.PropsWithChildren) => {
  const [showOptionalTags, setShowOptionalTags] = useState<boolean>(true)
  const [fieldStatuses, setFieldStatuses] = useState<FieldStatuses>({})

  const registerField = useCallback(
    (fieldId: string, hasSpaceForLabel: boolean) => {
      setFieldStatuses(prev => ({ ...prev, [fieldId]: { hasSpaceForLabel } }))
    },
    [],
  )

  const unregisterField = useCallback((fieldId: string) => {
    setFieldStatuses(prev => {
      const newStatuses = { ...prev }
      delete newStatuses[fieldId]
      return newStatuses
    })
  }, [])

  useEffect(() => {
    const allFieldsHaveSpace = Object.values(fieldStatuses).every(
      field => field.hasSpaceForLabel,
    )
    setShowOptionalTags(allFieldsHaveSpace)
  }, [fieldStatuses])

  return (
    <OptionalLabelContext.Provider
      value={{
        showOptionalTags,
        registerField,
        unregisterField,
      }}
    >
      {children}
    </OptionalLabelContext.Provider>
  )
}

export const useOptionalLabelManager = () => {
  const context = React.useContext(OptionalLabelContext)
  if (context === undefined) {
    throw new Error(
      "useOptionalLabelManager must be used within an OptionalLabelManager",
    )
  }
  return context
}
