import { FormLabel as ChakraFormLabel, Box } from "@chakra-ui/react"
import { FormControlLabelProps } from "components/common/FormControlLabel"
import { OptionalTag } from "components/OptionalTag"
import { useFieldWidthComparison } from "hooks"

/**
 * Component sets the content of labels for optional fields, checks if the label fits inside the parent container. If the label does not fit, it will hide the "Optional" tag or use a tooltip, based on settings in FormContext
 */
export const FormControlOptionalLabel = ({
  children,
  isFieldDisabled,
  ...rest
}: FormControlLabelProps) => {
  const optionalLabelText = "(optional)"

  const { fieldRef, showOptionalTags } = useFieldWidthComparison(
    children as string,
    optionalLabelText,
  )

  const renderOptionalIndicator = () => {
    if (!showOptionalTags || isFieldDisabled) {
      return null
    }

    return (
      <OptionalTag ml={1} transform="translateY(-1px)">
        {optionalLabelText}
      </OptionalTag>
    )
  }

  return (
    <Box ref={fieldRef}>
      <ChakraFormLabel optionalIndicator={renderOptionalIndicator()} {...rest}>
        {children}
      </ChakraFormLabel>
    </Box>
  )
}
